/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { cpbusImg } from '../../utils/assets';

const BusinessDivisionSlider = () => {
    const [selectedDivision, setSelectedDivision] =
        useState('Defence Solutions');
    const [selectedDm, setSelectedDm] = useState('Defence Solutions');
    const handleDmClick = (dm) => {
        setSelectedDm(dm);
    };
    const handleDivisionClick = (devision) => {
        setSelectedDivision(devision);
    };

    useEffect(() => {
        const dms = Object.keys(BusinessDivisionCardsmobile);
        let currentIndex = dms.indexOf(selectedDm);

        const intervalId = setInterval(() => {
            currentIndex = (currentIndex + 1) % dms.length;
            setSelectedDm(dms[currentIndex]);
        }, 5000); // Change the interval duration as needed (in milliseconds)

        return () => clearInterval(intervalId);
    }, [selectedDm]);

    useEffect(() => {
        const divisions = Object.keys(BusinessDivisionCards);
        let currentIndex = divisions.indexOf(selectedDivision);

        const intervalId = setInterval(() => {
            currentIndex = (currentIndex + 1) % divisions.length;
            setSelectedDivision(divisions[currentIndex]);
        }, 5000); // Change the interval duration as needed (in milliseconds)

        return () => clearInterval(intervalId);
    }, [selectedDivision]);
    const BusinessDivisionCards = {
        'Defence Solutions': (
            <div className="grid grid-cols-2 gap-[38px] mb-[42px]">
                <div className="flex justify-end">
                    <div className="rounded-lg overflow-hidden max-w-[525px] min-h-[305px]">
                        <img
                            className="w-full h-full object-cover"
                            src={cpbusImg[0]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="">
                    <p className="text-[15px] font-normal leading-[21px]">
                        Specialized Divisions
                    </p>
                    <p className="my-[14px] text-[40px] leading-[56px] font-medium text-[#007ad9]">
                        Defence Solutions
                    </p>
                    <p className="text-[15px] font-normal leading-[21px] max-w-[543px] text-[#50504E]">
                        The Kingenic Defense Solutions Division is unwaveringly
                        committed to advancing and providing specialized,
                        evidence-based nutritional solutions for defense forces.
                        Our mission is to optimize the health and performance of
                        warfighters by harnessing the potent synergy of
                        nutraceuticals and the power of carefully curated food
                        formulations. Through cutting-edge research and
                        development, we strive to offer products that not only
                        meet but exceed the unique nutritional needs of military
                        personnel. Elevate your force&apos;s well-being with
                        Kingenic Defense Solutions, where health meets the
                        forefront of nutritional innovation.
                    </p>
                </div>
            </div>
        ),

        'Premix Solutions': (
            <div className="grid grid-cols-2 gap-[38px] mb-[42px]">
                <div className="flex justify-end">
                    <div className="rounded-lg overflow-hidden max-w-[525px] min-h-[305px]">
                        <img
                            className="w-full h-full object-cover"
                            src={cpbusImg[1]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="">
                    <p className="text-[15px] font-normal leading-[21px]">
                        Specialized Divisions
                    </p>
                    <p className="my-[14px] text-[40px] leading-[56px] font-medium text-[#007ad9]">
                        Premix Solutions
                    </p>
                    <p className="text-[15px] font-normal leading-[21px] max-w-[543px] text-[#50504E]">
                        The Kingenic Premix Division is fervently committed to
                        the comprehensive lifecycle of food fortification
                        ingredient development, production, and marketing. Our
                        diverse portfolio encompasses an extensive array of
                        premix solutions, including Staple Premixes, Flour
                        Enzyme Premixes, Nutraceutical Premixes, and Animal
                        Nutrition Premixes. With a focus on quality and
                        innovation, we strive to deliver versatile and effective
                        premix formulations that cater to a broad spectrum of
                        applications. Elevate your products with Kingenic
                        Premixes, where dedication to excellence meets the
                        dynamic landscape of nutritional enhancement.
                    </p>
                </div>
            </div>
        ),
        'Community Nutrition': (
            <div className="grid grid-cols-2 gap-[38px] mb-[42px]">
                <div className="flex justify-end">
                    <div className="rounded-lg overflow-hidden max-w-[525px] min-h-[305px]">
                        <img
                            className="w-full h-full object-cover"
                            src={cpbusImg[2]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="">
                    <p className="text-[15px] font-normal leading-[21px]">
                        Specialized Divisions
                    </p>
                    <p className="my-[14px] text-[40px] leading-[56px] font-medium text-[#007ad9]">
                        Community Nutrition
                    </p>
                    <p className="text-[15px] font-normal leading-[21px] max-w-[543px] text-[#50504E]">
                        Our commitment extends to the meticulous development of
                        Ready-to-Use Therapeutic Foods (RUTFs), Ready-to-Use
                        Supplementary Foods (RUSFs), and ready-to-eat nutritious
                        powders. Specifically designed for Community-based
                        Management of Acute Malnutrition (CMAM), our products
                        address a spectrum of nutritional challenges including
                        Severe Acute Malnutrition, Moderate Malnutrition, and
                        corrective nutrition therapies. We take pride in
                        crafting versatile food fortification mixes that cater
                        to diverse nutritional needs. Ensuring effective
                        solutions for the well-being of individuals in hostile
                        areas like under-developed territories, warzones and
                        infectious outbreak areas.
                    </p>
                </div>
            </div>
        ),
        'Medical Food': (
            <div className="grid grid-cols-2 gap-[38px] mb-[42px]">
                <div className="flex justify-end">
                    <div className="rounded-lg overflow-hidden max-w-[525px] min-h-[305px]">
                        <img
                            className="w-full h-full object-cover"
                            src={cpbusImg[3]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="">
                    <p className="text-[15px] font-normal leading-[21px]">
                        Specialized Divisions
                    </p>
                    <p className="my-[14px] text-[40px] leading-[56px] font-medium text-[#007ad9]">
                        Medical Food
                    </p>
                    <p className="text-[15px] font-normal leading-[21px] max-w-[543px] text-[#50504E]">
                        Collaborating closely with healthcare professionals, we
                        actively engage in comprehending their evolving concerns
                        and the increasing burdens within the healthcare
                        landscape. Our commitment is to provide the most fitting
                        adjunct treatments in innovative delivery formats. These
                        formulations are not only palatable but also engineered
                        for advanced absorption, aiming to elevate the standards
                        of care. Through this dedication, we contribute to
                        enhancing the quality of life for patients, ensuring
                        that our solutions align seamlessly with the evolving
                        needs and challenges faced by healthcare providers in
                        their pursuit of improved patient outcomes.
                    </p>
                </div>
            </div>
        ),
    };
    const BusinessDivisionCardsmobile = {
        'Defence Solutions': (
            <div className="nutri-sol-cardxy">
                <p className="nutri-solutions-textxy">Defence Solutions</p>
                <p className="Developing-and-reinventionxy">
                    The Kingenic Defense Solutions Division is unwaveringly
                    committed to advancing and providing specialized,
                    evidence-based nutritional solutions for defense forces. Our
                    mission is to optimize the health and performance of
                    warfighters by harnessing the potent synergy of
                    nutraceuticals and the power of carefully curated food
                    formulations. Through cutting-edge research and development,
                    we strive to offer products that not only meet but exceed
                    the unique nutritional needs of military personnel. Elevate
                    your force&apos;s well-being with Kingenic Defense
                    Solutions, where health meets the forefront of nutritional
                    innovation.
                </p>
            </div>
        ),
        'Premix Solutions': (
            <div className="nutri-sol-cardxy">
                <p className="nutri-solutions-textxy">Premix Solutions</p>
                <p className="Developing-and-reinventionxy">
                    The Kingenic Premix Division is fervently committed to the
                    comprehensive lifecycle of food fortification ingredient
                    development, production, and marketing. Our diverse
                    portfolio encompasses an extensive array of premix
                    solutions, including Staple Premixes, Flour Enzyme Premixes,
                    Nutraceutical Premixes, and Animal Nutrition Premixes. With
                    a focus on quality and innovation, we strive to deliver
                    versatile and effective premix formulations that cater to a
                    broad spectrum of applications. Elevate your products with
                    Kingenic Premixes, where dedication to excellence meets the
                    dynamic landscape of nutritional enhancement.
                </p>
            </div>
        ),
        'Community Nutrition': (
            <div className="nutri-sol-cardxy">
                <p className="nutri-solutions-textxy">Community Nutrition</p>
                <p className="Developing-and-reinventionxy">
                    Our commitment extends to the meticulous development of
                    Ready-to-Use Therapeutic Foods (RUTFs), Ready-to-Use
                    Supplementary Foods (RUSFs), and ready-to-eat nutritious
                    powders. Specifically designed for Community-based
                    Management of Acute Malnutrition (CMAM), our products
                    address a spectrum of nutritional challenges including
                    Severe Acute Malnutrition, Moderate Malnutrition, and
                    corrective nutrition therapies. We take pride in crafting
                    versatile food fortification mixes that cater to diverse
                    nutritional needs. Ensuring effective solutions for the
                    well-being of individuals in hostile areas like
                    under-developed territories, warzones and infectious
                    outbreak areas.
                </p>
            </div>
        ),
        'Medical Food': (
            <div className="nutri-sol-cardxy">
                <p className="nutri-solutions-textxy">Medical Food</p>
                <p className="Developing-and-reinventionxy">
                    Collaborating closely with healthcare professionals, we
                    actively engage in comprehending their evolving concerns and
                    the increasing burdens within the healthcare landscape. Our
                    commitment is to provide the most fitting adjunct treatments
                    in innovative delivery formats. These formulations are not
                    only palatable but also engineered for advanced absorption,
                    aiming to elevate the standards of care. Through this
                    dedication, we contribute to enhancing the quality of life
                    for patients, ensuring that our solutions align seamlessly
                    with the evolving needs and challenges faced by healthcare
                    providers in their pursuit of improved patient outcomes.
                </p>
            </div>
        ),
    };

    const BusinessDivisionCardsmobileImge = {
        'Defence Solutions': (
            <div className="w-full h-full">
                <img
                    src={cpbusImg[0]}
                    alt=""
                    className="w-full h-full object-cover"
                />
            </div>
        ),
        'Premix Solutions': (
            <div className="w-full h-full">
                <img
                    src={cpbusImg[1]}
                    alt=""
                    className="w-full h-full object-cover"
                />
            </div>
        ),
        'Community Nutrition': (
            <div className="w-full h-full">
                <img
                    src={cpbusImg[2]}
                    alt=""
                    className="w-full h-full object-cover"
                />
            </div>
        ),
        'Medical Food': (
            <div className="w-full h-full">
                <img
                    src={cpbusImg[3]}
                    alt=""
                    className="w-full h-full object-cover"
                />
            </div>
        ),
    };

    return (
        <div className="custom__container max-md:!px-0">
            <div className="business-containerx">
                <div className="division-cards">
                    {BusinessDivisionCards[selectedDivision]}
                </div>
                <div className="business-menu">
                    {Object.keys(BusinessDivisionCards).map((division) => (
                        <p
                            key={division}
                            onClick={() => handleDivisionClick(division)}
                            className={
                                selectedDivision === division ? 'selected' : ''
                            }
                        >
                            {division}
                        </p>
                    ))}
                </div>
            </div>
            <div className="business-containerxy">
                <p className="max-md:text-[15px] font-normal leading-[21px] ml-[13px] mb-[18px]">
                    Specialised Divisions
                </p>
                <div className="bsc-div">
                    {BusinessDivisionCardsmobileImge[selectedDm]}
                </div>
                <div className="business-menuxy">
                    {Object.keys(BusinessDivisionCardsmobile).map((dm) => (
                        <p
                            key={dm}
                            onClick={() => handleDmClick(dm)}
                            className={selectedDm === dm ? 'selected' : ''}
                        >
                            {dm}
                        </p>
                    ))}
                </div>
                <div className="division-cardsxy">
                    {BusinessDivisionCardsmobile[selectedDm]}
                </div>
            </div>
        </div>
    );
};

export default BusinessDivisionSlider;
