import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '../Assests/Home.svg';
import ProductIcon from '../Assests/Atom.svg';
import PortalIcon from '../Assests/certificate.svg';
import LearningIcon from '../Assests/Bookopen.svg';
import ContactIcon from '../Assests/Status.svg';
import '../Styles/BottomNavigation.css';

const BottomNavigation = () => {
    return (
        <div className="bottomNavigationBar">
            <Link to="/home" className="navItem">
                <img src={HomeIcon} alt="Home" className="icon" />
                <span className="navBarItem"> Home </span>
            </Link>
            <Link to="/productcompodium" className="navItem">
                <img src={ProductIcon} alt="Product" className="icon" />
                <span className="navBarItem"> Products </span>
            </Link>
            <Link to="/brandPage" className="navItem">
                <img src={PortalIcon} alt="Brand" className="icon" />
                <span className="navBarItem"> Portal </span>
            </Link>
            <Link to="/userPage" className="navItem">
                <img src={LearningIcon} alt="User" className="icon" />
                <span className="navBarItem"> Learning </span>
            </Link>
            <Link to="/userPage" className="navItem">
                <img src={ContactIcon} alt="User" className="icon" />
                <span className="navBarItem"> Contact </span>
            </Link>
        </div>
    );
};

export default BottomNavigation;
