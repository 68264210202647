const NewCard = ({ title, paragraph, img }) => {
    return (
        <div className="w-full flex items-center gap-[22px] md:gap-[35px] py-[31px] border-b-[1px] border-b-[#007AD9] last:border-none first:mt-[18px] max-md:flex-col">
            <div className="max-w-[357px] max-md:max-w-full w-full aspect-video">
                <img src={img} alt="" className="w-full h-full object-cover" />
            </div>

            <div className="">
                <div className="text-[20px] max-md:mb-[14px] md:leading-[52px] font-normal">
                    {title}
                </div>

                <p className="text-[15px] font-normal leading-[28px] text-[#50504e]">
                    {paragraph}
                </p>
            </div>
        </div>
    );
};

export default NewCard;
