import Navbar from '../Contents/Navbar';
import DownloadCard from '../Pages/DownloadCard';
import '../Styles/LearningCentre.css';
import '../Styles/fonts.css';
// import AnimatedCursor from "react-animated-cursor";
import { CiCalendar } from 'react-icons/ci';
import { SlCalender } from 'react-icons/sl';
import 'swiper/css';
import 'swiper/css/pagination';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import lo from '../Assests/lo.png';
import lo2 from '../Assests/lo2.jpg';
import Footer from '../Contents/Footer';
import '../Styles/Cardslider.css';
import { featuredImg, learning, logos } from '../utils/assets';

import vlogimg1 from './../Assests/vlogimg1.png';
import vlogimg2 from './../Assests/vlogimg2.png';
import Subscribe from '../Components/shared/Subscribe';

import homesliderimg from './../Assests/homesliderimg.png';

const LearningCentre = () => {
    const isMobile = window.innerWidth <= 768;

    const fearuredData = [
        [
            {
                img: featuredImg[0],
                paragraph:
                    'Ashwagandha, known as "Indian ginseng," promotes vitality, reduces stress, and boosts overall well-being.',
            },
            {
                img: featuredImg[1],
                paragraph:
                    'Curcumin, gives turmeric its vibrant hue, is a potent anti-inflammatory and antioxidant.',
            },
            {
                img: featuredImg[2],
                paragraph:
                    'Adaptogenic mushrooms enhance resilience, reduce stress, and boost immunity for overall well-being.',
            },
        ],
        [
            {
                img: featuredImg[3],
                paragraph:
                    "Ginkgo biloba, Earth's oldest tree species, aids memory and cognitive function.",
            },
            {
                img: featuredImg[4],
                paragraph:
                    'Green tea, packed with antioxidants, originated in China and offers numerous health benefits.',
            },
            {
                img: featuredImg[5],
                paragraph:
                    'Astaxanthin, found in salmon and algae, is a potent antioxidant with skin benefits.',
            },
        ],
        [
            {
                img: featuredImg[6],
                paragraph:
                    'Spirulina, one of the oldest life forms on Earth contains more protein than meat.',
            },
            {
                img: featuredImg[7],
                paragraph:
                    'Cacao, the raw form of chocolate, is a rich source of antioxidants and mood-enhancing compounds.',
            },
            {
                img: featuredImg[8],
                paragraph:
                    'Ivy leaf extract helps soothe coughs and has been used in traditional medicine.',
            },
        ],
    ];

    return (
        <div className="Learning-container">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />
            <div className="Lmain">
                <div className="custom__container">
                    <div className="flex max-md:flex-col pt-[71px] justify-between">
                        <p className="text-[30px] max-w-[286px] font-medium leading-[42px] max-md:text-center max-md:mb-[13px] max-md:mx-auto max-md:max-w-[346px]">
                            Understand nutrition in-depth{' '}
                        </p>

                        <p className="max-w-[641px] text-[15px] font-normal leading-[21px] max-md:text-center">
                            Explore a wealth of knowledge at our Learning Hub, a
                            dedicated portal. Uncover valuable insights into
                            nutrition and dietary supplements, curated to
                            empower your understanding. Dive into expert
                            articles, research findings, and resources to stay
                            informed and make well-informed decisions on your
                            journey towards a healthier, more vibrant
                            lifestyle.Engage with interactive webinars, latest
                            white-papers and videos led by renowned health
                            professionals, offering a dynamic learning
                            experience.
                        </p>
                    </div>
                </div>

                <div className="max-w-[1320px] px-[20px] max-md:px-0 mx-auto mt-[70px]">
                    <div className=" relative max-md:h-[662px]">
                        <img
                            src={learning.banner}
                            alt=""
                            className="w-full h-full object-cover max-md:hidden"
                        />

                        <img
                            src={learning.bannerMobile}
                            alt=""
                            className="w-full h-full object-cover hidden max-md:block"
                        />

                        <div className="absolute bottom-0 left-0 w-full py-[24px] md:py-[33px] pl-[100px] backdrop-blur-lg bg-[#0F172E] bg-opacity-10 text-white max-md:pl-[24px]">
                            <div className="flex items-center -ml-[8px]">
                                <div className=" w-[58px] h-[22px] md:w-[100px] md:h-[41px] overflow-hidden">
                                    <img
                                        src={logos.logo}
                                        alt=""
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                                <span className="h-[18px] md:h-[30px] w-[1px] bg-white"></span>
                                <p className="text-[23px] md:text-[40px] font-medium leading-[35px] md:leading-[55px] ml-[10px] mt-[1px]">
                                    LEARNING HUB
                                </p>
                            </div>
                            <p className="text-[15px] leading-[18px] md:text-[20px] md:leading-[24px] font-[400] text-white">
                                Access free, expert-created education based on
                                your interests and needs.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bg-[#979797] bg-opacity-5 mt-[28px] max-md:hidden">
                    <div className="max-w-[1151px] px-[20px] py-[43px] mx-auto">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <img
                                    src={logos.logo2}
                                    alt="logo"
                                    className="w-[46px] mb-1"
                                />
                                |
                                <p className="text-[24px] font-medium leading-[34px] ml-4 uppercase">
                                    LEARNING HUB
                                </p>
                            </div>

                            <div className="flex items-center gap-3">
                                <p className="text-[15px] font-normal leading-[21px] text-[#007ad9]">
                                    @ TrendingShorties
                                </p>

                                <p className="uppercase text-[14px] font-bold leading-[26px] underline tracking-[2px]  text-[#007ad9] cursor-pointer">
                                    VIEW ALL
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-[50px]">
                            <div className="">
                                <h2 className="text-[24px] leading-[29px] font-medium max-w-[761px]">
                                    Probiotics for Your Family’s Immunity, How
                                    does it help in pregnancy and overall health
                                </h2>

                                <div className="flex items-center gap-4 mt-[10px]">
                                    <div className="flex items-center gap-1 text-[#727272]">
                                        <CiCalendar
                                            size={15}
                                            className="mb-1"
                                            strokeWidth={1}
                                        />
                                        <p className="text-[12px] font-normal">
                                            26 Oct, 23
                                        </p>
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        PROBIOTICS
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        GUT HEALTH
                                    </div>
                                </div>
                                <p className="mt-[9px] text-[15px] leading-[21px] font-normal text-[#50504E] max-w-[828px]">
                                    With life being so busy, now is a good time
                                    to be thinking about getting your whole
                                    family’s health in tip-top condition.
                                    Supporting the immune system, through a
                                    combination of approaches, is the best way
                                    to do this. In a 2021 survey, 62%1 of
                                    consumers indicated that having a strong
                                    immune system is their definition of
                                    &apos;healthy&apos;. Put simply, the immune
                                    system is a network of different cells,
                                    tissues, organs, and proteins which are
                                    located throughout the body.
                                </p>
                            </div>

                            {/* <div className="w-[252px] h-[171px] aspect-[16/8]"> */}
                            <img src={vlogimg1} alt="" />
                            {/* </div> */}
                        </div>
                        <div className="w-full h-[1px] bg-[#007AD9] my-[19px]"></div>

                        <div className="flex items-center justify-between gap-[50px]">
                            <div className="">
                                <h2 className="text-[24px] leading-[29px] font-medium max-w-[761px]">
                                    Mastering medical nutrition: 5 future trends
                                    from ESPEN 2023 Future of Nutrition
                                </h2>

                                <div className="flex items-center gap-4 mt-[10px]">
                                    <div className="flex items-center gap-1 text-[#727272]">
                                        <CiCalendar
                                            size={15}
                                            className="mb-1"
                                            strokeWidth={1}
                                        />
                                        <p className="text-[12px] font-normal">
                                            26 Oct, 23
                                        </p>
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        SUPPLEMENTATION
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        PLANT PROTEIN
                                    </div>
                                </div>
                                <p className="mt-[9px] text-[15px] leading-[21px] font-normal text-[#50504E] max-w-[828px]">
                                    In the dynamic realm of medical nutrition,
                                    advancements persist without pause. Emerging
                                    insights from the 45th ESPEN Congress on
                                    clinical nutrition and metabolism in
                                    September 2023 indicate forthcoming
                                    developments. Anticipate the ascendance of
                                    plant proteins, omega-3 supplements,
                                    strategies to enhance muscle health through
                                    gut interventions, customized nutritional
                                    products, and the integration of Human Milk
                                    Oligosaccharides. These transformations are
                                    poised to reshape our perspectives on
                                    nutrition and administration.
                                </p>
                            </div>

                            {/* <div className="w-[252px] h-[171px] aspect-[16/8]"> */}
                            <img src={vlogimg2} alt="" />
                            {/* </div> */}
                        </div>

                        {/* <div className="mntrends-l">
                        <div className="kinlots-l">
                            <div className="logo1s-l"></div>
                            <div className="vlines-l"></div>
                            <p className="hubps-l">NUTRITION HUB</p>
                        </div>
                        <p className="ptrnds-l">@ TrendingShorties</p>
                    </div>
                    <div className="imgtboxt1-l">
                        <div className="lftdiv-l">
                            <p className="rbt2-l">
                                Probiotics for Your Family’s Immunity, How does
                                it help in pregnancy and overall health
                            </p>
                            <div className="ravit-l">
                                <p className="rbt3-l">
                                    <CiCalendar size={15} /> 26 Oct, 23{' '}
                                </p>
                                <div className="piyug1-l">
                                    {' '}
                                    <p>PROBIOTICS</p>
                                </div>
                                <div className="piyug1-l">
                                    <p>GUT HEALTH</p>
                                </div>
                            </div>
                            <p className="story-l">
                                With life being so busy, now is a good time to
                                be thinking about getting your whole family’s
                                health in tip-top condition. Supporting the
                                immune system, through a combination of
                                approaches, is the best way to do this. In a
                                2021 survey, 62%1 of consumers indicated that
                                having a strong immune system is their
                                definition of &apos;healthy&apos;. Put simply,
                                the immune system is a network of different
                                cells, tissues, organs, and proteins which are
                                located throughout the body.{' '}
                            </p>
                        </div>
                        <div className="rtimgbox-l"></div>
                    </div>

                    <div className="horiline-l"></div>
                    <div className="imgtboxt2-l">
                        <div className="lftdiv-l">
                            <p className="rbt2-l">
                                Mastering medical nutrition: 5 future trends
                                from ESPEN 2023 Future of Nutrition
                            </p>
                            <div className="ravit-l">
                                <p className="rbt3-l">
                                    {' '}
                                    <CiCalendar size={15} />
                                    26 Oct, 23{' '}
                                </p>
                                <div className="piyug1-l">
                                    {' '}
                                    <p>PROBIOTICS</p>
                                </div>
                                <div className="piyug1-l">
                                    <p>GUT HEALTH</p>
                                </div>
                            </div>
                            <p className="story-l">
                                In the dynamic realm of medical nutrition,
                                advancements persist without pause. Emerging
                                insights from the 45th ESPEN Congress on
                                clinical nutrition and metabolism in September
                                2023 indicate forthcoming developments.
                                Anticipate the ascendance of plant proteins,
                                omega-3 supplements, strategies to enhance
                                muscle health through gut interventions,
                                customized nutritional products, and the
                                integration of Human Milk Oligosaccharides.
                                These transformations are poised to reshape our
                                perspectives on nutrition and administration.
                            </p>
                        </div>
                        <div className="rtimgbox-l"></div>
                    </div> */}
                    </div>
                </div>
                {/* <div className="tiputr-l" id="toputr-l">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <div className="babuptrw-l">
                                <div className="kinlotfxtr-l">
                                    <img
                                        src={lo2}
                                        alt=""
                                        className="logo1fxtr-l"
                                    />
                                    <div className="vlinefxtr-l"></div>
                                    <p className="hubpfxtr-l">LEARNING HUB</p>
                                </div>
                                <p className="Cxtrends-l">@ TrendingShorties</p>
                                <div className="bruline-l"></div>
                                <div className="brudiv1-l"></div>
                                <p className="brudte1-l">
                                    Probiotics for Your Family’s Immunity, How
                                    does it help in pregnancy and overall health
                                </p>
                                <div className="brudiv2-x-x-l">
                                    <div className="brd-1-0-l">
                                        <SlCalender size="10px" />
                                        <p>26 Oct, 23</p>
                                    </div>
                                    <button>PROBIOTICS</button>
                                    <button>GUT HEALTH</button>
                                </div>
                                <p className="brudte3-l">
                                    With life being so busy, now is a good time
                                    to be thinking about getting your whole
                                    family’s health in tip-top condition.
                                    Supporting the immune system, through a
                                    combination of approaches, is the best way
                                    to do this. In a 2021 survey, 62%1 of
                                    consumers indicated that having a strong
                                    immune system is their definition of
                                    &apos;healthy&apos;. Put simply, the immune
                                    system is a network of different cells,
                                    tissues, organs, and proteins which are
                                    located throughout the body.{' '}
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="babuptrw-l">
                                <div className="kinlotfxtr-l">
                                    <img
                                        src={lo2}
                                        alt=""
                                        className="logo1fxtr-l"
                                    />
                                    <div className="vlinefxtr-l"></div>
                                    <p className="hubpfxtr-l">LEARNING HUB</p>
                                </div>
                                <p className="Cxtrends-l">@ TrendingShorties</p>
                                <div className="bruline-l"></div>
                                <div className="brudiv1-l"></div>
                                <p className="brudte1-l">
                                    Probiotics for Your Family’s Immunity, How
                                    does it help in pregnancy and overall health
                                </p>
                                <div className="brudiv2-x-x-l">
                                    <div className="brd-1-0-l">
                                        <SlCalender size="10px" />
                                        <p>26 Oct, 23</p>
                                    </div>
                                    <button>PROBIOTICS</button>
                                    <button>GUT HEALTH</button>
                                </div>
                                <p className="brudte3-l">
                                    With life being so busy, now is a good time
                                    to be thinking about getting your whole
                                    family’s health in tip-top condition.
                                    Supporting the immune system, through a
                                    combination of approaches, is the best way
                                    to do this. In a 2021 survey, 62%1 of
                                    consumers indicated that having a strong
                                    immune system is their definition of
                                    &apos;healthy&apos;. Put simply, the immune
                                    system is a network of different cells,
                                    tissues, organs, and proteins which are
                                    located throughout the body.{' '}
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div> */}

                {/* Trending section for mobile */}
                <div className="lg:hidden bg-[#B0B5BE0D] px-12 max-md:!px-[10px] pb-8 mt-[68px]">
                    {/* <div className="product-hub-logo-div justify-center">
                        <div className="log-1">
                            <img src={slogo} alt="" />
                        </div>
                        <div className="line-x"></div>
                        <p>PRODUCT HUB</p>
                    </div> */}
                    <p className="text-center">@TrendingShorties</p>
                    <hr className="border border-[#007AD9] mt-2 mb-5" />

                    <Swiper
                        style={{
                            '--swiper-pagination-color': '#000',
                            '--swiper-pagination-bullet-inactive-color': '#000',
                            '--swiper-pagination-bottom': '0px',
                        }}
                        pagination={true}
                        modules={[Pagination]}
                        className="mySwiper !pb-[20px]"
                    >
                        <SwiperSlide>
                            <div className="flex flex-col gap-2 items-center mb-8">
                                <img
                                    className="h-full w-[80%] mx-auto"
                                    src={vlogimg1}
                                    alt=""
                                />
                                <p className="rbt2 !text-center">
                                    Probiotics for Your Family’s Immunity, How
                                    does it help in pregnancy and overall health
                                </p>
                                <div className="ravit">
                                    <p className="rbt3">
                                        <CiCalendar size={15} /> 26 Oct, 23{' '}
                                    </p>
                                    <div className="piyug1">
                                        {' '}
                                        <p>PROBIOTICS</p>
                                    </div>
                                    <div className="piyug1">
                                        <p>GUT HEALTH</p>
                                    </div>
                                </div>
                                <p className="story !text-center">
                                    With life being so busy, now is a good time
                                    to be thinking about getting your whole
                                    family’s health in tip-top condition.
                                    Supporting the immune system, through a
                                    combination of approaches, is the best way
                                    to do this. In a 2021 survey, 62%1 of
                                    consumers indicated that having a strong
                                    immune system is their definition of
                                    &apos;healthy&apos;. Put simply, the immune
                                    system is a network of different cells,
                                    tissues, organs, and proteins which are
                                    located throughout the body.{' '}
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-col gap-2 items-center">
                                <img
                                    className="h-full w-[80%] mx-auto"
                                    src={vlogimg2}
                                    alt=""
                                />
                                <p className="rbt2">
                                    Mastering medical nutrition: 5 future trends
                                    from ESPEN 2023 Future of Nutrition
                                </p>
                                <div className="ravit">
                                    <p className="rbt3">
                                        <CiCalendar size={15} /> 26 Oct, 23{' '}
                                    </p>
                                    <div className="piyug1">
                                        {' '}
                                        <p> SUPPLEMENTATION</p>
                                    </div>
                                    <div className="piyug1">
                                        <p>PLANT PROTEIN</p>
                                    </div>
                                </div>
                                <p className="story !text-center">
                                    In the dynamic realm of medical nutrition,
                                    advancements persist without pause. Emerging
                                    insights from the 45th ESPEN Congress on
                                    clinical nutrition and metabolism in
                                    September 2023 indicate forthcoming
                                    developments. Anticipate the ascendance of
                                    plant proteins, omega-3 supplements,
                                    strategies to enhance muscle health through
                                    gut interventions, customized nutritional
                                    products, and the integration of Human Milk
                                    Oligosaccharides. These transformations are
                                    poised to reshape our perspectives on
                                    nutrition and administration.
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                <div className="max-md:hidden max-w-[1328px]  mx-auto flex rounded-[30px] bg-[#50504E] bg-opacity-5 mt-[80px]">
                    <div className="publp1div pl-[24px] pt-[24px]">
                        <p className="text-[15px] leading-[21px] font-normal text-[#007AD9] ">
                            Featured Short Facts
                        </p>
                        <p className="text-[18px] leading-[25px] font-medium my-[27px] max-w-[278px]">
                            We publish facts for better understanding of
                            nutrition
                        </p>
                        <p className="text-[15px] leading-[21px] font-normal max-w-[278px] text-[#0F172E] natosans">
                            Welcome to our featured short facts, where we dive
                            deep into the fascinating world of nutrition and
                            wellness. We curate short facts based on latest
                            research breakthroughs to time-tested remedies,
                            we&apos;re here to guide you on your journey to
                            optimal health. about food & nutrition.
                            <br /> <br />
                            Understanding the ingredients and the effect of
                            supplementation is important for targeted outcomes,
                            join us as we explore the science-backed benefits of
                            nutraceuticals and uncover the secrets to living
                            your best life.
                        </p>
                    </div>
                    <div className="publp2div  pl-[48px] ">
                        {fearuredData?.map((arr) => (
                            <div
                                className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[23px] pb-[57px] pt-[35px] pr-[24px] border-b border-b-[#007ad9] last:border-none"
                                key={arr}
                            >
                                {arr.map((item) => (
                                    <div
                                        key={item.paragraph}
                                        className="max-w-[287px] mx-auto w-full"
                                    >
                                        <div className="w-full">
                                            <img
                                                src={item.img}
                                                alt=""
                                                className="w-full"
                                            />
                                        </div>
                                        <p className="text-[15px] leading-[21px] font-normal text-center text-[#0f172e] mt-[11px]">
                                            {item.paragraph}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="px-[20px] md:hidden">
                    <div className="">
                        <p className="text-[15px] leading-[21px] font-normal text-[#007AD9] text-center">
                            Featured Short Facts
                        </p>
                        <p className="text-[18px] leading-[25px] font-medium my-[10px] text-center max-w-[255px] mx-auto">
                            We publish facts for better understanding of
                            nutrition
                        </p>
                        <p className="text-[15px] leading-[21px] font-normal text-[#0F172E] natosans text-center">
                            Welcome to our featured short facts, where we dive
                            deep into the fascinating world of nutrition and
                            wellness. We curate short facts based on latest
                            research breakthroughs to time-tested remedies,
                            we&apos;re here to guide you on your journey to
                            optimal health. about food & nutrition.
                            <br /> <br />
                            Understanding the ingredients and the effect of
                            supplementation is important for targeted outcomes,
                            join us as we explore the science-backed benefits of
                            nutraceuticals and uncover the secrets to living
                            your best life.
                        </p>
                    </div>

                    <div className="tipupa" id="topupa">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            spaceBetween={10}
                            slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            {/* <SwiperSlide>
                                <div className="babuppa">
                                    <div className="babdiv1pa"></div>
                                    <div className="babdiv2pa">
                                        <p className="babp1pa">
                                            4 reasons to partner with DSM on
                                            your next early life nutrition
                                            innovation
                                        </p>
                                        <p className="babp2pa">Read More</p>
                                    </div>
                                </div>
                            </SwiperSlide> */}

                            {fearuredData?.map((arr) => (
                                <div className="" key={arr}>
                                    {arr.map((item) => (
                                        <SwiperSlide
                                            key={item.paragraph}
                                            className="w-full mt-[26px] pb-[40px]"
                                        >
                                            <div className="max-w-[287px] mx-auto">
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="w-full"
                                                />
                                                <p className="text-[15px] leading-[21px] font-normal text-center text-[#0f172e] mt-[11px]">
                                                    {item.paragraph}
                                                </p>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </div>
                            ))}
                        </Swiper>
                    </div>
                </div>
                {/* <div className="mdivx">
                    <p className="vtp1">Trending Video</p>
                    <div className="vtp2">
                        <p>Early Life Nutrition</p>
                        <p>Sports Nutrition</p>
                        <p>Oncology Nutrition</p>
                        <p>Diabetes Nutrition</p>
                    </div>
                    <div className="und1"></div>
                    <div className="movi1">
                        <div className="movp1"></div>
                        <div className="movp2">
                            <p className="mot1">
                                Talking Nutrition With a Heart Surgeon - with
                                Dr. Steven Gundry | The Empowering Neurologist
                                EP. 50
                            </p>
                            <p className="mot2">
                                Kingenic Consumer Healthcare • 1 M View • 5 days
                                ago{' '}
                            </p>
                            <p className="mot2">
                                Talking Nutrition With a Heart Surgeon - with
                                Dr. Steven Gundry | The Empowering Neurologist
                                EP. 50
                            </p>
                        </div>
                    </div>
                    <div className="movi1">
                        <div className="movp1"></div>
                        <div className="movp2">
                            <p className="mot1">
                                Talking Nutrition With a Heart Surgeon - with
                                Dr. Steven Gundry | The Empowering Neurologist
                                EP. 50
                            </p>
                            <p className="mot2">
                                Kingenic Consumer Healthcare • 1 M View • 5 days
                                ago{' '}
                            </p>
                            <p className="mot2">
                                Talking Nutrition With a Heart Surgeon - with
                                Dr. Steven Gundry | The Empowering Neurologist
                                EP. 50
                            </p>
                        </div>
                    </div>
                </div> */}

                {/* <div className="mdivxram">
                    <p className="vtp1">Trending Video</p>
                    <div className="vtp2">
                        <p>Early Life Nutrition</p>
                        <p>Sports Nutrition</p>
                    </div>
                    <div className="und1"></div>
                    <div className="movi1">
                        <div className="movp1"></div>
                        <div className="movp2">
                            <p className="mot1">
                                Talking Nutrition With a Heart Surgeon - with
                                Dr. Steven Gundry | The Empowering Neurologist
                                EP. 50
                            </p>
                            <p className="mot2">
                                Kingenic Consumer Healthcare • 1 M View • 5 days
                                ago{' '}
                            </p>
                        </div>
                    </div>
                    <div className="movi1">
                        <div className="movp1"></div>
                        <div className="movp2">
                            <p className="mot1">
                                Talking Nutrition With a Heart Surgeon - with
                                Dr. Steven Gundry | The Empowering Neurologist
                                EP. 50
                            </p>
                            <p
                                className="mot2"
                                style={{
                                    marginTop: '5px',
                                    marginBottom: '30px',
                                }}
                            >
                                Kingenic Consumer Healthcare • 1 M View • 5 days
                                ago{' '}
                            </p>
                        </div>
                    </div>
                </div> */}
                {/* <div className="apexpandyour-l">
          <div className="apexpand1-l">
            <p className="apexpand1p1-l">
              Expand your product portfolio by featuring science based products
            </p>
            <p className="apexpand1p2-l">
              Collaborate with us. Book a free consultation today !
            </p>

            <div className="apemailinputbox-l">
              <input type="text" name="" id="apputbox-l" placeholder="Email" />
              <button>Subscribe</button>
            </div>
          </div>
          <div className="apexpand2-l">
            <p className="apexpand2p1-l">
              Are you looking for scientific white papers?
            </p>
            <p className="apexpand2p2-l">Request Clinical Resoureces</p>
          </div>
        </div>
        <div className="product-form-l">
          <p className="form-p1-l">
            Expand your product portfolio by featuring science based products
          </p>
          <p className="form-p2-l">Collaborate with us.<br/> Book a free consultation today !</p>
          <input type="text" name="" id="" placeholder="Name" />
          <input type="text" name="" id="" placeholder="Email Address" />
          <input type="text" name="" id="" placeholder="Phone Number" />
          <textarea placeholder="Tell us the area of your interest"></textarea>
          <button>Submit</button>
        </div> */}
                {/* <DownloadCard /> */}
                <Subscribe />
                <Footer />
            </div>
        </div>
    );
};

export default LearningCentre;
