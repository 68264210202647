import parse from 'html-react-parser';
import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { infoImg } from '../../utils/assets';
import { cn } from '../../utils/classMarge';

function InfoTab() {
    // const [nav1, setNav1] = useState(null);
    // const [nav2, setNav2] = useState(null);
    // let sliderRef1 = useRef(null);
    // let sliderRef2 = useRef(null);

    const [activeSlide, setActiveSlide] = useState(0);

    // useEffect(() => {
    //     setNav1(sliderRef1);
    //     // setNav2(sliderRef2);
    // }, []);

    const data = [
        {
            title: 'Product Development',
            subtitle: 'From Idea to finished product',
            paragraph:
                'Kingenic specializes in helping organizations translate ideas into reality.  Our methodology employs a systematic and transparent approach, ensuring the realization of objectives within predefined timelines.  <br /> <br /> Prior to embarking on the product development journey, we meticulously assess pivotal factors, encompassing legislation, nutritional composition, quality, safety, desired benefits, consumer acceptability, and scalability to factory levels. Our comprehensive task ownership matrix delineates each stage, providing a lucid depiction of the role of each organization throughout the association.    <br /> <br /> The strategic ramework optimizes collaboration',
            img: infoImg[0],
        },
        {
            title: 'Manufacturing',
            subtitle: 'Quality Crafted, Precision Focused',
            paragraph:
                'Delivering purposeful and strategic manufacturing solutions designed to meet specific objectives and goals. We pride ourselves as masters of powder formulation, employing cutting-edge technology to craft innovative products.  <br /> <br />  A testament to our commitment to revolutionizing supplement consumption. With a dedication to precision and quality, we offer an extensive array of powder formulas, meeting diverse nutritional needs.   <br /> <br />  At Kingenic, we stand out for our rigorous testing procedures, ensuring each product surpasses industry standards. Embrace advanced technology with us, as we continually strive to redefine excellence.',
            img: infoImg[1],
        },
        {
            title: 'Product & Brand Design',
            subtitle: 'Your Brand, Our Expertise.',
            paragraph:
                'Discover a comprehensive solution for product and brand design with our services.  <br /> <br /> We specialize in crafting innovative and visually compelling designs that elevate your product and brand identity. Our dedicated team brings creativity and strategic thinking to the forefront, ensuring that every design solution aligns seamlessly with your brand vision and market objectives.  <br /> <br /> Elevate your brand presence and product aesthetics with our tailored design services, where creativity meets functionality, delivering solutions that resonate with your target audience and set you apart in the competitive landscape.',
            img: infoImg[2],
        },
        {
            title: 'Brand Licensing',
            subtitle: 'Offering brands with Impact',
            paragraph:
                "Embark on a journey of innovation with our services, where we introduce novel products and brands through licensing agreements. Dive into a realm of endless possibilities as we extend our expertise to bring forth new and exciting offerings.  <br /> <br /> Through strategic licensing agreements, we provide a gateway for the introduction of fresh products and brands, fostering growth and market expansion. Whether you're a brand seeking to diversify or a potential licensee, our services offer a collaborative platform for mutually beneficial partnerships. Join us in reshaping industries and unlocking potential through our tailored approach to introducing innovative products and brands under licensing agreements.",
            img: infoImg[3],
        },
    ];

    const setting = {
        beforeChange: (current, next) => {
            setActiveSlide(next);
        },

        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };

    const activeSlideinfo = data[activeSlide];

    return (
        <div className="slider-container">
            <Slider
                // asNavFor={nav1}
                // ref={(slider) => (sliderRef2 = slider)}
                slidesToShow={4}
                swipeToSlide={true}
                focusOnSelect={true}
                className="border-b max-md:border-b-[#007AD9]"
                {...setting}
            >
                {data.map((dd, i) => (
                    <Title
                        activeSlide={activeSlide === i}
                        title={dd.title}
                        key={dd.title}
                    />
                ))}
            </Slider>

            <div className="mt-[32px]">
                <InfoCard
                    subtitle={activeSlideinfo.subtitle}
                    paragraph={activeSlideinfo.paragraph}
                    img={activeSlideinfo.img}
                />
            </div>

            {}

            {/* <Slider
                className="mt-[31px]"
                asNavFor={nav2}
                ref={(slider) => (sliderRef1 = slider)}
            >
                {data.map((dd) => (
                    <InfoCard
                        key={dd.title}
                        subtitle={dd.subtitle}
                        paragraph={dd.paragraph}
                        img={dd.img}
                    />
                ))}
            </Slider> */}
        </div>
    );
}

export default InfoTab;

const Title = ({ title, activeSlide }) => {
    return (
        <div
            className={cn(
                'pb-[16px] w-fit max-md:pb-[6px]',
                activeSlide && 'md:border-b-[#007AD9] md:border-b-[3px]  '
            )}
        >
            <p
                className={cn(
                    'text-[15px] md:text-[17px] leading-[24px] font-medium w-fit '
                )}
            >
                {title}
            </p>
        </div>
    );
};

const InfoCard = ({ subtitle, img, paragraph }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-5 gap-[50px]">
            <div className="md:col-span-2">
                <p className="text-[20px] font-medium leading-[28px] text-[#007ad9] max-md:text-center">
                    {subtitle}
                </p>

                <p className="mt-[14px] text-[15px] font-normal leading-[21px] max-md:text-center">
                    {parse(paragraph)}
                </p>
            </div>
            <div className="md:col-span-3  relative">
                <div className="md:absolute md:left-0 md:top-0 w-full h-full rounded-lg overflow-hidden">
                    <img
                        src={img}
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>
            </div>
        </div>
    );
};
