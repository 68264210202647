import React from 'react';
import '../Styles/Private.css';

import '../Styles/Brand.css';

import { Link } from 'react-router-dom';
import '../Styles/Product.css';
// import AnimatedCursor from "react-animated-cursor";
import c1 from '../Assests/c1.svg';
import c10 from '../Assests/c10.svg';
import c2 from '../Assests/c2.svg';
import c3 from '../Assests/c3.svg';
import c4 from '../Assests/c4.svg';
import c5 from '../Assests/c5.svg';
import c6 from '../Assests/c6.svg';
import c7 from '../Assests/c7.svg';
import c8 from '../Assests/c8.svg';
import c9 from '../Assests/c9.svg';
import Footer from '../Contents/Footer';
import Navbar from '../Contents/Navbar';
import '../Styles/fonts.css';
import DownloadCard from './DownloadCard';
const brand = () => {
    const isMobile = window.innerWidth <= 768;
    return (
        <div className="Private-container">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />
            <div className="Bmain">
                <div className="PRtbdiv1"></div>

                <p className="xycategory">
                    Product categories
                    <span className="we-off">
                        we offer under private label
                    </span>{' '}
                </p>
                <div className="xyline1"></div>
                <div className="zcategoryBox">
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c1} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <Link to="/privatecategory">
                                <p>BLOOD SUGAR</p>
                            </Link>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c2} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>
                                BONE, JOINT & <br /> MUSCLE HEALTH
                            </p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c3} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>
                                COGNITIVE <br /> HEALTH & MENTAL <br />{' '}
                                PERFORMANCE
                            </p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c4} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>EYE HEALTH</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c5} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>
                                GASTROINTESTINAL <br /> HEALTH
                            </p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c6} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>HEART HEALTH</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c7} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>IMMUNE HEALTH</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c8} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>MOOD SUPPORT</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c9} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>NATURAL ENERGY</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c10} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>
                                SEXUAL <br /> PERFORMANCE & <br /> FERTILITY
                            </p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c8} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>MOOD SUPPORT</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c9} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>NATURAL ENERGY</p>
                        </div>
                    </div>
                    <div className="zcatbox">
                        <div className="zicondiv">
                            <img src={c10} alt="" className="zcatLo" />
                        </div>
                        <div className="zpBox">
                            <p>
                                SEXUAL <br /> PERFORMANCE & <br /> FERTILITY
                            </p>
                        </div>
                    </div>
                </div>
                <DownloadCard />
                {/* <div className="product-form-l-p">
          <p className="form-p1-l-p">
            Expand your product portfolio by featuring science based products
          </p>
          <p className="form-p2-l-p">Collaborate with us.<br/> Book a free consultation today !</p>
          <input type="text" name="" id="" placeholder="Name" />
          <input type="text" name="" id="" placeholder="Email Address" />
          <input type="text" name="" id="" placeholder="Phone Number" />
          <textarea placeholder="Tell us the area of your interest"></textarea>
          <button>Submit</button>
        </div> */}

                <Footer />
            </div>
        </div>
    );
};

export default brand;
