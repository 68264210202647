import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import '../Styles/Product.css';
// import AnimatedCursor from "react-animated-cursor";
import 'swiper/css';
import 'swiper/css/pagination';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Footer from '../Contents/Footer';
import Navbar from '../Contents/Navbar';
import '../Styles/Cardslider.css';
import '../Styles/fonts.css';
import DownloadCard from './DownloadCard';
import Subscribe from '../Components/shared/Subscribe';
import {
    logos,
    manufacturerComImg,
    pdfs,
    producBanner,
    products,
} from '../utils/assets';
const Product = () => {
    const productItems = [
        {
            title: 'Our Brands',
            img: products[0],
            paragraph:
                'Learn about our portfolio of our brands for children and adults.',
            url: '/brandedproduct',
        },
        {
            title: 'Private Label Products',
            img: products[1],
            paragraph:
                'Wide range of formulas/products ready for your new brand or brand extension.',
            url: '/privatelabel',
        },
        {
            title: 'Clinical Resources',
            img: products[2],
            paragraph:
                'Explore nutrition protocols and medical nutrition infographics ',
            url: '',
        },
        {
            title: 'Nutrition Resources',
            img: products[3],
            paragraph:
                'Unlocking nutrition through evidence-based insights from scientific research.',
            url: '',
        },
    ];

    const fearuredData = [
        [
            {
                img: manufacturerComImg[0],
                paragraph: 'Quality Centric',
                url: '',
                urlText: 'Commitment at every stage',
            },
            {
                img: manufacturerComImg[1],
                paragraph: 'Branded Ingredients',
                url: '',
                urlText: 'Superior Sourcing',
            },
            {
                img: manufacturerComImg[2],
                paragraph: 'Sustainable Approach',
                url: '',
                urlText: 'As easy as it gets',
            },
        ],
    ];

    const pdnavigation = [
        {
            title: 'Food & Beverages Product List',
            url: pdfs.food,
        },
        {
            title: 'Generic Supplements Brochure',
            url: pdfs.generic,
        },
        {
            title: 'Specialized Supplements Brochure',
            url: pdfs.brochure,
        },
        {
            title: 'Medical Nutrition Brochure',
            url: pdfs.nutition,
        },
        {
            title: 'Defence Nutrition Brochure',
            url: pdfs.nutition,
        },
        {
            title: 'Animal Nutrition Brochure',
            url: pdfs.animal,
        },
        {
            title: 'Malnutrition Brochure',
            url: pdfs.mal,
        },
    ];

    return (
        <div className="">
            <Navbar />

            <div className="custom__container">
                <div className="flex justify-between max-md:flex-col pt-[122px] max-md:pt-[80px]">
                    <h2 className="text-[25px] md:text-[30px] leading-[35px] max-md:text-center md:leading-[42px] font-medium pb-[28px]">
                        Product Categories
                    </h2>

                    <p className="max-w-[641px] ext-[15px] leading-[21px] font-normal max-md:text-center max-md:max-w-[343px] max-md:mx-auto">
                        Our products are developed on scientific principles,
                        innovation, with a deep understanding of human and
                        animal nutritional needs. They are trusted by businesses
                        and consumers worldwide. Kingenic possesses a robust
                        collection of brands and formulations strategically
                        poised to serve a crucial role for people across the
                        globe. In a rapidly expanding and increasingly relevant
                        sector, we are well-positioned. We offer a portfolio of
                        one thousand in-house formulas for contract
                        manufacturing and have nine Power Brands prepared for
                        distribution and vertical expansion. Additionally, we
                        provide exclusive contract offerings with special
                        incentives.
                    </p>
                </div>
            </div>

            {/* -------------------------------- */}
            {/* -- Header banner sectin start -- */}
            {/* -------------------------------- */}
            <div className="max-w-[1280px] mt-[54px] md:mt-[120px] w-full mx-auto">
                <div className="max-md:h-[659px] md:max-h-[725px] w-full rounded-lg max-md:rounded-none overflow-hidden relative">
                    <img
                        src={producBanner.banner}
                        alt=""
                        className="w-full h-full object-cover max-md:hidden"
                    />
                    <img
                        src={producBanner.bannerM}
                        alt=""
                        className="w-full h-full object-cover md:hidden"
                    />
                </div>
            </div>
            {/* -------------------------------- */}
            {/* -- Product list section End -- */}
            {/* -------------------------------- */}
            <div className="py-[100px] max-md:py-[70px]">
                <div className="custom__container">
                    <div className="flex items-center justify-center mb-[21px]">
                        <img
                            src={logos.logo__thin}
                            className="w-[56px]"
                            alt=""
                        />

                        <div className="h-[22px] w-[1px] mx-[10px] bg-black"></div>

                        <h2 className="text-[30px]  leading-[42px] font-medium">
                            PRODUCT HUB
                        </h2>
                    </div>

                    <p className="text-center text-[15px] leading-[21px] font-normal text-[#5f5f5f]">
                        Browse our products by categories and desired interest.
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px] mt-[30px] max-w-[900px] mx-auto">
                        {productItems?.map((item) => (
                            <div className="" key={item.title}>
                                <div className="max-w-[408px] mx-auto flex flex-col items-center justify-center">
                                    <div className="w-full">
                                        <img
                                            src={item.img}
                                            alt=""
                                            className="w-full"
                                        />
                                    </div>

                                    <h3 className="mt-[17px] text-center text-[22px] leading-[32px] font-medium ">
                                        {item.title}
                                    </h3>

                                    <p className="text-center text-[15px] font-normal my-[17px] max-w-[365px] mx-auto text-[#5F5F5F]">
                                        {item.paragraph}
                                    </p>

                                    <Link to={item.url}>
                                        <div className="px-[22px] py-[10px] rounded-[100px] border border-[#0f172e] text-center text-[16px] font-medium leading-[22px] text-[#0F172E]">
                                            Learn More
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* -------------------------------- */}
            {/* -- Product list section End -- */}
            {/* -------------------------------- */}

            {/* -------------------------------- */}
            {/* -- Product list section End -- */}
            {/* -------------------------------- */}
            <div className="custom__container">
                <div className="hidden max-md:block pb-[20px]">
                    <div className="max-w-[292px] mx-auto w-full">
                        <img src={products[4]} className="w-full" alt="" />
                    </div>
                </div>

                <h2 className="text-center text-[35px] leading-[52px] font-medium max-md:text-[15px] max-md:leading-[21px] ">
                    PRODUCT NAVIGATION MADE EASY
                </h2>

                <p className="text-center text-[15px] my-[20px] leading-[21px] font-normal max-md:max-w-[296px] max-md:mx-auto max-md:mt-[10px]">
                    Download our category brochures and product lists to explore
                    our products in the traditional way
                </p>
                <div className="max-md:shadow-lg max-md:border max-md:rounded-2xl max-md:w-fit max-md:mx-auto max-md:px-[10px]">
                    <div className="flex justify-between gap-[30px] max-w-[676px] mx-auto ">
                        <div className="text-[18px] font-medium leading-[28px] max-w-[317px] max-md:mx-auto max-md:p-[20px] ">
                            {pdnavigation.map((item) => (
                                <div key={item.title} className="">
                                    <h2 className="mb-[13px] max-md:text-[15px]">
                                        {item.title}
                                    </h2>
                                    <a
                                        target="_blank"
                                        href={`${item.url}`}
                                        className="text-[15px] leading-[21px] font-normal text-[#007AD9] underline"
                                        rel="noreferrer"
                                    >
                                        Download Now
                                    </a>

                                    <div className="w-full mt-[10px] mb-[10px] bg-[#007ad9] h-[1px] max-w-[273px]"></div>
                                </div>
                            ))}
                        </div>

                        <div className="max-md:hidden">
                            <div className="max-w-[292px] w-full">
                                <img
                                    src={products[4]}
                                    className="w-full"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto mb-[100px] max-md:mb-[28px] mt-[42px] w-fit px-[17px] py-[13px] rounded-[100px] md:bg-[#007AD9] cursor-pointer max-md:border max-md:border-black">
                        <p className="text-[16px] font-normal leading-[22px] text-center underline md:text-white">
                            Schedule A Free Brand Consultation
                        </p>
                    </div>
                </div>
            </div>

            {/* -------------------------------- */}
            {/* -- Product list section End -- */}
            {/* -------------------------------- */}

            <div className="bg-[#F2F2F2]/50 pt-[31px] pb-[22px] max-md:hidden ">
                <div className="custom__container">
                    <div className="flex rounded-[30px]  ">
                        <div className="publp1div ">
                            <p className="text-[15px] leading-[21px] font-normal ">
                                Manufacturing
                            </p>
                            <p className="text-[18px] leading-[25px] font-medium my-[27px] max-w-[278px]">
                                The standards is ours to set
                            </p>
                            <p className="text-[15px] leading-[21px] font-normal max-w-[278px] text-[#0F172E] natosans">
                                The complexity of production demands our full
                                attention from start to finish. The process of
                                blending various ingredients and ensuring they
                                deliver their intended benefit requires
                                continued testing at every stage of
                                manufacturing. Once products pass inspection,
                                labels are then verified so a have insight into
                                every ingredient.
                            </p>
                        </div>
                        <div className="publp2div  pl-[48px] ">
                            {fearuredData?.map((arr) => (
                                <div
                                    className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[23px] pb-[57px]  pr-[24px] border-b border-b-[#007ad9] last:border-none"
                                    key={arr}
                                >
                                    {arr.map((item) => (
                                        <div
                                            key={item.paragraph}
                                            className="max-w-[287px] mx-auto w-full"
                                        >
                                            <div className="w-full">
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="w-full"
                                                />
                                            </div>
                                            <p className="text-[20px] leading-[28px] font-normal text-center text-[#0f172e] mt-[11px] mb-[13px]">
                                                {item.paragraph}
                                            </p>

                                            <p className="text-[14px] font-medium leading-[20px] text-[#007AD9] text-center">
                                                {item.urlText}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-[20px] pt-[28px] md:hidden">
                <div className="">
                    <p className="text-[15px] leading-[21px] font-normal text-center">
                        Manufacturing
                    </p>
                    <p className="text-[18px] leading-[25px] font-medium my-[10px] text-center max-w-[255px] mx-auto">
                        The standards is ours to set
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal text-[#0F172E] natosans text-center">
                        The complexity of production demands our full attention
                        from start to finish. The process of blending various
                        ingredients and ensuring they deliver their intended
                        benefit requires continued testing at every stage of
                        manufacturing. Once products pass inspection, labels are
                        then verified so a have insight into every ingredient.
                    </p>
                </div>

                <div className="tipupa" id="topupa">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {fearuredData?.map((arr) => (
                            <div className="" key={arr}>
                                {arr.map((item) => (
                                    <SwiperSlide
                                        key={item.paragraph}
                                        className="w-full mt-[26px] pb-[40px]"
                                    >
                                        <div className="max-w-[287px] mx-auto">
                                            <img
                                                src={item.img}
                                                alt=""
                                                className="w-full"
                                            />
                                            <p className="text-[20px] leading-[28px] font-normal text-center text-[#0f172e] mt-[11px] mb-[13px]">
                                                {item.paragraph}
                                            </p>

                                            <p className="text-[14px] font-medium leading-[20px] text-[#007AD9] text-center">
                                                {item.urlText}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        ))}
                    </Swiper>
                </div>
            </div>

            <Subscribe />
            <Footer />
        </div>
    );
};

export default Product;

const OldStuff = () => {
    return (
        <div id="Product-container">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />
            <div className="Pmain">
                <div className="pcdiv">
                    <p className="pcCat">Product Categories</p>
                    <div className="pclngText">
                        <p className="pc1t">
                            Our products are developed on scientific principles,
                            innovation, with a deep understanding of human
                            needs. They are trusted by businesses and consumers
                            worldwide. Kingenic possesses a robust collection of
                            brands and formulations strategically poised to
                            serve a crucial role for people across the globe. In
                            a rapidly expanding and increasingly relevant
                            sector, we are well-positioned. We offer a portfolio
                            of one thousand in-house formulas for contract
                            manufacturing and have nine Power Brands prepared
                            for distribution and vertical expansion.
                            Additionally, we provide exclusive contract
                            offerings with special incentives.
                        </p>
                        {/* <p className="pc2t">
              Offering widest range of most advanced and convenient powdered
              formulas in the regions we serve.{" "}
            </p> */}
                    </div>
                </div>
                <div className="PbgBox"></div>

                <div className="cardbrc">
                    <div className="product-Hub-logo-div">
                        <div className="product-logo-div"></div>
                        <div className="product-vertical-line"></div>
                        <p className="product-hub-text">PRODUCT HUB</p>
                    </div>
                    <p className="product-exp-offering">
                        Explore our offerings
                    </p>
                    <div className="product-section-div">
                        <Link
                            to="/brand"
                            className="brnd-link"
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="cardbr1">
                                <div></div>
                                <p className="br1">Our Brands</p>
                                <p className="br2">
                                    Learn about our portfolio of brands for
                                    children and adults.
                                </p>
                                <p className="br3a">View branded portfolio</p>
                            </div>
                        </Link>
                        <Link
                            to="/private"
                            className="brnd-link"
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="cardbr1">
                                <div></div>
                                <p className="br1">Private Label Products</p>
                                <p className="br2">
                                    Wide range of formulas ready for your new
                                    brand or brand extension{' '}
                                </p>
                                <p className="br3b">View our offerings</p>
                            </div>
                        </Link>
                        <Link
                            to="/"
                            className="brnd-link"
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="cardbr1">
                                <div></div>
                                <p className="br1">Clinical Resources</p>
                                <p className="br2">
                                    Access relevant clinical studies and
                                    peer-reviewed articles to stay informed
                                </p>
                                <p className="br3c">Explore clinical papers</p>
                            </div>
                        </Link>
                        <Link
                            to="/"
                            className="brnd-link"
                            style={{ textDecoration: 'none' }}
                        >
                            <div className="cardbr1">
                                <div></div>
                                <p className="br1">Nutrition Resources</p>
                                <p className="br2">
                                    Informed decisions are good decisions and
                                    support your patients
                                </p>
                                <p className="br3d">Explore latest resource</p>
                            </div>
                        </Link>
                    </div>
                    <div className="product-section-div-m">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            spaceBetween={10}
                            slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <Link
                                    to="/brand"
                                    className="brnd-link"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div className="cardbr1">
                                        <div></div>
                                        <p className="br1">Our Brands</p>
                                        <p className="br2">
                                            Learn about our portfolio of brands
                                            for children and adults.
                                        </p>
                                        <p className="br3a">
                                            View branded portfolio
                                        </p>
                                    </div>
                                </Link>
                            </SwiperSlide>

                            <SwiperSlide>
                                <Link
                                    to="/private"
                                    className="brnd-link"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div className="cardbr1">
                                        <div></div>
                                        <p className="br1">
                                            Private Label Products
                                        </p>
                                        <p className="br2">
                                            Wide range of formulas ready for
                                            your new brand or brand extension{' '}
                                        </p>
                                        <p className="br3b">
                                            View our offerings
                                        </p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to="/"
                                    className="brnd-link"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div className="cardbr1">
                                        <div></div>
                                        <p className="br1">
                                            Clinical Resources
                                        </p>
                                        <p className="br2">
                                            Access relevant clinical studies and
                                            peer-reviewed articles to stay
                                            informed
                                        </p>
                                        <p className="br3c">
                                            Explore clinical papers
                                        </p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Link
                                    to="/"
                                    className="brnd-link"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <div className="cardbr1">
                                        <div></div>
                                        <p className="br1">
                                            Nutrition Resources
                                        </p>
                                        <p className="br2">
                                            Informed decisions are good
                                            decisions and support your patients
                                        </p>
                                        <p className="br3d">
                                            Explore latest resource
                                        </p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
                <div className="product-container-f">
                    <div className="p-c-f-divL"></div>
                    <div className="p-c-f-divR">
                        <p className="partner-p">
                            Partner with us in many ways
                        </p>
                        <p className="our-p-approach">
                            Our partnering approach is transparent, direct and
                            fully engaged with our leadership allowing us to
                            execute agreements quickly and effectively.{' '}
                        </p>
                        <div className="p-c-div-pr">
                            <div>
                                <p className="p-c-p1">Contract Manufacturing</p>
                                <p className="p-c-p2">
                                    We help you start a new brand or expand the
                                    existing brand
                                </p>
                                {/* <p className="p-c-p3">Click here to start you own brand </p> */}
                            </div>
                            <div>
                                <p className="p-c-p1">Product Development</p>
                                <p className="p-c-p2">
                                    We develop custom product formulas just for
                                    your brand
                                </p>
                                {/* <p className="p-c-p3">Unique product a click away</p> */}
                            </div>
                            <div>
                                <p className="p-c-p1">Clinical Trial</p>
                                <p className="p-c-p2">
                                    Offering clinical trials in areas of food
                                    and nutrition{' '}
                                </p>
                                {/* <p className="p-c-p3">Start a supplement trail today</p> */}
                            </div>
                            <div>
                                <p className="p-c-p1">Branded Products</p>
                                <p className="p-c-p2">
                                    We offer wide range of market ready brands
                                    for distribution
                                </p>
                                {/* <p className="p-c-p3">Becoming a distributor</p> */}
                            </div>
                        </div>
                        <button>Schedule A Discovery Call</button>
                    </div>
                </div>

                <div className="hrxdiv">
                    <div className="hrxdiv1">
                        <p className="man1">Manufacturing</p>
                        <p className="man2">The standards is ours to set</p>
                        <p className="man3">
                            The complexity of production demands our full
                            attention from start to finish. The process of
                            blending various ingredients and ensuring they
                            deliver their intended benefit requires continued
                            testing at every stage of manufacturing. Once
                            products pass inspection, labels are then verified
                            so a have insight into every ingredient.
                        </p>
                    </div>
                    <div className="hrxchdiv">
                        <div className="hrxdiv2">
                            <div className="htw"></div>
                            <p className="dc1">Quality Centric</p>
                            <div className="dct">
                                <p className="dc2">Commitment at every stage</p>
                                <RiArrowRightSLine size={22} color="#ff5914" />
                            </div>
                        </div>
                        <div className="hrxdiv2">
                            <div className="htw"></div>
                            <p className="dc1">Branded Ingredients</p>
                            <div className="dct">
                                <p className="dc2">Superior Sourcing</p>
                                <RiArrowRightSLine size={22} color="#ff5914" />
                            </div>
                        </div>
                        <div className="hrxdiv2">
                            <div className="htw"></div>
                            <p className="dc1">Sustainable Approach</p>
                            <div className="dct">
                                <p className="dc2">As easy as it gets</p>
                                <RiArrowRightSLine size={22} color="#ff5914" />
                            </div>
                        </div>
                    </div>
                    <div className="hrxchdiv-mob">
                        <Swiper
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            pagination={{ clickable: true }}
                            spaceBetween={10}
                            slidesPerView={1}
                            onSlideChange={() => console.log('slide change')}
                            onSwiper={(swiper) => console.log(swiper)}
                        >
                            <SwiperSlide>
                                <div className="hrxdiv2">
                                    <div className="htw"></div>
                                    <p className="dc1">Quality Centric</p>
                                    <div className="dct">
                                        <p className="dc2">
                                            Commitment at every stage
                                        </p>
                                        <RiArrowRightSLine
                                            size={22}
                                            color="#ff5914"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="hrxdiv2">
                                    <div className="htw"></div>
                                    <p className="dc1">Branded Ingredients</p>
                                    <div className="dct">
                                        <p className="dc2">Superior Sourcing</p>
                                        <RiArrowRightSLine
                                            size={22}
                                            color="#ff5914"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="hrxdiv2">
                                    <div className="htw"></div>
                                    <p className="dc1">Sustainable Approach</p>
                                    <div className="dct">
                                        <p className="dc2">
                                            As easy as it gets
                                        </p>
                                        <RiArrowRightSLine
                                            size={22}
                                            color="#ff5914"
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>

                {/* <div className="apexpandyour">
          <div className="apexpand1">
            <p className="apexpand1p1">
              Expand your product portfolio by featuring science based products
            </p>
            <p className="apexpand1p2">
              Collaborate with us. Book a free consultation today !
            </p>

            <div className="apemailinputbox">
              <input type="text" name="" id="apputbox" placeholder="Email" />
              <button>Subscribe</button>
            </div>
          </div>
          <div className="apexpand2">
            <p className="apexpand2p1">
              Are you looking for scientific white papers?
            </p>
            <p className="apexpand2p2">Request Clinical Resoureces</p>
          </div>
        </div>
        <div className="product-form">
          <p className="form-p1">
            Expand your product portfolio by featuring science based products
          </p>
          <p className="form-p2">
            Collaborate with us.
            <br /> Book a free consultation today !
          </p>
          <input type="text" name="" id="" placeholder="Name" />
          <input type="text" name="" id="" placeholder="Email Address" />
          <input type="text" name="" id="" placeholder="Phone Number" />
          <textarea placeholder="Tell us the area of your interest"></textarea>
          <button>Submit</button>
        </div> */}
                <DownloadCard />
                <Footer />
            </div>
        </div>
    );
};
