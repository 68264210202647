// Hero sliders
import slider1 from '../Assests/slider/1.png';
import slider2 from '../Assests/slider/2.png';
import slider3 from '../Assests/slider/3.png';
import slider4 from '../Assests/slider/4.png';

// Hero Sliders
export const sliderPhotos = [slider1, slider2, slider3, slider4];

// Logo
import logo from '../Assests/lo.png';
import logo2 from '../Assests/s-logos2.png';
import logo__thin from '../Assests/logo/logo__thin.png';

// Logo
export const logos = {
    logo,
    logo2,
    logo__thin,
};

// 2nd Hero section widset secton
import widset from '../Assests/widset/1.png';
export const h_hero2 = widset;

// about section images
import about1 from '../Assests/about/1.png';
import about2 from '../Assests/about/2.png';
import about3 from '../Assests/about/3.png';
import about4 from '../Assests/about/4.png';
import about5 from '../Assests/about/5.png';

export const aboutImg = [about1, about2, about3, about4, about5];

// BUSINESS SEGMENTS

import business1 from '../Assests/business/1.png';
import business2 from '../Assests/business/2.png';
import business3 from '../Assests/business/3.png';
import business4 from '../Assests/business/4.png';
import business5 from '../Assests/business/5.png';
import business6 from '../Assests/business/6.png';

export const businessImg = [
    business1,
    business2,
    business3,
    business4,
    business5,
    business6,
];

// MANUFECTURES
import manufacture1 from '../Assests/manufacture/1.png';
import manufacture2 from '../Assests/manufacture/2.png';
import manufacture3 from '../Assests/manufacture/3.png';
import manufacture4 from '../Assests/manufacture/4.png';
import manufacture5 from '../Assests/manufacture/5.png';

export const manufactureImg = [
    manufacture1,
    manufacture2,
    manufacture3,
    manufacture4,
    manufacture5,
];

//COMPANY
import cpny from '../Assests/company/1.png';
import cpny2 from '../Assests/company/2.png';

export const cpnyImg = {
    headeing: cpny,
    cpny2,
};

import cirtificate11 from '../Assests/company/11.png';
import cirtificate12 from '../Assests/company/12.png';
import cirtificate13 from '../Assests/company/13.png';
import cirtificate14 from '../Assests/company/14.png';
import cirtificate15 from '../Assests/company/15.png';
import cirtificate16 from '../Assests/company/16.png';
import cirtificate17 from '../Assests/company/17.png';
import cirtificate18 from '../Assests/company/18.png';

export const cirtificates = [
    cirtificate11,
    cirtificate12,
    cirtificate13,
    cirtificate14,
    cirtificate15,
    cirtificate16,
    cirtificate17,
    cirtificate18,
];

import infoImg1 from '../Assests/company/i1.png';
import infoImg2 from '../Assests/company/i2.png';
import infoImg3 from '../Assests/company/i3.png';
import infoImg4 from '../Assests/company/i4.png';

export const infoImg = [infoImg1, infoImg2, infoImg3, infoImg4];

import bus1 from '../Assests/company/b1.png';
import bus2 from '../Assests/company/b2.png';
import bus3 from '../Assests/company/b3.png';
import bus4 from '../Assests/company/b4.png';

export const cpbusImg = [bus1, bus2, bus3, bus4];

import nikita from '../Assests/company/Nikita.png';
import manan from '../Assests/company/Manan.png';

export const teamImg = {
    nikita,
    manan,
};

import news1 from '../Assests/company/n1.png';
import news2 from '../Assests/company/n2.png';
import news3 from '../Assests/company/n3.png';
import news4 from '../Assests/company/w.png';

export const newsImg = [news3, news2, news1, news4];

//Learning
import learningbanner from '../Assests/learning/1.png';
import learningbannerM from '../Assests/learning/b1.png';

export const learning = {
    banner: learningbanner,
    bannerMobile: learningbannerM,
};

import f1 from '../Assests/learning/f1.png';
import f2 from '../Assests/learning/f2.png';
import f3 from '../Assests/learning/f3.png';
import f4 from '../Assests/learning/f4.png';
import f5 from '../Assests/learning/f5.png';
import f6 from '../Assests/learning/f6.png';
import f7 from '../Assests/learning/f7.png';
import f8 from '../Assests/learning/f8.png';
import f9 from '../Assests/learning/f9.png';

export const featuredImg = [f1, f2, f3, f4, f5, f6, f7, f8, f9];

// Ingredients
import b1 from '../Assests/ingredients/b1.png';
import b2 from '../Assests/ingredients/b2.png';

export const ingredientsImg = {
    banner: b1,
    bannerM: b2,
};

import c1 from '../Assests/ingredients/c1.png';
import c2 from '../Assests/ingredients/c2.png';
import c3 from '../Assests/ingredients/c3.png';
import c4 from '../Assests/ingredients/c4.png';
import c5 from '../Assests/ingredients/c5.png';
import c6 from '../Assests/ingredients/c6.png';
import c7 from '../Assests/ingredients/c7.png';
import c8 from '../Assests/ingredients/c8.png';
import c9 from '../Assests/ingredients/c9.png';
import c10 from '../Assests/ingredients/c10.png';
import c11 from '../Assests/ingredients/c11.png';
import c12 from '../Assests/ingredients/c12.png';
import c13 from '../Assests/ingredients/c13.png';
import c14 from '../Assests/ingredients/c14.png';
import c15 from '../Assests/ingredients/c15.png';

export const ingrComImg = [
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
    c9,
    c10,
    c11,
    c12,
    c13,
    c14,
    c15,
];

import m1 from '../Assests/ingredients/m1.png';
import m2 from '../Assests/ingredients/m2.png';
import m3 from '../Assests/ingredients/m3.png';

export const manufacturerComImg = [m1, m2, m3];

//Products
import pb1 from '../Assests/product/b1.png';
import pb2 from '../Assests/product/b2.png';

import p1 from '../Assests/product/1.png';
import p2 from '../Assests/product/2.png';
import p3 from '../Assests/product/3.png';
import p4 from '../Assests/product/4.png';
import p5 from '../Assests/product/5.png';

export const producBanner = {
    banner: pb1,
    bannerM: pb2,
};

export const products = [p1, p2, p3, p4, p5];

// Branded portfoli
import banner from '../Assests/branded/bg.png';
import bl1 from '../Assests/branded/l1.png';
import bl2 from '../Assests/branded/l2.png';
import bl3 from '../Assests/branded/l3.png';
import bl4 from '../Assests/branded/l4.png';
import br1 from '../Assests/branded/1.png';
import br2 from '../Assests/branded/2.png';
import br3 from '../Assests/branded/3.png';
import chk1 from '../Assests/branded/b1.png';
import chk2 from '../Assests/branded/b2.png';
import chk3 from '../Assests/branded/b3.png';
import chk4 from '../Assests/branded/b4.png';
import chk5 from '../Assests/branded/b5.png';

export const branded = {
    banner,
    partner: [bl1, bl2, bl3, bl4],
    brandedImg: [br1, br2, br3],
    chk: [chk1, chk2, chk3, chk4, chk5],
};

// Private label prodcts
import plb1 from '../Assests/private/b1.png';
import plb2 from '../Assests/private/b2.png';
import pla1 from '../Assests/private/a1.png';
import pla2 from '../Assests/private/a2.png';
import pl1 from '../Assests/private/1.png';
import pl2 from '../Assests/private/2.png';
import pl3 from '../Assests/private/3.png';
import pl4 from '../Assests/private/4.png';
import pl5 from '../Assests/private/5.png';
import pl6 from '../Assests/private/6.png';
import pl7 from '../Assests/private/7.png';
import pl8 from '../Assests/private/8.png';
import pl9 from '../Assests/private/9.png';
import pl10 from '../Assests/private/10.png';
import pl11 from '../Assests/private/11.png';
import pl12 from '../Assests/private/12.png';
import pl13 from '../Assests/private/13.png';
import pl14 from '../Assests/private/14.png';
import pl15 from '../Assests/private/15.png';

export const privateLabel = {
    banner: [plb1, plb2],
    cardimg: [pla1, pla2],
    privateImg: [
        pl1,
        pl2,
        pl3,
        pl4,
        pl5,
        pl6,
        pl7,
        pl8,
        pl9,
        pl10,
        pl11,
        pl12,
        pl13,
        pl14,
        pl15,
    ],
};

//contact
import co1 from '../Assests/contact/1.png';
import co2 from '../Assests/contact/2.png';

export const contact = [co1, co2];

//pdfs
import pdf_fssai from '../Assests/pdf/FSSAI LIC 2024.pdf';
import pdf_usfda from '../Assests/pdf/USFDA Food Facility Registration (FFR) - View Registration 2024.pdf';
import pdf_dippt from '../Assests/pdf/DIPPT.pdf';
import pdf_zed from '../Assests/pdf/Kingenic ZED Pledge.pdf';
import pdf_gmp from '../Assests/pdf/GMP.pdf';
import pdf_kosher from '../Assests/pdf/KOSHER.pdf';
import pdf_haccp from '../Assests/pdf/HACCP.pdf';
import pdf_halal from '../Assests/pdf/HALAL.pdf';

import pdf_animal from '../Assests/pdf/Animal Nutrition Brochure .pdf';
import pdf_nutition from '../Assests/pdf/Defence Nutrition Brochure .pdf';
import pdf_food from '../Assests/pdf/Food & Beverages Product List.pdf';
import pdf_generic from '../Assests/pdf/Generic Supplement Brochure.pptx';
import pdf_mal from '../Assests/pdf/Malnutrition_Brochure (1).pdf';
import pdf_brocu from '../Assests/pdf/Specialized Supplements Brochure.pdf';

export const pdfs = {
    fssai: pdf_fssai,
    usfda: pdf_usfda,
    dippt: pdf_dippt,
    zed: pdf_zed,
    gmp: pdf_gmp,
    kosher: pdf_kosher,
    haccp: pdf_haccp,
    halal: pdf_halal,
    animal: pdf_animal,
    nutition: pdf_nutition,
    food: pdf_food,
    generic: pdf_generic,
    mal: pdf_mal,
    brochure: pdf_brocu,
};

export const footerPages = [
    {
        label: 'Company',
        url: 'company',
    },
    {
        label: 'Our Products',
        url: 'product',
    },
    {
        label: 'Branded Products',
        url: 'brandedproduct',
    },
    {
        label: 'Learning Center',
        url: 'learningcentre',
    },
    {
        label: 'Private Product',
        url: 'privatelabel',
    },
    {
        label: 'Our Ingredients',
        url: 'ingredients',
    },
    {
        label: 'Contact us',
        url: 'contactus',
    },
];
