import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import Navbar from '../Contents/Navbar';
import '../Styles/BrandPage.css';
import '../Styles/fonts.css';
// import AnimatedCursor from "react-animated-cursor";

import { Link } from 'react-router-dom';
import 'swiper/css/pagination';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import wuv from '../Assests/cha.png';
import Footer from '../Contents/Footer';
import DownloadCard from '../Pages/DownloadCard';
// Import Swiper styles
import 'swiper/css';
const BrandPage = () => {
    const isMobile = window.innerWidth <= 768;
    return (
        <div className="Brandpage-container">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />
            <div className="brndpmain">
                <div className="bp-container-1">
                    <img src={wuv} alt="" />
                </div>
                <div className="Brand-menu">
                    <p>PRODUCTS</p>
                    <p>ABOUT WUNE</p>
                    <p>NUTRITION & HEALING</p>
                    <p>RECIPES</p>
                    <p>FOR HCPS</p>
                </div>
                <div className="Brand-menu-for-mobile">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2.5}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <p className="menu-p">ABOUT WUNE</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <p className="menu-p">PRODUCTS</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <p className="menu-p" id="mmmp-1">
                                NUTRITION & HEALING
                            </p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <p className="menu-p">RECIPES</p>
                        </SwiperSlide>
                        <SwiperSlide>
                            <p className="menu-p">FOR HCPS</p>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <div className="bp-container-2">
                    <div className="bp-container-c-for-image"></div>
                    <div className="bp-container-2-a"></div>
                    <div className="bp-container-2-b">
                        <p>RECOVERY THAT GOES BEYOND SURFACE</p>
                    </div>
                </div>
                <p className="redefining-wound">
                    Redefining wound care nutrition with world&apos;s first
                    wound management brand
                </p>
                <p className="if-you-are-wune">
                    If you are Wune, innovative brand developed by Kingenic that
                    specializes in wound management. We assist healthcare
                    professionals in achieving optimal healing by harnessing the
                    power of nutrition. Whether it&apos;s pre-surgery,
                    post-surgery, or disease-specific wound care, we provide a
                    comprehensive range of products for accelerated healing.
                </p>
                <div className="bp-container-3">
                    <div>
                        <div className="bp-circle"></div>
                        <div className="bp-text-circle">
                            <p>Pressure Ulcers</p>
                        </div>
                    </div>
                    <div>
                        <div className="bp-circle"></div>
                        <div className="bp-text-circle">
                            <p>Diabetic Foot Ulcers</p>
                        </div>
                    </div>
                    <div>
                        <div className="bp-circle"></div>
                        <div className="bp-text-circle">
                            <p>Surgical Incisions</p>
                        </div>
                    </div>
                    <div>
                        <div className="bp-circle"></div>
                        <div className="bp-text-circle">
                            <p>Burns</p>
                        </div>
                    </div>
                    <div>
                        <div className="bp-circle"></div>
                        <div className="bp-text-circle">
                            <p>Radiotherapy Induced Injuries</p>
                        </div>
                    </div>
                    <div>
                        <div className="bp-circle"></div>
                        <div className="bp-text-circle">
                            <p>Vascular Wounds</p>
                        </div>
                    </div>
                </div>
                <div className="bp-container-4">
                    <p>
                        Wune, world’s only complete advanced wound management
                        nutrition brand.{' '}
                    </p>
                </div>
                <div className="bp-line-1"></div>
                <div className="bp-container-5">
                    <div>
                        <div className="bp-brand-image"></div>
                        <p className="s-suuport">SURGERY SUPPORT </p>
                        <p className="wune-complete-1">
                            Wune Complete 1.0 is nutritionally complete food for
                            special medical purpose for preoperative nutritional
                            support. It is powdered oral feed that provides 1.0
                            kcal/ml when .
                        </p>
                        <Link to="/brandedproduct">
                            <p className="view-product-information">
                                View Product Information
                            </p>
                        </Link>
                    </div>
                    <div>
                        <div className="bp-brand-image"></div>
                        <p className="s-suuport">SURGERY SUPPORT </p>
                        <p className="wune-complete-1">
                            Wune Complete 1.0 is nutritionally complete food for
                            special medical purpose for preoperative nutritional
                            support. It is powdered oral feed that provides 1.0
                            kcal/ml when .
                        </p>
                        <Link to="/brandedproduct">
                            <p className="view-product-information">
                                View Product Information
                            </p>
                        </Link>
                    </div>
                    <div>
                        <div className="bp-brand-image"></div>
                        <p className="s-suuport">SURGERY SUPPORT </p>
                        <p className="wune-complete-1">
                            Wune Complete 1.0 is nutritionally complete food for
                            special medical purpose for preoperative nutritional
                            support. It is powdered oral feed that provides 1.0
                            kcal/ml when .
                        </p>
                        <Link to="/brandedproduct">
                            <p className="view-product-information">
                                View Product Information
                            </p>
                        </Link>
                    </div>
                    <div>
                        <div className="bp-brand-image"></div>
                        <p className="s-suuport">SURGERY SUPPORT </p>
                        <p className="wune-complete-1">
                            Wune Complete 1.0 is nutritionally complete food for
                            special medical purpose for preoperative nutritional
                            support. It is powdered oral feed that provides 1.0
                            kcal/ml when .
                        </p>
                        <Link to="/brandedproduct">
                            <p className="view-product-information">
                                View Product Information
                            </p>
                        </Link>
                    </div>
                    <div>
                        <div className="bp-brand-image"></div>
                        <p className="s-suuport">SURGERY SUPPORT </p>
                        <p className="wune-complete-1">
                            Wune Complete 1.0 is nutritionally complete food for
                            special medical purpose for preoperative nutritional
                            support. It is powdered oral feed that provides 1.0
                            kcal/ml when .
                        </p>
                        <Link to="/brandedproduct">
                            <p className="view-product-information">
                                View Product Information
                            </p>
                        </Link>
                    </div>
                </div>
                {/* <div className="bp-line-2"></div> */}
                <div className="bp-container-6">
                    <div>
                        <div className="bp-c-6-a"></div>
                        <div className="bp-c-6-b">
                            <div className="bp-c-aero-div">
                                <p>Nutrition & Healing</p>
                                <RiArrowRightSLine size={30} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bp-c-6-a"></div>
                        <div className="bp-c-6-b">
                            <div className="bp-c-aero-div">
                                <p>Recipes</p>
                                <RiArrowRightSLine size={30} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="bp-c-6-a"></div>
                        <div className="bp-c-6-b">
                            <div className="bp-c-aero-div">
                                <p>Where to Find</p>
                                <RiArrowRightSLine size={30} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bp-container-6-for-mobile">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        pagination={{ clickable: true }}
                        spaceBetween={30}
                        slidesPerView={1}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                    >
                        <SwiperSlide>
                            <div className="mob-l">
                                <div className="bp-c-6-a"></div>
                                <div className="bp-c-6-b">
                                    <div className="bp-c-aero-div">
                                        <p>Where to Find</p>
                                        <RiArrowRightSLine size={30} />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="mob-l">
                                <div className="bp-c-6-a"></div>
                                <div className="bp-c-6-b">
                                    <div className="bp-c-aero-div">
                                        <p>Where to Find</p>
                                        <RiArrowRightSLine size={30} />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="mob-l">
                                <div className="bp-c-6-a"></div>
                                <div className="bp-c-6-b">
                                    <div className="bp-c-aero-div">
                                        <p>Where to Find</p>
                                        <RiArrowRightSLine size={30} />
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <p className="bp-c-disclaimer">
                    Disclaimer : Use Wune products must be under medical
                    supervision in addition to a complete, balanced diet.
                    <br />
                    <br />
                    The information on this page is intended for healthcare
                    professionals only. If you arent a healthcare professional,
                    please consult a healthcare professional prior usage. Wune
                    is a registered trademark of Kingenic Lifescience Private
                    Limited - India. Licensed to Kingenic Consumer Healthcare -
                    USA for worldwide distribution
                </p>

                <DownloadCard />
                <Footer />
            </div>
        </div>
    );
};

export default BrandPage;
