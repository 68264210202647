/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { TiArrowRight } from 'react-icons/ti';
import { aboutImg } from '../../utils/assets';

const AboutCardsMobile = () => {
    const [selectedDmx, setSelectedDmx] = useState('Nutrition Solutions');
    const [selectedDmxImg, setSelectedDmxImg] = useState('Nutrition Solutions');
    const handleDmxClick = (dmx) => {
        setSelectedDmx(dmx);
    };

    useEffect(() => {
        const dmsx = Object.keys(BusinessDivisionCardsmobilex);
        const dmsimg = Object.keys(BusinessDivisionImg);
        let currentIndex = dmsx.indexOf(selectedDmx);
        let currentIndexImg = dmsx.indexOf(selectedDmxImg);

        const intervalId = setInterval(() => {
            currentIndex = (currentIndex + 1) % dmsx.length;
            currentIndexImg = (currentIndexImg + 1) % dmsimg.length;
            setSelectedDmx(dmsx[currentIndex]);
            setSelectedDmxImg(dmsimg[currentIndexImg]);
        }, 5000); // Change the interval duration as needed (in milliseconds)

        return () => clearInterval(intervalId);
    }, [selectedDmx]);

    const BusinessDivisionImg = {
        'Nutrition Solutions': (
            <div className="bgVideo1x max-h-[400px]">
                <img
                    className="w-full h-full object-cover"
                    src={aboutImg[0]}
                    alt=""
                />
            </div>
        ),
        'Medical Food': (
            <div className="bgVideo1x max-h-[400px]">
                <img
                    className="w-full h-full object-cover"
                    src={aboutImg[1]}
                    alt=""
                />
            </div>
        ),
        'Premix Solutions': (
            <div className="bgVideo1x max-h-[400px]">
                <img
                    className="w-full h-full object-cover"
                    src={aboutImg[2]}
                    alt=""
                />
            </div>
        ),
        'Defence Solutions': (
            <div className="bgVideo1x max-h-[400px]">
                <img
                    className="w-full h-full object-cover"
                    src={aboutImg[3]}
                    alt=""
                />
            </div>
        ),
        'Nutrifeed Division': (
            <div className="bgVideo1x max-h-[400px]">
                <img
                    className="w-full h-full object-cover"
                    src={aboutImg[4]}
                    alt=""
                />
            </div>
        ),
    };

    const BusinessDivisionCardsmobilex = {
        'Nutrition Solutions': (
            <div className="nutri-sol-cardxyx">
                <p className="nutri-solutions-textxyx">Who</p>
                <p className="Developing-and-reinventionxyx">
                    Kingenic is a multifunctional nutrition product solution
                    partner with key focus on end-to-end solutions with highest
                    quality, affordable oral nutrition supplements (ONS) for a
                    healthier tommorow.
                </p>
                <div className="exp-more1">
                    <button>Explore More</button>
                    <TiArrowRight />
                </div>
            </div>
        ),
        'Medical Food': (
            <div className="nutri-sol-cardxyx">
                <p className="nutri-solutions-textxyx">What</p>
                <p className="Developing-and-reinventionxyx">
                    We provide comprehensive solutions to brand owners and
                    manufacturers by offering customised product formulas,
                    packaging and brand design solutions. Developing purposeful
                    partnerships for mutual growth
                </p>
                <div className="exp-more1">
                    <button>Explore More</button>
                    <TiArrowRight />
                </div>
            </div>
        ),
        'Premix Solutions': (
            <div className="nutri-sol-cardxyx">
                <p className="nutri-solutions-textxyx">Why</p>
                <p className="Developing-and-reinventionxyx">
                    Experience the difference of partnering with us as we offer
                    more than just reliability; we provide a comprehensive,
                    innovative, purpose-led collaboration. We want to contribute
                    to a brighter world and better nutrition for all.
                </p>
                <div className="exp-more1">
                    <button>Explore More</button>
                    <TiArrowRight />
                </div>
            </div>
        ),
        'Defence Solutions': (
            <div className="nutri-sol-cardxyx">
                <p className="nutri-solutions-textxyx">When</p>
                <p className="Developing-and-reinventionxyx">
                    We excel at overcoming challenges in product launches,
                    making us the perfect partner for co-innovation across all
                    development stages, from concept to consumer. We support all
                    at any stage of ideation.
                </p>
                <div className="exp-more1">
                    <button>Explore More</button>
                    <TiArrowRight />
                </div>
            </div>
        ),
        'Nutrifeed Division': (
            <div className="nutri-sol-cardxyx">
                <p className="nutri-solutions-textxyx">How</p>
                <p className="Developing-and-reinventionxyx">
                    We passionately collaborate with stakeholders, ensuring
                    top-tier customer service irrespective of project size. We
                    guide your idea to become a revenue-generating product.
                </p>
                <div className="exp-more1">
                    <button>Explore More</button>
                    <TiArrowRight />
                </div>
            </div>
        ),
    };

    return (
        <div className="md:hidden pt-[52px]">
            <div className="business-containerxyx">
                <p className="b-divsionx">About us</p>
                <div className="bsc-divx">
                    {BusinessDivisionImg[selectedDmxImg]}
                </div>
                <div className="business-menuxyx">
                    {Object.keys(BusinessDivisionCardsmobilex).map((dmx) => (
                        <p
                            key={dmx}
                            onClick={() => handleDmxClick(dmx)}
                            className={selectedDmx === dmx ? 'selected' : ''}
                        >
                            {dmx}
                        </p>
                    ))}
                </div>
                <div className="division-cardsxyx">
                    {BusinessDivisionCardsmobilex[selectedDmx]}
                </div>
            </div>
        </div>
    );
};

export default AboutCardsMobile;
