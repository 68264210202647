import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Footer from '../Contents/Footer';
import '../Styles/Cardslider.css';
import '../Styles/fonts.css';
// import AnimatedCursor from "react-animated-cursor";
import { CiCalendar } from 'react-icons/ci';
import { FaAngleRight } from 'react-icons/fa6';
import slogo from '../Assests/s-logos2.png';
import Navbar from '../Contents/Navbar';
import Tavbar from '../Contents/Tavbar';
import clientlogo1 from './../Assests/clientlogo1.png';
import clientlogo2 from './../Assests/clientlogo2.png';
import clientlogo3 from './../Assests/clientlogo3.png';
import clientlogo4 from './../Assests/clientlogo4.png';
import coffee from './../Assests/coffee.png';
import homesliderimg from './../Assests/homesliderimg.png';
import hubimg from './../Assests/hubimg.png';
import vlogimg1 from './../Assests/vlogimg1.png';
import vlogimg2 from './../Assests/vlogimg2.png';

import AboutCardsSection from '../Components/home/AboutCards.jsx';
import AboutCardsMobile from '../Components/home/AboutCardsMobile.jsx';
import HubsCard from '../Components/home/HubsCard.jsx';
import ManufecturerSlider from '../Components/home/Manufecturer/MnufecturerSlider.jsx';
import ExpendableFAQ from '../Components/shared/ExpendableFAQ.jsx';
import Slider from '../Components/shared/Slider.jsx';
import '../Styles/Home.css';
import { businessImg, h_hero2, logos } from '../utils/assets.js';
import Subscribe from '../Components/shared/Subscribe.jsx';
import { Link } from 'react-router-dom';

const Home = () => {
    const [heartTextIndex, setHeartTextIndex] = useState(0);

    const heartTextVariants = [
        'nutraceuticals',
        'boost life',
        'savor nutrition',
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setHeartTextIndex(
                (prevIndex) => (prevIndex + 1) % heartTextVariants.length
            );
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, [heartTextVariants.length]);

    const businessSegments = [
        {
            title: 'Dietary Supplements / Nutraceuticals',
            desc: 'Offering advanced combinations of Dietary fibre, Probiotics, Prebiotics, Polyunsaturated fatty acids, Polyphenols, Functional spices, Single and Complex botanical, Vitamins and mineral combinations.',
            subtitle: 'Essential Supplements, Exceptional Results.',
            paragraph:
                'Elevate your health and wellness business with our cutting-edge supplement offerings. Harness the power of premium ingredients and scientific formulation to meet the growing demand for nutritional support. Boost customer satisfaction and drive revenue by expanding your product portfolio with our high-quality supplements.',
            img: businessImg[0],
        },
        {
            title: 'Performance Nutrition',
            desc: 'Elevate your portfolio with differentiated sports nutrition offerings with our Dairy proteins, Plant proteins, Amino acids, Nitric oxide Boosters,Complex carbohydrates, Performance botanical compounds.',
            subtitle: 'We think beyond proteins',
            paragraph:
                'Unleash peak performance with our advanced Performance Nutrition solutions. Elevate your athletic prowess with scientifically crafted sports nutrition supplements. From pre-workout energy to post-recovery essentials, our products fuel champions. Take the lead in the competitive market – power your success with our superior formulations.',
            img: businessImg[1],
        },
        {
            title: 'Medical Nutrition',
            desc: 'In the medical nutrition sector, purpose is pivotal. We blend curiosity, insights, and innovation into each product. Beyond ingredients, a committed partnership is essential for healthcare nutrition success.',
            subtitle: 'Enhancing quality of life',
            paragraph:
                'Ensuring timely access to proper nutritional care is essential for safeguarding the health of patients and the elderly, reducing medical complications, and promoting recovery and independence. Developing insight-driven medical nutrition products goes beyond ingredients; it necessitates a partner committed to human-centric approaches.',
            img: businessImg[2],
        },
        {
            title: 'Cosmetology Nutrition',
            desc: 'Elevate beauty from within. Nourish your skin with our premium cosmetic nutrition. Radiant results, inside and out. Unveil your natural glow, embrace beauty through holistic wellness',
            subtitle: 'Skin & hair food redefined',
            paragraph:
                'Transform your beauty from the inside out with our exclusive cosmetic nutrition. Pamper your skin with premium care for radiant results. Discover your natural glow and embrace beauty through a holistic approach to wellness. Our products are designed to nourish and enhance, reflecting the synergy of inner health and outer radiance.',
            img: businessImg[3],
        },
        {
            title: 'Food and Beverages',
            desc: 'Through our advanced R&D, we are dedicated to enchanting global consumers with uniquely crafted and flavorful food and beverage offerings. Enhancing everyday culinary experiences .',
            subtitle: 'Advanced food solutions',
            paragraph:
                'Specializing in high-growth categories such as coffee, tea, food fortification, and instant liquid breakfasts, we are dedicated to reshaping product portfolios and creating innovative solutions that cater to diverse consumer needs. Through our endeavors, we strive to redefine the way people experience and benefit from everyday essentials.',
            img: businessImg[4],
        },
        {
            title: 'Pet Nutrition',
            desc: "Crafting excellence for your pet's well-being. Our pet nutrition manufacturing ensures top-quality, nutritious products. Trust us to create the foundation for your pet's vibrant health and happiness.",
            subtitle: 'Pets Thrive, We Care.',
            paragraph:
                'At the heart of our pet nutrition manufacturing lies a commitment to excellence. We meticulously craft each product to ensure the well-being of your beloved companions. From sourcing premium ingredients to employing stringent quality control measures, we prioritize the nutritional needs of pets. ',
            img: businessImg[5],
        },
    ];

    const HubsCards = [
        {
            title: 'PRODUCT HUB',
            paragraph:
                'Discover our product and services via our product hub. Products that help you grow your portfolio.',
            urltitle: 'Explore our products',
            url: '/product',
        },
        {
            title: 'CARE HUB',
            paragraph:
                'Access product documentation anywhere, anytime. From contracts to  regulatory paperwork',
            urltitle: 'Access customer portal',
            url: '',
        },
        {
            title: 'LEARNING HUB',
            paragraph:
                'Access free, expert-created education based on your interests and needs.',
            urltitle: 'Explore More',
            url: '/learningcentre',
        },
    ];
    return (
        <div id="homeContainer">
            {/* {!isMobile && <AnimatedCursor />} */}

            <Navbar />
            <Tavbar />
            {/* -------------------------------- */}
            {/* --- Hero section slider start -- */}
            {/* -------------------------------- */}
            <Slider />
            {/* -------------------------------- */}
            {/* --  Hero section slider start -- */}
            {/* -------------------------------- */}
            <div className="mainc ">
                <div className="max-w-[934px] px-[20px] mx-auto pt-[60px]">
                    <div className="w-full h-full overflow-hidden rounded-md">
                        <img
                            src={h_hero2}
                            className="w-full h-full object-cover"
                            alt="cover image"
                        />
                    </div>

                    <div className="my-[60px] tracking-[3px] uppercase font-medium text-[16px] text-center">
                        WIDEST RANGE OF POWDER FORMULAS
                    </div>
                </div>

                <div className="horizontal-text-boxm custom__container">
                    <div className="h-tboxm">
                        <p className="hbp1m">100+</p>
                        <p className="hbp2m">FOOD SPRINKLES</p>
                    </div>
                    <div className="h-tboxm">
                        <p className="hbp1m">300+</p>
                        <p className="hbp2m">MOUTH MELTS</p>
                    </div>
                    <div className="h-tboxm">
                        <p className="hbp1m">500+</p>
                        <p className="hbp2m">DRINK MIXES</p>
                    </div>
                    <div className="h-tboxm">
                        <p className="hbp1m">1000+</p>
                        <p className="hbp2m">READY FORMULAS</p>
                    </div>
                </div>

                {/* -------------------------------- */}
                {/* -- About section slider start -- */}
                {/* -------------------------------- */}
                <AboutCardsSection />

                <AboutCardsMobile />
                {/* -------------------------------- */}
                {/* -- About section slider End -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- Business segmt sectin Start-- */}
                {/* -------------------------------- */}
                <div className="bg-[#979797] bg-opacity-5 py-[25px]">
                    <div className="custom__container">
                        <div className="flex items-center justify-center mb-[17px]">
                            <div className="w-[50px] md:w-[55px]">
                                <img
                                    src={logos.logo2}
                                    alt=""
                                    className="w-full"
                                />
                            </div>
                            <div className="line-x"></div>
                            <p className="text-[18px] max-md:mt-0.5 leading-[26px] md:text-[30px] md:leading-[42px] font-medium">
                                BUSINESS SEGMENTS
                            </p>
                        </div>
                        <p className="text-center text-[18px] md:text-[20px] font-normal max-md:max-w-[237px] max-md:mx-auto text-[#007AD9] leading-[27px] ">
                            Health categories we develop and manufacture
                        </p>

                        <div className="max-w-[870px] w-full mx-auto md:px-[20px] mt-[35px]">
                            {businessSegments.map((item, i) => (
                                <ExpendableFAQ
                                    key={item.title + i}
                                    title={item.title}
                                    desc={item.desc}
                                    subtitle={item.subtitle}
                                    paragraph={item.paragraph}
                                    img={item.img}
                                    index={1 + i}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* -- Business segmt section End -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- Menufecturer section start -- */}
                {/* -------------------------------- */}
                <div className="card-container-c ">
                    <p className="extrp2">
                        We develop and manufacture products that are unique and
                        marketable
                    </p>
                    <p className="max-w-[866px] mb-[28px] text-center text-[25px] md:text-[35px] font-medium leading-[40px] md:leading-[52px] mx-auto max-md:px-[45px] max-md:mt-[33px]">
                        Our clients have hugely successful brands that can be
                        found in the biggest stores and platforms
                    </p>
                    <ManufecturerSlider />
                </div>
                {/* -------------------------------- */}
                {/* -- Menufecturer section End -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- Explore viahub section Start -- */}
                {/* -------------------------------- */}
                <div className="pt-[44px] md:pt-[80px] pb-[75px] md:pb-[100px]">
                    <p className="exp-via-hubs">Explore via hubs</p>
                    <p className="we-have-created">
                        Our hubs help you navigate to sections that really
                        matters to you the most.
                    </p>

                    <div className="custom__container">
                        <div className="max-w-[681px] w-full mx-auto mt-[33px] md:mt-[100px]">
                            <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-[32px] ">
                                <div className="w-full">
                                    {HubsCards?.map((item) => (
                                        <HubsCard
                                            key={item.paragraph}
                                            title={item.title}
                                            paragraph={item.paragraph}
                                            urltitle={item.urltitle}
                                            url={item.url}
                                        />
                                    ))}
                                </div>

                                <div className="flex justify-center">
                                    <img
                                        className="w-full md:max-w-[290px]"
                                        src={hubimg}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* -- Explore viahub section End -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- Click away section start -- */}
                {/* -------------------------------- */}
                <div className="bg-[#979797] bg-opacity-[0.05] w-full py-[75px] md:py-[100px]">
                    <div className="custom__container">
                        <div className="flex flex-col-reverse md:grid md:grid-cols-2 gap-[20px]">
                            <div className="max-md:text-center">
                                <p className="text-[14px] font-normal text-[#007ad9]">
                                    Want to develop a product or start a brand?
                                </p>

                                <h2 className="text-[20px] md:text-[35px] font-medium leading-[28px] md:leading-[49px]">
                                    We are just a click away !
                                </h2>

                                <p className="text-[15px] leading-[25px] font-normal my-[16px] text-[#50504E]">
                                    We do things a bit differently at Kingenic.
                                    Schedule a call and discover a personalised
                                    approach for your brand goals.
                                </p>

                                <div className="">
                                    <h4 className="text-[15px] mb-[16px] leading-[20px] md:text-[20px]  md:leading-[28px] font-medium ">
                                        Product Development & Manufacturing
                                    </h4>

                                    <p className="text-[15px] ] leading-[25px] font-normal  text-[#50504E]">
                                        Let us know your target consumer and
                                        target market and we do the rest
                                    </p>

                                    <Link
                                        to="/contactus"
                                        className="flex items-center max-md:justify-center gap-1 text-[#007AD9]"
                                    >
                                        <p className="text-[15px] leading-[25px] font-normal  ">
                                            Send a Development Request
                                        </p>

                                        <FaAngleRight
                                            size={13}
                                            className="hidden md:block"
                                        />
                                    </Link>
                                </div>

                                <div className="mt-[19px]">
                                    <h4 className="text-[15px] mb-[16px] leading-[20px] md:text-[20px]  md:leading-[28px] font-medium ">
                                        Branded Products
                                    </h4>

                                    <p className="text-[15px] ] leading-[25px] font-normal  text-[#50504E]">
                                        We offer the most advanced market ready
                                        brands for your distribution portfolio.
                                        Schedule a call today
                                    </p>

                                    <Link
                                        to="/contactus"
                                        className="flex items-center max-md:justify-center gap-1 text-[#007AD9]"
                                    >
                                        <p className="text-[15px] leading-[25px] font-normal  ">
                                            Become a distributor
                                        </p>

                                        <FaAngleRight
                                            size={13}
                                            className="mt-0.5 hidden md:block"
                                        />
                                    </Link>
                                </div>
                            </div>

                            <div className="w-full rounded-lg overflow-hidden max-h-[421px]">
                                <img
                                    className="h-full w-full object-cover"
                                    src={coffee}
                                    alt="coffie"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* -- Click away section End -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- Leading Brand section strt -- */}
                {/* -------------------------------- */}
                <div className="hidden md:block">
                    <div className="custom__container">
                        <div className="py-[100px]">
                            <p className="text-center uppercase tracking-[2px] text-[14px] leading-[26px] font-bold text-[#007ad9]">
                                Our leading brands built on science, innovation
                                and human understanding
                            </p>
                            <p className="text-[35px] text-center font-medium max-w-[866px] mx-auto mt-[13px]">
                                Our variety of brands bring good health and
                                delight to people around the world
                            </p>
                            <div className="flex items-center justify-between">
                                <img src={clientlogo1} alt="" />
                                <img src={clientlogo2} alt="" />
                                <img src={clientlogo3} alt="" />
                                <img src={clientlogo4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* -- Leading Brand section end -- */}
                {/* -------------------------------- */}

                <div className="hidden lg:block bg-[#979797] bg-opacity-[0.05] py-[45px]">
                    <div className="custom__container ">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <img
                                    src={logos.logo2}
                                    alt="logo"
                                    className="w-[46px] mb-1"
                                />
                                |
                                <p className="text-[24px] font-medium leading-[34px] ml-4 uppercase">
                                    LEARNING HUB
                                </p>
                            </div>

                            <div className="flex items-center gap-3">
                                <p className="text-[15px] font-normal leading-[21px] text-[#007ad9]">
                                    @ TrendingShorties
                                </p>

                                <p className="uppercase text-[14px] font-bold leading-[26px] underline tracking-[2px]  text-[#007ad9] cursor-pointer">
                                    VIEW ALL
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center justify-between gap-[50px]">
                            <div className="">
                                <h2 className="text-[24px] leading-[29px] font-medium max-w-[761px]">
                                    Probiotics for Your Family’s Immunity, How
                                    does it help in pregnancy and overall health
                                </h2>

                                <div className="flex items-center gap-4 mt-[10px]">
                                    <div className="flex items-center gap-1 text-[#727272]">
                                        <CiCalendar
                                            size={15}
                                            className="mb-1"
                                            strokeWidth={1}
                                        />
                                        <p className="text-[12px] font-normal">
                                            26 Oct, 23
                                        </p>
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        PROBIOTICS
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        GUT HEALTH
                                    </div>
                                </div>
                                <p className="mt-[9px] text-[15px] leading-[21px] font-normal text-[#50504E] max-w-[828px]">
                                    With life being so busy, now is a good time
                                    to be thinking about getting your whole
                                    family’s health in tip-top condition.
                                    Supporting the immune system, through a
                                    combination of approaches, is the best way
                                    to do this. In a 2021 survey, 62%1 of
                                    consumers indicated that having a strong
                                    immune system is their definition of
                                    &apos;healthy&apos;. Put simply, the immune
                                    system is a network of different cells,
                                    tissues, organs, and proteins which are
                                    located throughout the body.
                                </p>
                            </div>

                            {/* <div className="w-[252px] h-[171px] aspect-[16/8]"> */}
                            <img src={vlogimg1} alt="" />
                            {/* </div> */}
                        </div>
                        <div className="w-full h-[2px] bg-[#007AD9] my-[19px]"></div>

                        <div className="flex items-center justify-between gap-[50px]">
                            <div className="">
                                <h2 className="text-[24px] leading-[29px] font-medium max-w-[761px]">
                                    Mastering medical nutrition: 5 future trends
                                    from ESPEN 2023 Future of Nutrition
                                </h2>

                                <div className="flex items-center gap-4 mt-[10px]">
                                    <div className="flex items-center gap-1 text-[#727272]">
                                        <CiCalendar
                                            size={15}
                                            className="mb-1"
                                            strokeWidth={1}
                                        />
                                        <p className="text-[12px] font-normal">
                                            26 Oct, 23
                                        </p>
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        SUPPLEMENTATION
                                    </div>

                                    <div className=" text-[11px] font-normal px-[15px] py-[4px] uppercase rounded-3xl bg-[#ededed] text-[#26394A]">
                                        PLANT PROTEIN
                                    </div>
                                </div>
                                <p className="mt-[9px] text-[15px] leading-[21px] font-normal text-[#50504E] max-w-[828px]">
                                    In the dynamic realm of medical nutrition,
                                    advancements persist without pause. Emerging
                                    insights from the 45th ESPEN Congress on
                                    clinical nutrition and metabolism in
                                    September 2023 indicate forthcoming
                                    developments. Anticipate the ascendance of
                                    plant proteins, omega-3 supplements,
                                    strategies to enhance muscle health through
                                    gut interventions, customized nutritional
                                    products, and the integration of Human Milk
                                    Oligosaccharides. These transformations are
                                    poised to reshape our perspectives on
                                    nutrition and administration.
                                </p>
                            </div>

                            {/* <div className="w-[252px] h-[171px] aspect-[16/8]"> */}
                            <img src={vlogimg2} alt="" />
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                {/* Trending section for mobile */}
                <div className="lg:hidden bg-[#B0B5BE0D] md:px-12 pb-8">
                    <div className="product-hub-logo-div justify-center">
                        <div className="log-1">
                            <img src={slogo} alt="" />
                        </div>
                        <div className="line-x"></div>
                        <p>PRODUCT HUB</p>
                    </div>
                    <p className="text-center">@TrendingShorties</p>
                    <hr className="border border-[#007AD9] mt-2 mb-5" />

                    <Swiper
                        style={{
                            '--swiper-pagination-color': '#000',
                            '--swiper-pagination-bullet-inactive-color': '#000',
                            '--swiper-pagination-bottom': '0px',
                        }}
                        pagination={true}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="flex flex-col gap-2 items-center mb-8">
                                <img
                                    className="h-full w-[80%] mx-auto"
                                    src={vlogimg1}
                                    alt=""
                                />
                                <p className="rbt2">
                                    Mastering medical nutrition: 5 future trends
                                    from ESPEN 2023 Future of Nutrition
                                </p>
                                <div className="ravit">
                                    <p className="rbt3">
                                        <CiCalendar size={15} /> 26 Oct, 23{' '}
                                    </p>
                                    <div className="piyug1">
                                        <p> SUPPLEMENTATION</p>
                                    </div>
                                    <div className="piyug1">
                                        <p> PLANT PROTEIN</p>
                                    </div>
                                </div>
                                <p className="story">
                                    With life being so busy, now is a good time
                                    to be thinking about getting your whole
                                    family’s health in tip-top condition.
                                    Supporting the immune system, through a
                                    combination of approaches, is the best way
                                    to do this. In a 2021 survey, 62%1 of
                                    consumers indicated that having a strong
                                    immune system is their definition of
                                    &apos;healthy&apos;. Put simply, the immune
                                    system is a network of different cells,
                                    tissues, organs, and proteins which are
                                    located throughout the body.
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="flex flex-col gap-2 items-center">
                                <img
                                    className="h-full w-[80%] mx-auto"
                                    src={vlogimg2}
                                    alt=""
                                />
                                <p className="rbt2">
                                    Probiotics for Your Family’s Immunity, How
                                    does it help in pregnancy and overall health
                                </p>
                                <div className="ravit">
                                    <p className="rbt3">
                                        <CiCalendar size={15} /> 26 Oct, 23{' '}
                                    </p>
                                    <div className="piyug1">
                                        {' '}
                                        <p>PROBIOTICS</p>
                                    </div>
                                    <div className="piyug1">
                                        <p>GUT HEALTH</p>
                                    </div>
                                </div>
                                <p className="story">
                                    In the dynamic realm of medical nutrition,
                                    advancements persist without pause. Emerging
                                    insights from the 45th ESPEN Congress on
                                    clinical nutrition and metabolism in
                                    September 2023 indicate forthcoming
                                    developments. Anticipate the ascendance of
                                    plant proteins, omega-3 supplements,
                                    strategies to enhance muscle health through
                                    gut interventions, customized nutritional
                                    products, and the integration of Human Milk
                                    Oligosaccharides. These transformations are
                                    poised to reshape our perspectives on
                                    nutrition and administration.
                                </p>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>

                {/* <div className="my-[88px] max-md:my-[75px] mx-auto">
                    <p className="text-center text-[35px] font-medium leading-[52px] mb-[28px] max-md:text-[25px] max-md:leading-[52px] max-md:mb-[13px] max-md:px-[10px]">
                        Subscribe our Newsletters
                    </p>
                    <p className="mb-[50px] text-center text-[#50504E] text-[15px]  font-normal max-w-[498px] leading-[26px] mx-auto max-md:px-[10px] max-md:mb-[15px]">
                        We have created a new product that will help designers,
                        developers and companies create websites for their
                        startups quickly and easily.
                    </p>
                    <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
                        <input
                            className="border h-[60px] max-md:h-[48px] w-[343px] md:w-[470px] rounded-full ps-8 placeholder:text-[18px] placeholder:font-light max-md:placeholder:text-[15px]"
                            type="text"
                            name=""
                            id=""
                            placeholder="Your email"
                        />
                        <button className="bg-black h-[60px] max-md:h-[48px] w-fit px-12 py-4 text-white rounded-full max-md:text-[15px]">
                            Sign Up
                        </button>
                    </div>
                    <p className="text-[16px] leading-[26px] font-normal text-center text-[#50504E] mt-[63px] max-md:text-[10px] max-md:mt-[20px]">
                        By subscribing, you agree to the Terms of Service
                    </p>
                </div> */}
                <Subscribe />
                <Footer />
            </div>
        </div>
    );
};

export default Home;
