import React from 'react';
import { IoSearch } from 'react-icons/io5';
import { RiMessage2Line } from 'react-icons/ri';
import '../Styles/Navbar.css';
import '../Styles/fonts.css';
import Tavbar from './Tavbar';
// import AnimatedCursor from "react-animated-cursor";
const Navbar = () => {
    const isMobile = window.innerWidth <= 768;
    return (
        <div className="NavBoxmaintm">
            {/* {!isMobile && <AnimatedCursor />} */}

            <div className="NavBox1main">
                <p className="NText1m">Material Safety Data Sheets</p>
                <div className="msearch">
                    <p className="NText2m">Medical Nutrition Disclaimer</p>
                    <div className="cntus">
                        <RiMessage2Line
                            style={{
                                color: '#C6C5C5',
                                width: '16px',
                                height: '16px',
                            }}
                        />
                        <p className="NText3m">Contact us</p>
                    </div>
                    <div className="srchicon">
                        <IoSearch
                            style={{
                                color: '#C6C5C5',
                                width: '16px',
                                height: '16px',
                            }}
                        />
                        <p className="NText4m">Search</p>
                    </div>
                </div>
            </div>
            <Tavbar />
            {/* <div className="NavBox2m">
          <img src={logo} alt="" className="iml" />
          <div className="mainMenu">
            <Link
              to="/company"
              className="linkmenu"
              style={{ textDecoration: "none" }}
            >
              <p>Company</p>
            </Link>
            <Link
              to="/learningcentre"
              className="linkmenu"
              style={{ textDecoration: "none" }}
            >
              <p className="linkmenu">Learning Centre</p>
            </Link>
            <Link
              to="/product"
              className="linkmenu"
              style={{ textDecoration: "none" }}
            >
              <p className="linkmenu">Products</p>
            </Link>

            <Link to="/ingredients" style={{ textDecoration: "none" }}>
              <p className="linkmenu">Our Ingredients</p>
            </Link>
            <Link to="" style={{ textDecoration: "none" }}>
              <p className="linkmenu">Careers</p>
            </Link>
            <Link to="/contactus" style={{ textDecoration: "none" }}>
              <p className="linkmenu">Contact Us</p>
            </Link>
          </div>
          <HiMenuAlt3  className='burgline'/>
        </div> */}
        </div>
    );
};

export default Navbar;
