import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import fruit1 from '../Assests/1.png';
import fruit2 from '../Assests/2.png';
import fruit3 from '../Assests/3.png';
import fruit4 from '../Assests/4.png';
import backbutton from '../Assests/Vector2.svg';
import backbutton1 from '../Assests/VectorN.svg';
import Banner1 from '../Assests/boxbanner.jpg';
import CalenderIcon from '../Assests/calendar.svg';
import search from '../Assests/search.svg';
import BottomNavigation from '../Components/BottomNavigation';
import '../Styles/ProductCompodium.css';
const ProductCompodium = () => {
    const navigate = useNavigate(); // Use useNavigate

    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page.
    };
    return (
        <div id="Container">
            <div className="Tnavbar2">
                <img
                    src={backbutton1}
                    alt=""
                    className="vectorN2"
                    onClick={handleGoBack}
                />

                <div className="pBox1">
                    <p className="hub02">PRODUCT OFFERINGS</p>
                    <p className="hub03">Navigate by interest</p>
                </div>
                <img
                    src={search}
                    alt=""
                    className="vectorN2"
                    // onClick={handleGoBack}
                />
            </div>
            <div id="navbar1">
                <img
                    src={backbutton}
                    alt=""
                    className="vector"
                    onClick={handleGoBack}
                />
                <div className="navTextbox">
                    <p className="textHello1">Let’s Explore...</p>
                    <p className="navtext1">
                        Are you looking for a ready product or private label
                        product.{' '}
                    </p>
                </div>
            </div>
            {/* <div className="boxA1">
        <div className="boxa1">
          <p>
            <span
              style={{
                color: "#222222",
                fontSize: 45,
                fontFamily: "NewHero",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              The right selection of <br />
            </span>
            <span
              style={{
                color: "#20BED7",
                fontSize: 45,
                fontFamily: "NewHero",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              formula
            </span>
            <span
              style={{
                color: "#222222",
                fontSize: 45,
                fontFamily: "NewHero",
                fontWeight: "500",
                wordWrap: "break-word",
              }}
            >
              {" "}
              is the key
            </span>
            <span
              style={{
                color: "#A083BC",
                fontSize: 45,
                fontFamily: "NewHero",
                fontWeight: "600",
                wordWrap: "break-word",
              }}
            >
              {" "}
            </span>
          </p>
        </div>
        <div className="boxa2">
          <p>
            Book your free online product consultation.
            <br />
            <span
              style={{
                color: "black",
                fontSize: 13,
                fontWeight: "400",
                wordWrap: "break-word",
                fontFamily: "NewHero, sans-serif",
              }}
            >
              Get one step closer to your dream brand.
            </span>
          </p>
          <div id="buttonBox">
            <img src={CalenderIcon} alt="calender" className="iconx" />
            <p>Schedule a call</p>
          </div>
        </div>
      </div> */}
            <div className="topBox1x">
                <div className="pribox11x">
                    <p
                        style={{ width: '100%', height: '100%' }}
                        className="para1x"
                    >
                        The right selection of <br />
                        <span className="nutTxt1x">forrmula</span> is the key
                    </p>
                </div>
                <div className="boxcalender1x">
                    <div className="pribox21x">
                        <p
                            style={{
                                color: 'black',
                                fontSize: 14,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            Book your free online product consultation. <br />{' '}
                            Get one step closer to your dream brand.
                        </p>
                    </div>
                    <div id="pributtonBox10x">
                        <img
                            src={CalenderIcon}
                            alt="calender"
                            className="priiconx10x"
                        />
                        <p>Schedule a call</p>
                    </div>
                </div>
            </div>
            {/* <div className="wraper1">
        <Link to='/productbrand' className="blink">
        <div className="wraperItem1">
          <div className="imgbox1">
            <img src={Banner1} alt="" className="banner1" />
          </div>
          <div className="TextBox1">
            <p className="textp11">Branded Products</p>
            <p className="textp21">
              Innovative brands that stands out for a better tomorrow
            </p>
            <p className="textp31">Explore More</p>
          </div>
        </div>
        </Link>
        <Link to='/privatelabel' className="blink">
        <div className="wraperItem1">
          <div className="imgbox1">
            <img src={Banner1} alt="" className="banner1" />
          </div>
          <div className="TextBox1">
            <p className="textp11">Private Label Products</p>
            <p className="textp21">
              Wide range of offerings for your next generation brand.
            </p>
            <p className="textp31">Explore More</p>
          </div>
        </div>
        </Link>
        <Link to='/home' className="blink">
        <div className="wraperItem1">
          <div className="imgbox1">
            <img src={Banner1} alt="" className="banner1" />
          </div>
          <div className="TextBox1">
            <p className="textp11">Animal Nutrition</p>
            <p className="textp21">
              Expert animal nutrition for your loved ones
            </p>
            <p className="textp31">Explore More</p>
          </div>
        </div>
        </Link>
      </div> */}

            <div className="card-container2">
                <div className="card2">
                    <Link to="/productbrand">
                        <img src={Banner1} alt="" />
                    </Link>
                    <div className="card-content2">
                        <p className="title2">Branded Products</p>
                        <p className="descrip2">
                            Innovative brands that stands out for a better
                            tomorrow
                        </p>
                        <p className="explr2">Explore</p>
                    </div>
                </div>
                <div className="card2">
                    <Link to="/privatelabel">
                        <img src={Banner1} alt="" />
                    </Link>
                    <div className="card-content2">
                        <p className="title2">Private Label Products</p>
                        <p className="descrip2">
                            Wide range of offerings for your next generation
                            brand.
                        </p>
                        <p className="explr2">Explore</p>
                    </div>
                </div>
                <div className="card2">
                    <Link to="/">
                        <img src={Banner1} alt="" />
                    </Link>
                    <div className="card-content2">
                        <p className="title2">Animal Nutrition</p>
                        <p className="descrip2">
                            Expert animal nutrition for your loved ones
                        </p>
                        <p className="explr2">Explore</p>
                    </div>
                </div>
            </div>

            <p className="gradientBox">
                Know your <span className="ingText">ingredient</span>
            </p>
            <div className="fruitsbox">
                <div className="iconbox">
                    <img src={fruit2} alt="" className="fruitsimage" />
                    <p>Apple</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit1} alt="" className="fruitsimage" />
                    <p>Acerola</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit3} alt="" className="fruitsimage" />
                    <p>Acai</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit4} alt="" className="fruitsimage" />
                    <p>Aswaghandha</p>
                </div>
                <div className="iconbox">
                    <img src={fruit2} alt="" className="fruitsimage" />
                    <p>Apple</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit1} alt="" className="fruitsimage" />
                    <p>Acerola</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit3} alt="" className="fruitsimage" />
                    <p>Acai</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit4} alt="" className="fruitsimage" />
                    <p>Aswaghandha</p>
                </div>
                <div className="iconbox">
                    <img src={fruit2} alt="" className="fruitsimage" />
                    <p>Apple</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit1} alt="" className="fruitsimage" />
                    <p>Acerola</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit3} alt="" className="fruitsimage" />
                    <p>Acai</p>
                </div>
                <div className="iconbox">
                    {' '}
                    <img src={fruit4} alt="" className="fruitsimage" />
                    <p>Aswaghandha</p>
                </div>
            </div>
            <BottomNavigation />
        </div>
    );
};

export default ProductCompodium;
