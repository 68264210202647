import React from 'react';
import Navbar from '../Contents/Navbar';
import Subscribe from '../Components/shared/Subscribe';
import Footer from '../Contents/Footer';
import { privateLabel } from '../utils/assets';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa6';

const PrivateLabelProduct = () => {
    const powder = [
        {
            label: 'FLAVOURING OPTIONS',
            items: [
                'NATURAL',
                'ARTIFICIAL',
                'NATURAL + ARTIFICIAL',
                'UNFLAVOURED',
            ],
        },
        {
            label: 'SWEETENER OPTIONS',
            items: [
                'NATURAL',
                'ARTIFICIAL',
                'NATURAL + ARTIFICIAL',
                'UNSWEETENED',
            ],
        },
        {
            label: 'COLOUR OPTIONS',
            items: ['NATURAL', 'ARTIFICIAL', 'NONE'],
        },
        {
            label: 'FLAVOURING OPTIONS',
            items: ['STOCK', 'CUSTOM'],
        },
    ];

    const humanData = [
        {
            img: privateLabel.privateImg[0],
            title: 'Sports Nutrition',
            paragraph:
                'Pre-Workout, Intra-workout, Post-Workout, Muscle Building Proteins, BCAA’s, EAA’s, Endurance Nutrition, Hydration, Recovery Aids, Weight Management, Joint Support, Specialized Sports Supplements',
            url: '',
            isUrl: true,
        },
        {
            img: privateLabel.privateImg[1],
            title: 'Dietary Supplements',
            paragraph:
                'Vitamins, Minerals, Herbal Supplements, Amino Acids, Daily Proteins, Omega-3 Fatty Acids, Probiotics, Fiber Supplements , Joint Health Supplements, Energy Supplements',
            url: '',
            isUrl: true,
        },
        {
            img: privateLabel.privateImg[2],
            title: 'Targeted Nutraceuticals',
            paragraph:
                'Herbal Extracts, Fatty acids, Enzymes, Probiotics, Prebiotics, Antioxidants, , Adaptogens, Ophthalmic Supplements,  Memory & Cognitive, Liver Support, Immune Support, Body Detox, Collagen',
            url: '',
            isUrl: true,
        },
        {
            img: privateLabel.privateImg[3],
            title: 'Clinical Nutrition',
            paragraph:
                'Oncology Nutrition, Wound Management, Critical Care Nutrition, Paediatric Nutrition, Geriatric Nutrition, Diabetes Nutrition, Cardiovascular Nutrition, Enteral Nutrition. ',
            url: '',
            isUrl: true,
        },
    ];

    const animalData = [
        {
            img: privateLabel.privateImg[4],
            title: 'Pet Nutrition',
            paragraph:
                'Immunity & Allergy, Overall Health, Calming, Mobility, Probiotics, Multivitamin, Blood Sugar Support, Cancer Care, Cushing Adrenal Support, Hearth Health,Seniors for dogs and cats. ',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[5],
            title: 'Equine Nutrition',
            paragraph:
                'Calming, Dewormer, Digestion, Electrolytes, Endurance, Hoof Health, Insect Control, Bone & Joint, Muscle Health, Metabolism Booster, Respiratory, Weight Control, Ulcer Management.',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[6],
            title: 'Livestock Nutrition',
            paragraph:
                'Commercial Poultry, Dairy & Cattle, Swine, Goats and sheep Health - Electrolytes, Probiotics, Energy Booster, Blood Builder, Muscle Health, Feed Additives, Laxatives, Supplements and more.',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[7],
            title: 'Bird Nutrition',
            paragraph:
                'Vitamin and Mineral Supplements, Power & Vigor, Fertility Blends, Proteins, Overall Nutrition, Digestive Enzymes, Iodine, Fatty Acids, Feather Health, Calcium Supplements, Trace Minerals ',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[8],
            title: 'Exotic Animals',
            paragraph:
                'Elephant Grain-free hay enhancer, Vegan Collagen Builder, Vitamin & Minerals, Reptile Calcium, Reptile Electrolyte Soaks, Exotic Reptile Vitamin & Minerals, Amino Acids and Proteins. ',
            url: '',
            isUrl: false,
        },
    ];

    const foodsData = [
        {
            img: privateLabel.privateImg[9],
            title: 'Instant Beverages',
            paragraph:
                'Coffee, Tea, Hot Cocoa, Green Tea, Fruit Drink Mix, Organic Juice Premix, Flavoured Lemonade, Iced Tea Premix, Milkshake Premix, Smoothie Premix, Liquid Breakfast Premix and more . ',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[10],
            title: 'Confectionary Premix',
            paragraph:
                'Soft Serve Premix, Custard Powder , Jelly Powder, Flavour Enhancers, Egg Replacers, Flavoured Sugar, Pancake Mix, Natural & Artificial Sweeteners, Jaggery Powder, Flavoured Salts',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[11],
            title: 'Cocktail Rimming Salt',
            paragraph: 'Flavoured Rimming Salts & Sugars.',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[12],
            title: 'Coffee Creamers',
            paragraph:
                'Original Coffee Creamer, Flavoured Coffee Creamers Keto Coffee Creamers, Collagen Coffee Creamers',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[13],
            title: 'Luxury Superfoods',
            paragraph:
                'Flavoured Superfood Beverages, Super Reds, Super Greens, Açai Drink Mix, Elderberry Drink Mix, Goji Berry Drink Mix, Coconut Water Premix, Premium Matcha',
            url: '',
            isUrl: false,
        },
        {
            img: privateLabel.privateImg[14],
            title: 'Organic Fruit Powders',
            paragraph:
                'Freeze Dried Organic Fruit Powders for smoothies and food applications',
            url: '',
            isUrl: false,
        },
    ];

    return (
        <div className="pt-[114px]">
            <Navbar />
            <div className="custom__container">
                <div className="grid grid-cols-1 lg:grid-cols-2 ">
                    <div className="flex gap-1">
                        <div className="max-md:max-w-[144px] overflow-hidden max-md:rounded-md">
                            <img
                                src={privateLabel.banner[0]}
                                alt=""
                                className="w-full max-md:w-[144px] object-cover"
                            />
                        </div>

                        <div className="bg-gradient-to-r from-[#20BED7] to-[#4DA0D6] px-[20px] py-[50px] max-w-[400px] max-md:w-[calc(100%-144px)] max-md:pt-[17px] max-md:rounded-md">
                            <h2 className="text-[45px] font-normal leading-[62px] mb-[30px] text-white max-md:text-[22px] max-md:leading-[30px]">
                                Start you manufacturing project <b>today !</b>
                            </h2>

                            <div className="rounded-[100px] w-fit cursor-pointer py-[8px] px-[58px] text-[18px] leading-[26px] font-medium dmsans text-white border-white border-2 max-md:px-[20px] max-md:text-[15px] max-md:border max-md:py-1">
                                Call Us Now
                            </div>
                        </div>
                    </div>

                    <div className="w-full overflow-hidden max-lg:hidden">
                        <img
                            src={privateLabel.banner[1]}
                            alt=""
                            className="w-full object-cover"
                        />
                    </div>
                </div>
            </div>
            <div className="custom__container max-md:!px-0">
                <div className="py-[70px] max-md:py-[30px] grid grid-cols-1 md:grid-cols-2 gap-[41px] max-md:gap-[14px]">
                    <div className="max-md:px-[20px]">
                        <h3 className="text-[30px] leading-[46px] font-medium text-[#0F172E] max-md:text-center max-md:text-[16px] max-md:leading-[21px]">
                            Powder Manufacturing
                        </h3>
                        <p className="text-[15px] leading-[21px] font-medium  text-[#0F172E] max-md:text-center max-md:text-[8px]">
                            Flavour and freshness you can count on
                        </p>

                        <div className="my-[26px] grid grid-cols-3 gap-[0px]">
                            <div className="pr-[10px] py-[7px] border-r border-r-black mr-[20px]">
                                <p className="text-[15px] font-semibold max-md:text-[8px] max-md:text-center">
                                    Flavours & Formula
                                </p>
                                <p className="text-[25px] font-semibold max-md:text-[12px] max-md:text-center">
                                    Infinite
                                </p>
                            </div>
                            <div className="pr-[10px] py-[7px] border-r border-r-black mr-[20px]">
                                <p className="text-[15px] font-semibold max-md:text-[8px] max-md:text-center">
                                    Fast Turnaround
                                </p>
                                <p className="text-[25px] font-semibold max-md:text-[12px] max-md:text-center">
                                    5 Weeks
                                </p>
                            </div>
                            <div className="pr-[10px] py-[7px]">
                                <p className="text-[15px] font-semibold max-md:text-[8px] max-md:text-center">
                                    Minimum Order Quantity
                                </p>
                                <p className="text-[25px] font-semibold max-md:text-[12px] max-md:text-center">
                                    Custom
                                </p>
                            </div>
                        </div>

                        <p className="text-[15px] leading-[21px] mb-4 text-[#0f172e] font-normal max-md:text-center">
                            Unlock the full potential of your supplement formula
                            with our tailored flavor and sweetener selection
                            process! Whether you prefer natural or artificial
                            additives, our experts are ready to craft the
                            perfect blend to suit your vision. Opt for a custom
                            flavor and watch as our skilled team meticulously
                            composes a taste profile tailored to your formula,
                            complete with specialized taste modifiers.
                        </p>

                        <p className="text-[15px] leading-[21px] mb-4 text-[#0f172e] font-normal max-md:text-center">
                            But that&apos;s not all – for brands seeking a purer
                            product, simply exclude additives altogether. And
                            why stop there? Elevate the visual appeal of your
                            powder supplement with our range of natural and
                            artificial coloring options.
                        </p>

                        <p className="text-[15px] leading-[21px] mb-4 text-[#0f172e] font-normal max-md:text-center">
                            And while we&apos;re busy enhancing the flavor of
                            your powdered supplement, rest assured that
                            we&apos;ll keep you in the loop every step of the
                            way.
                        </p>
                    </div>

                    <div className="overflow-hidden">
                        <img
                            src={privateLabel.cardimg[0]}
                            alt=""
                            className="w-full object-cover"
                        />
                    </div>
                </div>
            </div>
            <div className="custom__container">
                <h4 className="text-center text-[15px] leading-[21px] font-medium max-md:text-[10px]">
                    POWDER FLAVOURING & CUSTOMIZATION OPTIONS
                </h4>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mt-[30px]">
                    {powder?.map((item) => (
                        <div className="" key={item.label}>
                            <div className="w-fit mx-auto">
                                <p className="text-[15px] leading-[21px] font-bold underline text-center mb-[20px]">
                                    {item.label}
                                </p>

                                {item.items.map((it) => (
                                    <div
                                        className="text-center text-[14px] leading-[21px] font-medium py-[13px] border-b border-b-black last:border-none"
                                        key={it}
                                    >
                                        {it}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <p className="text-[25px] max-md:text-[15px] max-md:leading-[21px] mt-[58px] leading-[34px] font-medium text-center text-[#0071d9]">
                    Kingenic’s manufacturing services go beyond what you see on
                    this page. <br /> Call us at 1-848-342-1471 to discuss what
                    you have in mind for your premium powder product.
                </p>

                <Link to="">
                    <div className="w-fit mx-auto px-[37px] py-[8px] rounded-[100px] border border-black text-[18px] font-medium mt-[36px] cursor-pointer text-black">
                        Contact Us
                    </div>
                </Link>
            </div>
            <div className="custom__container max-md:!px-0">
                <div className="py-[70px] max-md:py-[30px] grid grid-cols-1 md:grid-cols-2 gap-[41px] max-md:gap-[14px]">
                    <div className="max-md:px-[20px]">
                        <h3 className="text-[30px] leading-[46px] font-medium text-[#0F172E] max-md:text-center max-md:text-[16px] max-md:leading-[21px]">
                            Capsule Manufacturing
                        </h3>
                        <p className="text-[15px] leading-[21px] font-medium  text-[#0F172E] max-md:text-center max-md:text-[8px]">
                            100% Customised Solutions
                        </p>

                        <div className="my-[26px] grid grid-cols-3 gap-[0px]">
                            <div className="pr-[10px] py-[7px] border-r border-r-black mr-[20px]">
                                <p className="text-[15px] font-semibold max-md:text-[8px] max-md:text-center">
                                    Formula Options
                                </p>
                                <p className="text-[25px] font-semibold max-md:text-[12px] max-md:text-center">
                                    Infinite
                                </p>
                            </div>
                            <div className="pr-[10px] py-[7px] border-r border-r-black mr-[20px]">
                                <p className="text-[15px] font-semibold max-md:text-[8px] max-md:text-center">
                                    Fast Turnaround
                                </p>
                                <p className="text-[25px] font-semibold max-md:text-[12px] max-md:text-center">
                                    5 Weeks
                                </p>
                            </div>
                            <div className="pr-[10px] py-[7px]">
                                <p className="text-[15px] font-semibold max-md:text-[8px] max-md:text-center">
                                    Minimum Order Quantity
                                </p>
                                <p className="text-[25px] font-semibold max-md:text-[12px] max-md:text-center">
                                    Custom
                                </p>
                            </div>
                        </div>

                        <p className="text-[15px] leading-[21px] mb-4 text-[#0f172e] font-normal ">
                            Embark on your journey to creating the perfect
                            supplement with our comprehensive capsule formulas
                            or tailor-made options. Once you&apos;ve made your
                            selection or crafted a custom formula on our
                            user-friendly platform, our dedicated product
                            specialist will be at your service to streamline
                            your experience.
                        </p>

                        <p className="text-[15px] leading-[21px] mb-4 text-[#0f172e] font-normal ">
                            From sizes to colors, textures, and shapes, our
                            manufacturing capabilities know no bounds. Your
                            specialist will delve into the specifics, ensuring
                            you grasp the full extent of what we can offer for
                            your supplement capsules.
                        </p>

                        <p className="text-[15px] leading-[21px] mb-4 text-[#0f172e] font-normal ">
                            To explore the endless possibilities, take a glance
                            at our capsule manufacturing options chart. While it
                            showcases popular combinations, rest assured, your
                            creativity knows no bounds with us.
                        </p>
                    </div>

                    <div className="overflow-hidden">
                        <img
                            src={privateLabel.cardimg[1]}
                            alt=""
                            className="w-full object-cover"
                        />
                    </div>
                </div>
            </div>

            <div className="custom__container max-md:!px-0">
                <h2 className="text-[15px] leading-[21px] font-medium uppercase max-md:text-center max-md:text-[8px]">
                    PRODUCT CATEGORIES
                </h2>

                <h3 className="text-[30px] leading-[42px] font-medium max-md:text-center max-md:text-[15px] max-md:leading-[21px]">
                    HUMAN NUTRITION
                </h3>

                <div className="mt-[33px]">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px]">
                        {humanData?.map((item) => (
                            <Card
                                key={item.title}
                                title={item.title}
                                paragraph={item.paragraph}
                                url={item.url}
                                img={item.img}
                                isUrl={item.isUrl}
                            />
                        ))}
                    </div>
                </div>
            </div>

            <div className="custom__container max-md:!px-0">
                <div className="flex max-md:flex-col max-md: justify-center items-center gap-[20px] max-md:gap-[6px] mt-[60px]">
                    <h3 className="text-[30px] max-md:text-[15px] max-md:leading-[21px] leading-[42px] font-medium">
                        ANIMAL NUTRITION
                    </h3>

                    <Link
                        to={''}
                        className="flex items-center gap-[13px] cursor-pointer"
                    >
                        <div className="w-[21px] aspect-square rounded-full flex items-center justify-center bg-black max-md:hidden">
                            <FaChevronRight
                                className="text-white "
                                size={12}
                                strokeWidth={5}
                            />
                        </div>

                        <p className="text-[10px] font-medium leading-[14px] text-black max-md:text-[#007AD9]">
                            Download Brochure
                        </p>
                    </Link>
                </div>

                <div className="mt-[22px]">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px]">
                        {animalData?.map((item) => (
                            <Card
                                key={item.title}
                                title={item.title}
                                paragraph={item.paragraph}
                                url={item.url}
                                isUrl={item.isUrl}
                                img={item.img}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="custom__container max-md:!px-0">
                <div className="flex max-md:flex-col max-md: justify-center items-center gap-[20px] max-md:gap-[6px] mt-[60px]">
                    <h3 className="text-[30px] max-md:text-[15px] max-md:leading-[21px] leading-[42px] font-medium">
                        FOODSERVICE & BULK SUPPLY
                    </h3>

                    <Link
                        to={''}
                        className="flex items-center gap-[13px] cursor-pointer"
                    >
                        <div className="w-[21px] aspect-square rounded-full flex items-center justify-center bg-black max-md:hidden">
                            <FaChevronRight
                                className="text-white "
                                size={12}
                                strokeWidth={5}
                            />
                        </div>

                        <p className="text-[10px] font-medium leading-[14px] text-black max-md:text-[#007AD9]">
                            Download Brochure
                        </p>
                    </Link>
                </div>

                <div className="mt-[22px]">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[24px]">
                        {foodsData?.map((item) => (
                            <Card
                                key={item.title}
                                title={item.title}
                                paragraph={item.paragraph}
                                url={item.url}
                                isUrl={item.isUrl}
                                img={item.img}
                            />
                        ))}
                    </div>
                </div>
            </div>

            {/* Form */}
            <div className="custom__container">
                <div className="max-w-[628px] mx-auto py-[60px]">
                    <p className="text-center text-[24px] leading-[33px] font-normal">
                        Start you brand in one click !
                    </p>

                    <form action="">
                        <input
                            type="text"
                            name="subject"
                            className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                            placeholder="How Can We Assist?"
                        />

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-[26px]">
                            <input
                                type="text"
                                name="first_name"
                                className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                placeholder="First Name"
                            />
                            <input
                                type="text"
                                name="last_name"
                                className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                placeholder="Last Name"
                            />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-[26px]">
                            <input
                                type="email"
                                name="email"
                                className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                placeholder="Email"
                            />
                            <input
                                type="text"
                                name="mobile"
                                className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                placeholder="Mobile"
                            />
                        </div>

                        <textarea
                            name="message"
                            id=""
                            className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                            cols="30"
                            rows="5"
                            placeholder="Tell us about the product the product you are interested in. "
                        ></textarea>

                        <label
                            htmlFor="ckhs"
                            className="flex items-center justify-center"
                        >
                            <input
                                type="checkbox"
                                name="agrree"
                                id="ckhs"
                                className="!border !border-gray-500 mr-2 !aspect-square"
                            />

                            <p className="text-[12px] leading-[16px]">
                                Yes, I would like Kingenic to inform me about
                                special offers and new products via email.
                            </p>
                        </label>

                        <div className="flex items-center justify-center mt-[14px] ">
                            <button
                                type="submit"
                                className="bg-[#151515] rounded-md text-[10px] px-[19px] py-[7px] uppercase font-medium text-white"
                            >
                                SUBMIT
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default PrivateLabelProduct;

const Card = ({ img, title, paragraph, url, isUrl }) => {
    return (
        <div className="flex gap-[17px] bg-[#F2F1F1] p-[23px] rounded-[5px]">
            <div className="w-[150px] h-[150px] md:w-[172px] md:h-[172px] aspect-square">
                <img src={img} alt="" className="w-full h-full object-cover" />
            </div>
            <div className="overflow-hidden ">
                <h2 className="text-[25px] max-md:text-[15px] max-md:leading-[21px] leading-[35px] font-medium">
                    {title}
                </h2>

                <div className="">
                    <p className="text-[15px] max-md:text-[9px] max-md:leading-[12px] my-[12px] leading-[21px] font-normal">
                        {paragraph}
                    </p>

                    {isUrl && (
                        <Link
                            to={url}
                            className="flex items-center gap-[13px] cursor-pointer"
                        >
                            <div className="w-[21px] max-md:w-[16px] aspect-square rounded-full flex items-center justify-center bg-black">
                                <FaChevronRight
                                    className="text-white max-md:hidden"
                                    size={12}
                                    strokeWidth={5}
                                />

                                <FaChevronRight
                                    className="text-white md:hidden"
                                    size={8}
                                    strokeWidth={2}
                                />
                            </div>

                            <p className="text-[10px] max-md:text-[6px] font-medium leading-[14px] text-black">
                                Explore Product List
                            </p>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
