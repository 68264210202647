import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaStar } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import backbutton from '../Assests/Vector2.svg';
import backbutton1 from '../Assests/VectorN.svg';
import search from '../Assests/search.svg';
import BottomNavigation from '../Components/BottomNavigation';
import '../Styles/PreSurgery.css';
const PreSurgery = () => {
    const navigate = useNavigate(); // Use useNavigate
    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page.
    };
    return (
        <div id="PresurgeryContainer">
            <div className="Tnavbar5">
                <img
                    src={backbutton1}
                    alt=""
                    className="vectorN5"
                    onClick={handleGoBack}
                />

                <div className="pBox5">
                    <p className="hubz3">Wûne Home</p>
                    <p className="hubz4">Navigate by interest</p>
                </div>
                <img
                    src={search}
                    alt=""
                    className="vectorN5"
                    // onClick={handleGoBack}
                />
            </div>
            <div id="preNavbar">
                <img
                    src={backbutton}
                    alt=""
                    className="prvector"
                    onClick={handleGoBack}
                />
                <div className="prnavTextbox">
                    <p className="prtextHello1">WÛNE</p>
                    <p className="prnavtext1">
                        Advanced Wound Management Brand
                    </p>
                </div>
            </div>
            <div className="prebox1">
                <div className="preboxc1"></div>
                <div className="preboxc2">
                    <p className="preT1">Pre-Surgery</p>
                    <div className="icondivo">
                        <FaStar className="star-icon" />
                        <p
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: 'NewHero, sans-serif',
                                color: '#606060',
                            }}
                        >
                            Brand : Wûne
                        </p>
                    </div>
                    <p className="LongText">
                        Aspen is as close as one can get to a storybook alpine
                        town in America. The choose- <br /> your-own-adventure
                        possibilities—skiing, hiking, dining shopping and <br />
                        Aspen is as close as one can get to a storybook alpine
                        town in America. The choose- <br /> your-own-adventure
                        possibilities—skiing, hiking, dining shopping and{' '}
                    </p>
                    <DropdownItem
                        label="Key Benefits"
                        details={
                            <>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </>
                        }
                    />
                    <DropdownItem
                        label="Nutrition Facts"
                        details={
                            <>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </>
                        }
                    />
                    <DropdownItem
                        label="Ingredients"
                        details={
                            <>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </>
                        }
                    />
                    <DropdownItem
                        label="Suitable Diets"
                        details={
                            <>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua.
                                    Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip
                                    ex ea commodo consequat. Duis aute irure
                                    dolor in reprehenderit in voluptate velit
                                    esse cillum dolore eu fugiat nulla pariatur.
                                    Excepteur sint occaecat cupidatat non
                                    proident, sunt in culpa qui officia deserunt
                                    mollit anim id est laborum.
                                </p>
                            </>
                        }
                    />
                </div>
            </div>
            <p className="pox">
                Explore other <span className="prdtsc">products</span>.
            </p>
            <div className="cardContainer1">
                <div className="CardBox1">
                    <Link to="/presurgery" className="LinkCrd1">
                        <div className="ImageCard1"></div>
                    </Link>
                    <p className="Cp11">Pre-Surgery</p>
                    <p className="Cp21">Pre-Operative Nutrition</p>
                    <p className="Cp31">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink1">
                        <div className="buttondiv1">
                            <p className="Cp41">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox1">
                    <Link to="/presurgery" className="LinkCrd1">
                        <div className="ImageCard1"></div>
                    </Link>

                    <p className="Cp11">Pre-Surgery</p>
                    <p className="Cp21">Pre-Operative Nutrition</p>
                    <p className="Cp31">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink1">
                        <div className="buttondiv1">
                            <p className="Cp41">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox1">
                    <Link to="/presurgery" className="LinkCrd1">
                        <div className="ImageCard1"></div>
                    </Link>
                    <p className="Cp11">Pre-Surgery</p>
                    <p className="Cp21">Pre-Operative Nutrition</p>
                    <p className="Cp31">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink1">
                        <div className="buttondiv1">
                            <p className="Cp41">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox1">
                    {' '}
                    <Link to="/presurgery" className="LinkCrd1">
                        <div className="ImageCard1"></div>
                    </Link>
                    <p className="Cp11">Pre-Surgery</p>
                    <p className="Cp21">Pre-Operative Nutrition</p>
                    <p className="Cp31">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink1">
                        <div className="buttondiv1">
                            <p className="Cp41">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox1">
                    <Link to="/presurgery" className="LinkCrd1">
                        <div className="ImageCard1"></div>
                    </Link>
                    <p className="Cp11">Pre-Surgery</p>
                    <p className="Cp21">Pre-Operative Nutrition</p>
                    <p className="Cp31">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink1">
                        <div className="buttondiv1">
                            <p className="Cp41">Learn More</p>
                        </div>
                    </Link>
                </div>
            </div>
            <BottomNavigation />
        </div>
    );
};

export default PreSurgery;

function DropdownItem(props) {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const dropdownStyle = {
        fontFamily: 'NewHero , sans-serif',
        borderTop: '0.5px solid #A0A0A0',
        padding: '5px',
        // margin: '10px',
        width: '100%',
    };
    const labelStyle = {
        fontSize: '11px',
        fontWeight: '400',
        fontFamily: 'NewHero , sans-serif',
    };
    const toggleStyle = {
        cursor: 'pointer',
        fontSize: '10px',
        float: 'right',
        transition: 'transform 1s',
    };

    if (showDetails) {
        toggleStyle.transform = 'rotate(180deg)';
    }

    const detailsStyle = {
        // backgroundColor: '#f8f8f8',
        // border: '1px solid #ddd',
        background: '#FFFDF8',
        padding: '10px',
        display: showDetails ? 'block' : 'none',
        fontSize: '11px',
    };

    return (
        <div style={dropdownStyle}>
            <p>
                <span style={labelStyle}>{props.label}</span>
                <span style={toggleStyle} onClick={toggleDetails}>
                    {showDetails ? <FaChevronUp /> : <FaChevronDown />}
                </span>
            </p>
            <div style={detailsStyle}>
                {/* Display details passed as a prop for the current option */}
                {props.details}
            </div>
        </div>
    );
}
