import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backbutton from '../Assests/Vector2.svg';
import backbutton1 from '../Assests/VectorN.svg';
import energy from '../Assests/beach.png';
import search from '../Assests/search.svg';
import BottomNavigation from '../Components/BottomNavigation';
import '../Styles/ProductPage.css';
const ProductPage = () => {
    const navigate = useNavigate(); // Use useNavigate

    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page.
    };
    return (
        <div id="productContainer">
            <div className="Tnavbar4">
                <img
                    src={backbutton1}
                    alt=""
                    className="vectorN4"
                    onClick={handleGoBack}
                />

                <div className="pBox4">
                    <p className="huby3">Wûne Home</p>
                    <p className="huby4">Navigate by interest</p>
                </div>
                <img
                    src={search}
                    alt=""
                    className="vectorN4"
                    // onClick={handleGoBack}
                />
            </div>
            <div id="productNavbar">
                <img
                    src={backbutton}
                    alt=""
                    className="pvector"
                    onClick={handleGoBack}
                />
                <div className="pnavTextbox">
                    <p className="ptextHello1">WÛNE</p>
                    <p className="pnavtext1">Advanced Wound Management Brand</p>
                </div>
            </div>
            <div className="imageBoxp">
                <div className="div1">
                    <img src={energy} alt="" className="img1box" />
                </div>
            </div>
            <p className="proText">
                Select the <span className="prdText">product</span> of your
                choice.
            </p>

            <div className="cardContainer">
                <div className="CardBox">
                    <Link to="/presurgery" className="LinkCrd">
                        <div className="ImageCard"></div>
                    </Link>
                    <p className="Cp1">Pre-Surgery</p>
                    <p className="Cp2">Pre-Operative Nutrition</p>
                    <p className="Cp3">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink">
                        <div className="buttondiv">
                            <p className="Cp4">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox">
                    <Link to="/presurgery" className="LinkCrd">
                        <div className="ImageCard"></div>
                    </Link>

                    <p className="Cp1">Pre-Surgery</p>
                    <p className="Cp2">Pre-Operative Nutrition</p>
                    <p className="Cp3">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink">
                        <div className="buttondiv">
                            <p className="Cp4">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox">
                    <Link to="/presurgery" className="LinkCrd">
                        <div className="ImageCard"></div>
                    </Link>
                    <p className="Cp1">Pre-Surgery</p>
                    <p className="Cp2">Pre-Operative Nutrition</p>
                    <p className="Cp3">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink">
                        <div className="buttondiv">
                            <p className="Cp4">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox">
                    {' '}
                    <Link to="/presurgery" className="LinkCrd">
                        <div className="ImageCard"></div>
                    </Link>
                    <p className="Cp1">Pre-Surgery</p>
                    <p className="Cp2">Pre-Operative Nutrition</p>
                    <p className="Cp3">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink">
                        <div className="buttondiv">
                            <p className="Cp4">Learn More</p>
                        </div>
                    </Link>
                </div>
                <div className="CardBox">
                    <Link to="/presurgery" className="LinkCrd">
                        <div className="ImageCard"></div>
                    </Link>
                    <p className="Cp1">Pre-Surgery</p>
                    <p className="Cp2">Pre-Operative Nutrition</p>
                    <p className="Cp3">
                        WUNE pre-surgery is a specialized clear beverage <br />{' '}
                        formulated to support patient wellbeing (thirst, hunger{' '}
                        <br /> and anxiety) before surgery. designed to be
                        consumed <br /> up to 3 hours before aneshesia or
                        surgery.
                    </p>
                    <Link to="/presurgery" className="buttonlink">
                        <div className="buttondiv">
                            <p className="Cp4">Learn More</p>
                        </div>
                    </Link>
                </div>
            </div>

            <BottomNavigation />
        </div>
    );
};

export default ProductPage;
