import React from 'react';
import '../Styles/DownloadCard.css';
const DownloadCard = () => {
    return (
        <div className="d-container">
            <div className="tpox">Download our private label product list</div>
            <div className="download-form-divx">
                <div className="down-lx">
                    <p className="ask-yourx">Ask the Experts</p>
                    <p className="answerx">
                        Answering your wellness questions.
                    </p>
                    <div className="dont-know-divx">
                        <p>
                            I’am new to dietary supplements and I don’t know
                            where to begin
                        </p>
                    </div>
                    <div className="d-product-listx">
                        <button>ASK YOUR QUESTION</button>
                        <p>DOWNLOAD OUR PRODUCT LIST</p>
                    </div>
                </div>
                <div className="down-rx"></div>
            </div>
            <div className="div-x-1-for-mobilex"></div>
            <p className="doplx">DOWNLOAD OUR PRODUCT LIST</p>
            <p className="atex">Ask the Experts</p>
            <p className="aywqx">Answering your wellness questions.</p>
            <div className="div-x-2x">
                <p>
                    I’am new to dietary supplements and I don’t know where to
                    begin
                </p>
            </div>
            <button className="ask-btnx">ASK YOUR QUESTION</button>
        </div>
    );
};

export default DownloadCard;
