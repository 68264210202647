import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ConsoleText from '../Contents/WordsChanger';
import Brand from '../Pages/Brand';
import BrandPage from '../Pages/BrandPage';
import BrandedProductPage from '../Pages/BrandedProductPage';
import Company from '../Pages/Company';
import ContactUs from '../Pages/ContactUs';
import DownloadCard from '../Pages/DownloadCard';
import Home from '../Pages/Home';
import HomePage from '../Pages/HomePage';
import Ingredient from '../Pages/Ingredient';
import LandingPage from '../Pages/LandingPage';
import LearningCentre from '../Pages/LearningCentre';
import PreSurgery from '../Pages/PreSurgery';
import Private from '../Pages/Private';
import PrivateLabel from '../Pages/PrivateLabel';
import Privatecategory from '../Pages/Privatecategory';
import Product from '../Pages/Product';
import ProductCompodium from '../Pages/ProductCompodium';
import ProductPage from '../Pages/ProductPage';
import ProdutBrandPage from '../Pages/ProdutBrandPage';
import BrandedPortfolo from '../Pages/BrandedPortfolo';
import PrivateLabelProduct from '../Pages/PrivateLabelProduct';
import ContactPage from '../Pages/ContactPage';
const AllRoute = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'auto' });
    }, [pathname]);
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/home-page" element={<Home />} />
            <Route path="/company" element={<Company />} />
            <Route path="/product" element={<Product />} />
            <Route path="/brand" element={<Brand />} />
            <Route path="/private" element={<Private />} />
            <Route path="/privatecategory" element={<Privatecategory />} />
            <Route path="/brandpage" element={<BrandPage />} />
            <Route path="/brandedproduct" element={<BrandedPortfolo />} />
            {/* <Route path="/brandedproduct" element={<BrandedProductPage />} /> */}
            <Route path="/learningcentre" element={<LearningCentre />} />
            <Route path="/ingredients" element={<Ingredient />} />
            {/* <Route path="/contactus" element={<ContactUs />} /> */}

            <Route path="/productcompodium" element={<ProductCompodium />} />
            <Route path="/productbrand" element={<ProdutBrandPage />} />
            <Route path="/products" element={<ProductPage />} />
            <Route path="/presurgery" element={<PreSurgery />} />
            {/* <Route path="/privatelabel" element={<PrivateLabel />} /> */}
            <Route path="/privatelabel" element={<PrivateLabelProduct />} />
            {/* <Route
                path="/privatelabel-product"
                element={<PrivateLabelProduct />}
            /> */}
            <Route path="/text" element={<ConsoleText />} />
            <Route path="/dcard" element={<DownloadCard />} />
            <Route path="/contactus" element={<ContactPage />} />
            {/* <Route path="/card" element={<Card />} />
       <Route path="/swiper" element={<Cardslider />} /> */}
        </Routes>
    );
};

export default AllRoute;
