import React, { useState } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import logo1 from '../Assests/logo1.png';
import logo from '../Assests/logo4.png';
import '../Styles/Tavbar.css';
function Tavbar() {
    const [active, setActive] = useState('nav__menu');
    const [icon, setIcon] = useState('nav__toggler');
    const navToggle = () => {
        if (active === 'nav__menu') {
            setActive('nav__menu nav__active');
        } else setActive('nav__menu');

        // Icon Toggler
        if (icon === 'nav__toggler') {
            setIcon('nav__toggler toggle');
        } else setIcon('nav__toggler');
    };
    return (
        <nav className="nav">
            <Link to="/" className="nav__brand">
                <img src={logo} alt="" className="iml" />
            </Link>
            <ul className={active}>
                <li className="nav__item">
                    <Link to="/company" className="nav__link">
                        <p>Company</p>
                    </Link>
                </li>
                <li className="nav__item">
                    <Link to="/learningcentre" className="nav__link">
                        <p>Learning Centre</p>
                    </Link>
                </li>
                <li className="nav__item">
                    <Link to="/product" className="nav__link">
                        <p> Products</p>
                    </Link>
                </li>
                <li className="nav__item">
                    <Link to="/ingredients" className="nav__link">
                        <p> Our Ingredients</p>
                    </Link>
                </li>
                {/* <li className="nav__item">
                    <Link to="/" className="nav__link">
                        <p> Careers</p>
                    </Link>
                </li> */}
                <li className="nav__item">
                    <Link to="/contactus" className="nav__link">
                        <p>Contact Us</p>
                    </Link>
                </li>
                <div className="cdrdp">
                    <div className="kinlot1dp">
                        <img src={logo1} alt="" className="logo11dp" />
                        <div className="vline1dp"></div>
                        <p className="hubp1dp">CARE HUB</p>
                    </div>
                    <p className="acprdtdp">
                        Access product documentation anytime, anywhere, all in
                        one app
                    </p>
                    <div className="crdunderdp">
                        <p className="cdrt21dp">Sign in or Create an account</p>
                        <RiArrowRightSLine size={30} color="#df0000" />
                    </div>
                </div>
            </ul>

            <div onClick={navToggle} className={icon}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
        </nav>
    );
}

export default Tavbar;

//  <ul id="primary">
//   <li><a href="">Accueil</a></li>
//   <li><a href="">Qui sommes-nous ?</a></li>
//   <li><a href="">Contact</a></li>
// </ul>

// @import url('https://fonts.googleapis.com/css?family=Open+Sans:700');

// $main-color: #23abd4;

// body{
//   background:#000;
//   vertical-align:middle;
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   flex-direction:column;
//   height:100%;
// }
// #primary{
//   position:absolute;
//   top:0;
//   margin:auto;
//   text-align:center;
//   display:flex;
//   align-items:center;
//   justify-content:center;
//   height:100%;
//   li{
//     margin:0 20px;
//     position:relative;
//     a{
//       text-decoration:none;
//       display:block;
//       padding:5px 0;
//       font-family:'Open Sans';
//       font-size:26px;
//       line-height:1;
//       font-weight:bold;
//       position:relative;
//       z-index:1;
//       text-align: center;
//       -webkit-background-clip: text;
//       -webkit-text-fill-color: transparent;
//       background-image: linear-gradient(
//           to right,
//           $main-color,
//           $main-color 50%,
//           #fff 50%);
//       background-size: 200% 100%;
//       background-position: -100%;
//       transition: all 0.3s ease-in-out;
//       &:before{
//         display:block;
//         content:'';
//         width:0;
//         height:3px;
//         bottom:5px;
//         left:0;
//         bottom:-3px;
//         z-index:0;
//         position:absolute;
//         background:$main-color;
//         transition: all 0.3s ease-in-out;
//       }
//       &:hover{
//         background-position: 0%;
//         &:before{
//           width:100%;
//         }
//       }
//     }
//   }
// }
