import React from 'react';
import Navbar from '../Contents/Navbar';
import Footer from '../Contents/Footer';
import { contact, logos } from '../utils/assets';

const ContactPage = () => {
    return (
        <div className="pt-[80px]">
            <Navbar />
            <div className="w-full max-h-[670px] overflow-hidden relative">
                <div className="left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-fit absolute max-md:w-full">
                    <div className="flex items-center justify-center mb-[10px]">
                        <div className="w-[70px] md:w-[70px]">
                            <img src={logos.logo} alt="" className="w-full" />
                        </div>
                        <div className="w-[1px] h-[22px] bg-white"></div>
                        <p className="text-[20px] ml-5 text-white max-md:mt-0.5 leading-[26px] md:text-[30px] md:leading-[42px] font-medium tracking-[3px]">
                            CONTACT CENTER
                        </p>
                    </div>

                    <p className="text-white font-normal text-[15px] leading-[21px] tracking-[3px] text-center">
                        GET IN TOUCH WITH US
                    </p>
                </div>
                <img
                    src={contact[0]}
                    className="w-full object-contain max-md:hidden"
                />
                <img
                    src={contact[1]}
                    className="w-full object-contain md:hidden"
                />
            </div>

            <div className="custom__container">
                <h2 className="mt-[67px] mb-[32px] text-center font-bold text-[70px] leading-[97px] max-md:hidden">
                    Let’s Talk !
                </h2>

                <div className="w-full h-[1px] bg-[#0F172E]"></div>

                <div className="mt-[44px] gap-[47px] pb-[82px] grid grid-cols-1 lg:grid-cols-2">
                    <div className="">
                        <h3 className="font-normal text-[24px] leading-[33px] mb-[9px] max-md:text-center">
                            How you can reach us{' '}
                        </h3>

                        <p className="text-[15px] max-md:text-[13px] max-md:text-center font-normal leading-[25px]">
                            When you reach out to our Customer Service Team by
                            chat, phone or email, you will reach a real person.
                            And that one person will promptly answer any
                            questions or concerns you may have.
                        </p>

                        <div className="mt-[11px] w-full mb-[21px] flex max-md:flex-col-reverse items-center justify-between gap-[10px] max-md:text-center">
                            <div className="">
                                <p className="text-[18px] mb-[15px] font-medium leading-[25px] underline">
                                    United States Of America
                                </p>
                                <p className="font-normal text-[15px] leading-[21px]">
                                    <span className="font-medium">
                                        Support Hours:
                                    </span>
                                    8am to 8pm EST
                                </p>
                                <p className="mt-[10px] font-normal text-[15px] leading-[21px]">
                                    <span className="font-medium">
                                        Phone, SMS*:
                                    </span>
                                    +1- 848-342-1471
                                </p>
                                <p className="mt-[10px] font-normal text-[15px] leading-[21px]">
                                    <span className="font-medium">Email:</span>
                                    supportusa@kingenic.com
                                </p>
                            </div>

                            <div className="w-[1px] max-md:w-full max-md:max-w-[197px] max-md:h-[1px] h-[138px] bg-[#007AD9]"></div>

                            <div className="">
                                <p className="text-[18px] mb-[15px] font-medium leading-[25px] underline">
                                    Republic of India
                                </p>
                                <p className="font-normal text-[15px] leading-[21px]">
                                    <span className="font-medium">
                                        Support Hours:
                                    </span>
                                    9 am to 5 pm IST
                                </p>
                                <p className="mt-[10px] font-normal text-[15px] leading-[21px]">
                                    <span className="font-medium">
                                        Phone, SMS*:
                                    </span>
                                    +91- 848-342-1471
                                </p>
                                <p className="mt-[10px] font-normal text-[15px] leading-[21px]">
                                    <span className="font-medium">Email:</span>
                                    supportindia@kingenic.com
                                </p>
                            </div>
                        </div>

                        <p className="text-[10px] max-md:text-center font-normal leading-[12px]">
                            *Message & Data rates may apply. By texting us, you
                            consent to receive text messages from Athletic
                            Greens at the mobile number you used to text and you
                            are opting-in to receive future messages or a phone
                            call in the number you provided. Message & Data
                            rates may apply. Reply STOP anytime to opt-out. (For
                            USA Support)
                        </p>
                    </div>

                    <div className="">
                        <h3 className="font-normal text-[24px] leading-[33px] mb-[9px]">
                            Send a message
                        </h3>

                        <form action="">
                            <input
                                type="text"
                                name="subject"
                                className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                placeholder="Short note : How can we help you?"
                            />

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[5px]">
                                <input
                                    type="text"
                                    name="first_name"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="First Name"
                                />
                                <input
                                    type="text"
                                    name="last_name"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Last Name"
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[5px]">
                                <input
                                    type="text"
                                    name="cp"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Company Name"
                                />
                                <input
                                    type="text"
                                    name="jt"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Job Title"
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[5px]">
                                <input
                                    type="text"
                                    name="coemail"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Company Email Address"
                                />
                                <input
                                    type="text"
                                    name="comobile"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Mobile Number"
                                />
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-[5px]">
                                <input
                                    type="email"
                                    name="country"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Select Country"
                                />
                                <input
                                    type="text"
                                    name="country2"
                                    className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                    placeholder="Select Country"
                                />
                            </div>

                            <textarea
                                name="message"
                                id=""
                                className="border rounded-[4px] my-[7px] w-full p-[6px] text-[12px]"
                                cols="30"
                                rows="5"
                                placeholder="How can we help you? (5000 characters max)* "
                            ></textarea>

                            <label
                                htmlFor="ckhs"
                                className="flex items-center max-md:justify-center"
                            >
                                <input
                                    type="checkbox"
                                    name="agrree"
                                    id="ckhs"
                                    className="!border !border-gray-500 mr-2 !aspect-square"
                                />

                                <p className="text-[12px] leading-[16px]">
                                    Yes, I would like Kingenic to inform me
                                    about special offers and new products via
                                    email.
                                </p>
                            </label>

                            <p className="mt-[13px] text-[10px] leading-[13px] font-medium">
                                This site is protected by reCAPTCHA and the
                                Google Privacy Policy and Terms of Service
                                apply.
                            </p>

                            <div className="flex items-center max-md:justify-center mt-[14px] ">
                                <button
                                    type="submit"
                                    className="bg-[#151515] rounded-md text-[10px] px-[19px] py-[7px] uppercase font-medium text-white"
                                >
                                    SEND A MESSAGE
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default ContactPage;
