import { useState } from 'react';
import { FaAngleDown } from 'react-icons/fa6';
import { cn } from '../../utils/classMarge';

const ExpendableFAQ = ({ title, desc, subtitle, paragraph, img, index }) => {
    const [isExpend, setIsExpend] = useState(false);
    return (
        <div className="w-full relative pl-[55px] group">
            <div className="left-0 top-0 h-full w-[55px] absolute">
                <div className="w-fit flex flex-col items-center">
                    <div className="w-[40px] h-[40px] rounded-full bg-[#ebeaed] text-[18px]  flex items-center justify-center z-10">
                        <p className="text-[#646464] font-semibold">{index}</p>
                    </div>

                    <div className="h-full left-[19px] top-0 absolute bg-[#ebeaed] w-[2px] -z-0 group-last:hidden"></div>
                </div>
            </div>

            <div className={cn()}>
                <h2 className="text-[18px] font-medium leading-[28px] mb-[20px] pt-[8px]">
                    {title}
                </h2>

                <p className="text-[15px] font-normal leading-[26px] text-[#50504e]">
                    {desc}
                </p>

                <div
                    onClick={() => setIsExpend(!isExpend)}
                    className="flex items-center gap-3 text-[#007AD9] text-[16px] leading-[26px] mt-[4px] mb-[12px] cursor-pointer"
                >
                    <p className="">Expand</p> <FaAngleDown className="mt-1" />
                </div>

                <div
                    className={cn(
                        'max-h-0 overflow-hidden transition-all duration-300 ',
                        isExpend &&
                            'max-h-[1500px] transition-all duration-300 pb-[70px]'
                    )}
                >
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-[23px]">
                        <div className="w-full h-full rounded-lg overflow-hidden flex items-center justify-center">
                            <img
                                src={img}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        </div>

                        <div className="flex flex-col justify-center">
                            <h4 className="text-[18px] font-medium leading-[28px] mb-[6px]">
                                {subtitle}
                            </h4>

                            <p className="text-[16px] font-normal leading-[26px]">
                                {paragraph}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExpendableFAQ;
