import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Assests/logo1.png';
import '../Styles/LandingPage.css';
const LandingPage = () => {
    return (
        <div className="bgimage">
            {/* <div class="bgimage"></div> */}
            <div id="container">
                <div id="box1">
                    <div id="Lbox">
                        <div id="logobox">
                            <img src={logo} alt="" />
                            <div className="vertical-line12"></div>
                            <p>NUTRITION HUB</p>
                        </div>

                        <p className="nutritext">
                            Embark on a journey of evidence based <br />{' '}
                            nutritional product explorations
                        </p>
                        <p className="bottext">
                            Learn about our portfolio, explore product guides,
                            Access relevant clinical studies <br /> and
                            peer-reviewed articles to stay informed on the
                            latest research and nutritional <br /> best
                            practices. We help you make informed decisions.
                        </p>
                        <p className="bottext1">
                            Learn about our portfolio, explore product guides,
                            Access relevant clinical studies and peer-reviewed
                            articles to stay informed on the latest research and
                            nutritional best practices. We help you make
                            informed decisions.
                        </p>
                        <Link
                            to="/home-page"
                            style={{ textDecoration: 'none' }}
                        >
                            <div id="butbox">
                                <button className="buton">Get Started</button>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
