/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const ConsoleText = ({ words, id, colors }) => {
    const [letterCount, setLetterCount] = useState(1);
    const [x, setX] = useState(1);
    const [waiting, setWaiting] = useState(false);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (letterCount === 0 && !waiting) {
                setWaiting(true);
                setTimeout(() => {
                    const usedColor = colors.shift();
                    colors.push(usedColor);
                    const usedWord = words.shift();
                    words.push(usedWord);
                    setX(1);
                    setLetterCount(letterCount + x);
                    setWaiting(false);
                }, 1000);
            } else if (letterCount === words[0].length + 1 && !waiting) {
                setWaiting(true);
                setTimeout(() => {
                    setX(-1);
                    setLetterCount(letterCount + x);
                    setWaiting(false);
                }, 1000);
            } else if (!waiting) {
                setLetterCount(letterCount + x);
            }
        }, 120);

        const underscoreIntervalId = setInterval(() => {
            setVisible((prevVisible) => !prevVisible);
        }, 400);

        return () => {
            clearInterval(intervalId);
            clearInterval(underscoreIntervalId);
        };
    }, [letterCount, waiting, x, words, colors]);

    return (
        <>
            <div id={id} style={{ color: colors[0] }}>
                {words[0].substring(0, letterCount)}
            </div>
            <div
                className={`console-underscore ${visible ? '' : 'hidden'}`}
            ></div>
        </>
    );
};

export default ConsoleText;
