import '../Styles/Brand.css';

import { Link } from 'react-router-dom';
import '../Styles/Product.css';
// import AnimatedCursor from "react-animated-cursor";
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import Footer from '../Contents/Footer';
import Navbar from '../Contents/Navbar';
import '../Styles/fonts.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
const brand = () => {
    const isMobile = window.innerWidth <= 768;
    return (
        <div className="brand-container">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />

            <div className="Bmain">
                <div className="brand-container-A">
                    <p className="BCCat">Branded Portfolio</p>
                    <div className="BClngText">
                        <p className="BC1t">
                            Our products are developed on scientific principles,
                            innovation, with a deep understanding of human
                            needs. They are trusted by businesses and consumers
                            worldwide. Kingenic possesses a robust collection of
                            brands and formulations strategically poised to
                            serve a crucial role for people across the globe. In
                            a rapidly expanding and increasingly relevant
                            sector, we are well-positioned. We offer a portfolio
                            of one thousand in-house formulas for contract
                            manufacturing and have nine Power Brands prepared
                            for distribution and vertical expansion.
                            Additionally, we provide exclusive contract
                            offerings with special incentives.
                        </p>
                        <p className="BC2t">
                            Offering widest range of most advanced and
                            convenient powdered formulas in the regions we
                            serve.{' '}
                        </p>
                    </div>
                </div>
                <div className="BxbgBox"></div>
                <div className="brand-container-b">
                    <div className="bc-div1"></div>
                    <div className="bc-div2">
                        <div className="brand-wune-logo"></div>
                        <p className="intro-of-wune">
                            Introducing Wune, innovative brand developed by
                            Kingenic that specializes in wound management. We
                            assist healthcare professionals in achieving optimal
                            healing by harnessing the power of nutrition.
                            Whether it&apos;s pre-surgery, post-surgery, or
                            disease-specific wound care, we provide a
                            comprehensive range of products for accelerated
                            healing.
                        </p>
                        <p className="wune-target">
                            Wune targets these areas of interest:
                        </p>

                        <ul className="custom-list">
                            <li>Wound Management</li>
                            <li> Diabetic Foot Ulcers</li>
                            <li>Pre Surgery Nutrition</li>
                            <li>Post Surgery Recovery</li>
                            <li>GastroIntestinal Health</li>
                            <li>Malnutrition</li>
                            <li>Oncology Malnutrition Management</li>
                            <li>Muscle Health</li>
                        </ul>
                        <button>
                            <Link to="/brandpage" className="bc-link-p1">
                                Visit Wune
                            </Link>
                        </button>
                        <p className="applies-to-all">
                            Applies to all Wune products manufactured after
                            October 2023 and distributed by authorised
                            distributors. Please check back of the label for
                            accurate product information
                        </p>
                    </div>
                </div>
                <div className="brand-container-c">
                    <div className="bc-div1-c"></div>
                    <div className="bc-div2-c">
                        <div className="brand-wune-logo-c"></div>
                        <p className="intro-of-wune-c">
                            Introducing Wune, innovative brand developed by
                            Kingenic that specializes in wound management. We
                            assist healthcare professionals in achieving optimal
                            healing by harnessing the power of nutrition.
                            Whether it&apos;s pre-surgery, post-surgery, or
                            disease-specific wound care, we provide a
                            comprehensive range of products for accelerated
                            healing.
                        </p>
                        <p className="wune-target-c">
                            Collagen Republic targets these areas of interest:
                        </p>

                        <ul className="custom-list-c">
                            <li>Wound Management</li>
                            <li> Diabetic Foot Ulcers</li>
                            <li>Pre Surgery Nutrition</li>
                            <li>Post Surgery Recovery</li>
                            <li>GastroIntestinal Health</li>
                            <li>Malnutrition</li>
                            <li>Oncology Malnutrition Management</li>
                            <li>Muscle Health</li>
                        </ul>
                        <button>
                            <Link to="/brandpage" className="bc-link-p2">
                                Visit Collagen Republic
                            </Link>
                        </button>
                        <p className="applies-to-all-c">
                            Applies to all Wune products manufactured after
                            October 2023 and distributed by authorised
                            distributors. Please check back of the label for
                            accurate product information
                        </p>
                    </div>
                </div>
                <div className="brand-container-d">
                    <div className="bc-div1-d"></div>
                    <div className="bc-div2-d">
                        <div className="brand-wune-logo-d"></div>
                        <p className="intro-of-wune-d">
                            Introducing Wune, innovative brand developed by
                            Kingenic that specializes in wound management. We
                            assist healthcare professionals in achieving optimal
                            healing by harnessing the power of nutrition.
                            Whether it&apos;s pre-surgery, post-surgery, or
                            disease-specific wound care, we provide a
                            comprehensive range of products for accelerated
                            healing.
                        </p>
                        <p className="wune-target-d">
                            Wune targets these areas of interest:
                        </p>

                        <ul className="custom-list-d">
                            <li>Wound Management</li>
                            <li> Diabetic Foot Ulcers</li>
                            <li>Pre Surgery Nutrition</li>
                            <li>Post Surgery Recovery</li>
                            <li>GastroIntestinal Health</li>
                            <li>Malnutrition</li>
                            <li>Oncology Malnutrition Management</li>
                            <li>Muscle Health</li>
                        </ul>
                        <button>
                            <Link to="/brandpage" className="bc-link-p1">
                                Visit Wune
                            </Link>
                        </button>
                        <p className="applies-to-all-d">
                            Applies to all Wune products manufactured after
                            October 2023 and distributed by authorised
                            distributors. Please check back of the label for
                            accurate product information
                        </p>
                    </div>
                </div>
                <p className="speciality-nutrition">Speciality Nutrition</p>
                <div className="brand-container-e">
                    <div>
                        <div className="brand-img-div1"></div>
                        <div className="brand-img-div2">
                            <p className="pediatric-specialty">
                                Pediatric Specialty Nutrition
                            </p>
                            <p className="Abott-offers">
                                Abbott offers a variety of products designed to
                                help meet unique nutrition needs in infants and
                                children.
                            </p>
                            <p className="explore-osemil">Explore Osemil</p>
                        </div>
                    </div>
                    <div>
                        <div className="brand-img-div1"></div>
                        <div className="brand-img-div2">
                            <p className="pediatric-specialty">
                                Pediatric Specialty Nutrition
                            </p>
                            <p className="Abott-offers">
                                Abbott offers a variety of products designed to
                                help meet unique nutrition needs in infants and
                                children.
                            </p>
                            <p className="explore-osemil">Explore Osemil</p>
                        </div>
                    </div>
                </div>
                <p className="design-to-help">
                    *Designed to help minimize blood sugar spikes in people with
                    diabetes compare to high-glycemic carbohydrates.
                </p>
                <div className="brand-container-f">
                    <p className="expand-Your-product">
                        Expand your product portfolio by featuring science based
                        products
                    </p>
                    <p className="Offering-Market-ready-brands">
                        Offering market ready brands for distribution.
                        <br /> Become a distributor today !
                    </p>
                    <button>Contact Us</button>
                </div>
                <p className="waysto-brand">Other ways to partner with us</p>
                <div className="brand-container-g">
                    <Link
                        to="/"
                        className="brnd1-link"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="card-br1">
                            <div></div>
                            <p className="br-1">Contract Manufacturing</p>
                            <p className="br-2">
                                We help you start a new brand or expand the
                                existing brand
                            </p>
                            <p className="br-30">
                                Click here to start you own brand{' '}
                            </p>
                        </div>
                    </Link>
                    <Link
                        to="/"
                        className="brnd1-link"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="card-br1">
                            <div></div>
                            <p className="br-1">Product Development</p>
                            <p className="br-2">
                                We develop custom product formulas based your
                                targeted concept
                            </p>
                            <p className="br-31">
                                Unique product just a click away
                            </p>
                        </div>
                    </Link>
                    <Link
                        to="/"
                        className="brnd1-link"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="card-br1">
                            <div></div>
                            <p className="br-1">Clinical Trials</p>
                            <p className="br-2">
                                Offering clinical trials execution in multiple
                                areas of food and nutrition
                            </p>
                            <p className="br-32">
                                Start a supplement clinical trial today
                            </p>
                        </div>
                    </Link>
                    <Link
                        to="/"
                        className="brnd1-link"
                        style={{ textDecoration: 'none' }}
                    >
                        <div className="card-br1">
                            <div></div>
                            <p className="br-1">Branded Products</p>
                            <p className="br-2">
                                We offer wide range of market ready brands for
                                distribution
                            </p>
                            <p className="br-33">Become a distributor </p>
                        </div>
                    </Link>
                </div>
                <div className="brand-container-h">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <Link
                                to="/"
                                className="brnd1-link-h"
                                style={{ textDecoration: 'none' }}
                            >
                                <div className="card-br1-h">
                                    <div></div>
                                    <p className="br-1-h">
                                        Contract Manufacturing
                                    </p>
                                    <p className="br-2-h">
                                        We help you start a new brand or expand
                                        the existing brand
                                    </p>
                                    <p className="br-30-h">
                                        Click here to start you own brand{' '}
                                    </p>
                                </div>
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Link
                                to="/"
                                className="brnd1-link-h"
                                style={{ textDecoration: 'none' }}
                            >
                                <div className="card-br1-h">
                                    <div></div>
                                    <p className="br-1-h">
                                        Product Development
                                    </p>
                                    <p className="br-2-h">
                                        We develop custom product formulas based
                                        your targeted concept
                                    </p>
                                    <p className="br-31-h">
                                        Unique product just a click away
                                    </p>
                                </div>
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Link
                                to="/"
                                className="brnd1-link-h"
                                style={{ textDecoration: 'none' }}
                            >
                                <div className="card-br1-h">
                                    <div></div>
                                    <p className="br-1-h">Clinical Trials</p>
                                    <p className="br-2-h">
                                        Offering clinical trials execution in
                                        multiple areas of food and nutrition
                                    </p>
                                    <p className="br-32-h">
                                        Start a supplement clinical trial today
                                    </p>
                                </div>
                            </Link>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Link
                                to="/"
                                className="brnd1-link-h"
                                style={{ textDecoration: 'none' }}
                            >
                                <div className="card-br1-h">
                                    <div></div>
                                    <p className="br-1-h">Branded Products</p>
                                    <p className="br-2-h">
                                        We offer wide range of market ready
                                        brands for distribution
                                    </p>
                                    <p className="br-33-h">
                                        Become a distributor{' '}
                                    </p>
                                </div>
                            </Link>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default brand;
