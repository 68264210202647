import { Link } from 'react-router-dom';
import backbutton1 from '../Assests/VectorN.svg';
import CalenderIcon from '../Assests/calendar.svg';
import search from '../Assests/search.svg';
import BottomNavigation from '../Components/BottomNavigation';
import '../Styles/HomePage.css';

import bot1 from '../Assests/bot1.png';
import botlogo from '../Assests/botlogo.svg';
import Banner1 from '../Assests/boxbanner.jpg';
const HomePage = () => {
    return (
        <div className="container">
            <div className="Tnavbar">
                <img
                    src={backbutton1}
                    alt=""
                    className="vectorN"
                    // onClick={handleGoBack}
                />

                <div className="pBox">
                    <p className="hub1">HUB </p>
                    <p className="hub2">Navigate by interest</p>
                </div>
                <img
                    src={search}
                    alt=""
                    className="vectorN"
                    // onClick={handleGoBack}
                />
            </div>
            <div id="navbar">
                <p className="textHello">Hello</p>
                <p className="navtext">What would you like to explore today?</p>
            </div>

            <div className="topBox1">
                <div className="pribox11">
                    <p
                        style={{ width: '100%', height: '100%' }}
                        className="para1"
                    >
                        Feel great through the <br /> power of{' '}
                        <span className="nutTxt1">nutrition</span>
                    </p>
                </div>
                <div className="boxcalender1">
                    <div className="pribox21">
                        <p
                            style={{
                                color: 'black',
                                fontSize: 14,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            Book your free online product consultation. <br />{' '}
                            Get one step closer to your dream brand.
                        </p>
                    </div>
                    <div id="pributtonBox10">
                        <img
                            src={CalenderIcon}
                            alt="calender"
                            className="priiconx10"
                        />
                        <p>Schedule a call</p>
                    </div>
                </div>
            </div>
            <div className="card-container">
                {/* <Link to='/productcompodium' className="cLink"> */}
                <div className="card">
                    <Link to="/productcompodium" className="cLink">
                        <img src={Banner1} alt="" />
                    </Link>
                    <div className="card-content">
                        <p className="title">Product Compendium</p>
                        <p className="descrip">
                            Explore our branded portfolio and private label
                            offerings
                        </p>
                        <p className="explr">Explore</p>
                    </div>
                </div>
                {/* </Link> */}
                {/* <Link to='/productcompodium' className="cLink"> */}
                <div className="card">
                    <Link to="/productcompodium" className="cLink">
                        <img src={Banner1} alt="" />
                    </Link>
                    <div className="card-content">
                        <p className="title">Customer Portal</p>
                        <p className="descrip">
                            From documentation to live service portal
                        </p>
                        <p className="explr">Explore</p>
                    </div>
                </div>
                {/* </Link> */}
                {/* <Link to='/productcompodium' className="cLink"> */}
                <div className="card">
                    <Link to="/productcompodium" className="cLink">
                        <img src={Banner1} alt="" />
                    </Link>
                    <div className="card-content">
                        <p className="title">Learning Center</p>
                        <p className="descrip">
                            Access free, expert-created continuous education
                            data for advanced condition management
                        </p>
                        <p className="explr">Explore</p>
                    </div>
                </div>
                {/* </Link> */}
            </div>

            <div className="horzline"></div>
            <div className="bottomBox">
                <div className="botboxL">
                    <div className="botlogobox">
                        <div className="logodiv">
                            <img src={botlogo} alt="" className="logox" />
                        </div>
                        <div className="vertical-line"></div>
                        <p className="nuttri">LEARNING HUB</p>
                    </div>
                    <p className="ltext">
                        <span
                            style={{
                                color: '#1E1F1F',
                                fontSize: 8,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            The new era of intelligent
                        </span>
                        <span
                            style={{
                                color: '#1E1F1F',
                                fontSize: 8,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '600',
                                wordWrap: 'break-word',
                            }}
                        >
                            {' '}
                        </span>
                        <span
                            style={{
                                color: '#1E1F1F',
                                fontSize: 8,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '700',
                                wordWrap: 'break-word',
                            }}
                        >
                            nutrition
                        </span>
                        <span
                            style={{
                                color: '#1E1F1F',
                                fontSize: 8,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '600',
                                wordWrap: 'break-word',
                            }}
                        >
                            {' '}
                        </span>
                        <span
                            style={{
                                color: '#1E1F1F',
                                fontSize: 8,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            is here{' '}
                        </span>{' '}
                    </p>
                    <p className="trending">@ TrendingShorties</p>
                </div>
                <div className="boxright">
                    <div className="l1box">
                        <img src={bot1} alt="" className="imagel" />
                    </div>
                    <div className="l2box">
                        <p
                            style={{
                                color: 'black',
                                fontSize: 24,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '600',
                                wordWrap: 'break-word',
                            }}
                        >
                            Probiotics for Your Family’s Immunity
                        </p>
                        <div className="longtbox">
                            <p className="longt">
                                With life being so busy, now is a good time to
                                be thinking about getting your whole family’s
                                health in tip-top condition. Supporting the{' '}
                                <br /> immune system, through a combination of
                                approaches, is the best way to do this. In a
                                2021 survey, 62%1 of consumers indicated that{' '}
                                <br /> having a strong immune system is their
                                definition of &apos;healthy&apos;. Put simply,
                                the immune system is a network of different
                                cells, tissues, <br /> organs, and proteins
                                which are located throughout the body. The
                                different components of the immune system all
                                work together to <br /> protect us...........
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <BottomNavigation />
        </div>
    );
};

export default HomePage;
