import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import backbutton from '../Assests/Vector2.svg';
import backbutton1 from '../Assests/VectorN.svg';
import CalenderIcon from '../Assests/calendar.svg';
import collagen from '../Assests/collagent.svg';
import definite from '../Assests/definite.svg';
import infused from '../Assests/infused.svg';
import search from '../Assests/search.svg';
import wune from '../Assests/wune.svg';
import BottomNavigation from '../Components/BottomNavigation';
import '../Styles/ProductBrandPage.css';
const ProdutBrandPage = () => {
    const navigate = useNavigate(); // Use useNavigate

    const handleGoBack = () => {
        navigate(-1); // This will navigate back to the previous page.
    };
    return (
        <div id="brandContainer">
            <div className="Tnavbar3">
                <img
                    src={backbutton1}
                    alt=""
                    className="vectorN3"
                    onClick={handleGoBack}
                />

                <div className="pBox3">
                    <p className="hubx3">Branded Products</p>
                    <p className="hubx4">Navigate by interest</p>
                </div>
                <img
                    src={search}
                    alt=""
                    className="vectorN3"
                    // onClick={handleGoBack}
                />
            </div>
            <div id="brandnavbar1">
                <img
                    src={backbutton}
                    alt=""
                    className="vector2"
                    onClick={handleGoBack}
                />
                <div className="navTextbox2">
                    <p className="textHello2">Brands</p>
                    <p className="navtext2">
                        Products to enhance, maintain and improve health at
                        every stage of life, via advanced nutrition.
                    </p>
                </div>
            </div>
            {/* <div className="brandText">
        <div className="boxLeft">
          <div className="lefttext1">
            <p style={{width: '100%', height: '100%'}}><span style={{color: '#222222', fontSize: 45, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}>From </span><span style={{color: '#A083BC', fontSize: 45, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}>concept</span><span style={{color: '#222222', fontSize: 45, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}> to a </span><span style={{color: '#4DA0D6', fontSize: 45, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}>product</span><span style={{color: '#222222', fontSize: 45, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}>. <br/>We got you </span><span style={{color: '#4DA0D6', fontSize: 45, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}>covered</span></p>
          </div>
          <div className="lefttext2">
            <p style={{width: '100%', height: '100%'}}><span style={{color: 'black', fontSize: 14, fontFamily: "NewHero, sans-serif", fontWeight: '400',  wordWrap: 'break-word'}}>Book your free online product consultation.<br/>Get one step closer to your dream brand.</span><span style={{color: 'black', fontSize: 14, fontFamily: "NewHero, sans-serif", fontWeight: '400', wordWrap: 'break-word'}}> </span></p>
          </div>
          <div id="pributtonBox1">
            <img src={CalenderIcon} alt="calender" className="priiconx1" />
            <p>Schedule a call</p>
          </div>
        </div>
        <div className="boxRight">
          <div className="brtext">
            <p style={{color: '#222222', fontSize: 30, fontFamily: "NewHero, sans-serif", fontWeight: '500', wordWrap: 'break-word'}}>Speciality Nutrition</p>
          </div>
          <div className="brtext2">
            
          </div>
        </div>
      </div> */}

            <div className="topBox1b">
                <div className="pribox11b">
                    <p
                        style={{ width: '100%', height: '100%' }}
                        className="para1b"
                    >
                        From <span className="conText">concept</span> to a{' '}
                        <span className="prTextr">product</span>.<br /> We got
                        you <span className="cText">covered</span>
                    </p>
                </div>
                <div className="boxcalender1b">
                    <div className="pribox21b">
                        <p
                            style={{
                                color: 'black',
                                fontSize: 14,
                                fontFamily: 'NewHero, sans-serif',
                                fontWeight: '400',
                                wordWrap: 'break-word',
                            }}
                        >
                            Book your free online product consultation. <br />{' '}
                            Get one step closer to your dream brand.
                        </p>
                    </div>
                    <div id="pributtonBox10b">
                        <img
                            src={CalenderIcon}
                            alt="calender"
                            className="priiconx10b"
                        />
                        <p>Schedule a call</p>
                    </div>
                </div>
            </div>

            <p className="botmText">
                Take a step closer to our{' '}
                <span className="brdText">brands</span>.
            </p>

            {/* <div className="cardbox">
        <Link to="/products" className="linkCard" >
          <div className="childBox">
            <div className="imageBrandBox">
              <img src={wune} alt="" className="imagecard" />
            </div>
            <p className="CardText">WÛNE</p>
            <p className="CardText2">Advanced Wound Management Brand</p>
            <p className="CardText3">Explore More</p>
          </div>
        </Link>

         <Link to="/" className="linkCard" >
          <div className="childBox">
            <div className="imageBrandBox">
              <img src={collagen} alt="" className="imagecard" />
            </div>
            <p className="CardText">Collagen Republic</p>
            <p className="CardText2">Skincare from within</p>
            <p className="CardText3">Explore More</p>
          </div>
        </Link>

         <Link to="/" className="linkCard" >
          <div className="childBox">
            <div className="imageBrandBox">
              <img src={definite} alt="" className="imagecard" />
            </div>
            <p className="CardText">Definite</p>
            <p className="CardText2">Comprehensive nutritional support</p>
            <p className="CardText3">Explore More</p>
          </div>
        </Link>

         <Link to="/" className="linkCard">
          <div className="childBox">
            <div className="imageBrandBox">
              <img src={infused} alt="" className="imagecard" />
            </div>
            <p className="CardText">Infused</p>
            <p className="CardText2">Instant Energy Melts</p>
            <p className="CardText3">Explore More</p>
          </div>
        </Link>
       </div> */}
            <div className="card-container3">
                <div className="card3">
                    <Link to="/products">
                        <img src={wune} alt="" />
                    </Link>
                    <div className="card-content3">
                        <p className="title3">WÛNE</p>
                        <p className="descrip3">
                            Advanced Wound Management Brand
                        </p>
                        <p className="explr3">Explore</p>
                    </div>
                </div>
                <div className="card3">
                    <Link to="/">
                        <img src={collagen} alt="" />
                    </Link>
                    <div className="card-content3">
                        <p className="title3">Collagen Republic</p>
                        <p className="descrip3">Skincare from within</p>
                        <p className="explr3">Explore</p>
                    </div>
                </div>
                <div className="card3">
                    <Link to="/">
                        <img src={definite} alt="" />
                    </Link>
                    <div className="card-content3">
                        <p className="title3">Definite</p>
                        <p className="descrip3">
                            Comprehensive nutritional support
                        </p>
                        <p className="explr3">Explore</p>
                    </div>
                </div>
                <div className="card3">
                    <Link to="/">
                        <img src={infused} alt="" />
                    </Link>
                    <div className="card-content3">
                        <p className="title3">Infused</p>
                        <p className="descrip3">Instant Energy Melts</p>
                        <p className="explr3">Explore</p>
                    </div>
                </div>
            </div>

            <BottomNavigation />
        </div>
    );
};

export default ProdutBrandPage;
