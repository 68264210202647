import '../Styles/Company.css';

import Footer from '../Contents/Footer';
import Navbar from '../Contents/Navbar';
import '../Styles/fonts.css';

// Import Swiper styles
import 'swiper/css';
import ProductCard from '../Components/company/ProductCard';
import { cirtificates, cpnyImg, newsImg, pdfs, teamImg } from '../utils/assets';

import BusinessDivisionSlider from '../Components/company/BusinessDivisionSlider';
import InfoTab from '../Components/company/InfoTab';
import NewCard from '../Components/company/NewCard';

const Company = () => {
    const cirtificate = [
        {
            title: 'FSSAI',
            img: cirtificates[0],
            url: pdfs.fssai,
        },
        {
            title: 'USFDA',
            img: cirtificates[1],
            url: pdfs.usfda,
        },
        {
            title: 'DIPPT',
            img: cirtificates[2],
            url: pdfs.dippt,
        },
        {
            title: 'ZED',
            img: cirtificates[3],
            url: pdfs.zed,
        },
        {
            title: 'GMP',
            img: cirtificates[4],
            url: pdfs.gmp,
        },
        {
            title: 'KOSHER',
            img: cirtificates[5],
            url: pdfs.kosher,
        },
        {
            title: 'HACCP',
            img: cirtificates[6],
            url: pdfs.haccp,
        },
        {
            title: 'HALAL',
            img: cirtificates[7],
            url: pdfs.halal,
        },
    ];

    const news = [
        {
            title: 'Territory Expansion',
            paragraph:
                'Kingenic proudly announces the establishment of a new branch office in the United States, strategically positioned to cater to the diverse and dynamic markets of North America. This expansion marks a significant milestone in our commitment for worldwide expansion. ',
            img: newsImg[0],
        },
        {
            title: 'Designated product development and manufacturing partner',
            paragraph:
                "Kingenic has been designated as the product development and manufacturing partner for RASHA, cementing a collaborative alliance that leverages our expertise to drive innovation and deliver exceptional product development and manufacturing solutions for RASHA's initiatives.",
            img: newsImg[1],
        },
        {
            title: 'Kingenic launches Infused in North American market',
            paragraph:
                'Kingenic gears up to introduce its proprietary instant energy brand in North American markets, initiating the launch in 5000 retail stores. Our ambitious plan includes expanding this presence to a remarkable 300,000 stores within the next two years across North and South America',
            img: newsImg[2],
        },
    ];

    return (
        <div id="company-container-A">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />
            <div className="Cmain">
                <p className="About-kingenic max-md:!mb-[10px]">
                    About Kingenic{' '}
                </p>
                <p className="max-w-[1062px] max-md:px-[24px] text-[15px] leading-[21px] mx-auto font-normal text-center">
                    At Kingenic, we are committed to promoting healthier lives
                    worldwide through specialized nutrition and nourishment that
                    truly benefits people&apos;s health. Our mission-driven
                    company is grounded in science, with a focus on developing
                    innovative solutions that are ready for market. By
                    partnering with us, our clients gain access to a robust
                    pipeline of purposeful products, ranging from health
                    supplements and clinical nutrition to fast-moving consumer
                    goods (FMCG). We offer a broad range of segmented products,
                    making us an ideal partner for co - development,
                    co-manufacturing, or distribution of our branded products
                </p>
                {/* -------------------------------- */}
                {/* -- Heading sectn slider start -- */}
                {/* -------------------------------- */}
                <div className="max-w-[1620px] mt-[54px] md:mt-[98px] w-full mx-auto px-[20px] max-md:px-0">
                    <div className="min-h-[287px] md:max-h-[725px] max-md:h-[287px] w-full rounded-lg max-md:rounded-none overflow-hidden relative">
                        <img
                            src={cpnyImg.headeing}
                            alt=""
                            className="w-full h-full object-cover"
                        />

                        <div className="absolute bottom-0 left-0 w-full py-[16px] pl-[100px] backdrop-blur-sm bg-white/30 text-white max-md:pl-[24px]">
                            <p className="text-[12px] leading-[16px] md:text-[19px] md:leading-[26px] font-normal">
                                Transforming Nutrition
                            </p>

                            <h3 className="text-[30px] leading-[41px] md:text-[64px] md:leading-[80px] font-medium">
                                Our Story
                            </h3>

                            <h3 className="text-[25px] leading-[35px] md:text-[64px] md:leading-[80px] font-normal">
                                Inspired By Innovation
                            </h3>
                        </div>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* -- Heading sectn slider End   -- */}
                {/* -------------------------------- */}
                {/* -------------------------------- */}
                {/* --Concept product sct Start   -- */}
                {/* -------------------------------- */}
                <div className="custom__container">
                    <div className="py-[50px] md:py-[100px] w-full">
                        <p className="text-center uppercase md:tracking-[2px] text-[14px] leading-[26px] md:font-bold max-md:text-[10px] max-md:leading-[14px] text-[#007ad9] max-md:max-w-[339px] max-md:mx-auto">
                            We develop and manufacture products that are unique
                            and marketable
                        </p>
                        <p className="text-[25px] max-md:font-normal max-md:leading-[35px] md:text-[35px] text-center font-medium max-w-[866px] mx-auto mt-[13px]">
                            Revitalising health, <br /> Energising lives through
                            reimagined nutrition
                        </p>

                        <div className="max-w-[846px] w-full mx-auto h-[1px] bg-[#007AD9] my-[25px]"></div>

                        <p className="text-[10px] mt-[27px] font-normal leading-[14px] text-center text-[#007AD9]">
                            CRAFTED SERVICES
                        </p>

                        <p className="text-[20px] leading-[28px] my-[8px] text-[#0F172E] font-normal text-center">
                            From Concept to Product
                        </p>

                        <p className="max-w-[845px] mx-auto text-[15px] text-[#0F172E] leading-[21px] font-normal text-center">
                            Kingenic is a multifunctional nutrition solution
                            partner with key focus on end-to-end solution with
                            highest quality, affordable oral nutrition
                            supplements (ONS)
                        </p>

                        <div className="mt-[40px] flex max-md:flex-col items-center gap-[51px]">
                            <div className="w-full rounded-lg overflow-hidden">
                                <img
                                    src={cpnyImg.cpny2}
                                    alt=""
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <div className="max-w-[329px] w-full aspect-square max-md:text-center">
                                <div className="mb-[42px]">
                                    <h4 className="text-[20px] font-normal mb-[10px] leading-[28px] text-[#007AD9]">
                                        Development
                                    </h4>
                                    <p className="text-[15px] leading-[21px] font-normal">
                                        Take an idea to the shelf by our product
                                        development expertise
                                    </p>
                                </div>

                                <div className="mb-[42px]">
                                    <h4 className="text-[20px] font-normal mb-[10px] leading-[28px] text-[#007AD9]">
                                        Manufacturing
                                    </h4>
                                    <p className="text-[15px] leading-[21px] font-normal">
                                        Optimised formulas, quick turn around,
                                        low MOQ
                                    </p>
                                </div>

                                <div>
                                    <h4 className="text-[20px] font-normal mb-[10px] leading-[28px] text-[#007AD9]">
                                        Re-Packing
                                    </h4>
                                    <p className="text-[15px] leading-[21px] font-normal">
                                        Facilitate swift adaptation to
                                        production demands.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="max-w-[846px] w-full mx-auto h-[2px] bg-[#007AD9] my-[25px]"></div>

                        <p className="text-[10px] mt-[27px] font-normal leading-[14px] text-center">
                            CERTIFICATIONS
                        </p>

                        <p className="text-[20px] leading-[28px] my-[8px] text-[#0F172E] font-normal text-center">
                            From Concept to Product
                        </p>

                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-[29px]">
                            {cirtificate.map((cirt) => (
                                <ProductCard
                                    key={cirt.title}
                                    title={cirt.title}
                                    img={cirt.img}
                                    url={cirt.url}
                                />
                            ))}
                        </div>

                        <p className="font-normal text-[20px] leading-[28px] text-center mt-[43px] underline text[#0f172e]">
                            Click On The Logo To View The Certificate /
                            Registration
                        </p>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* --  Concept product sct End   -- */}
                {/* -------------------------------- */}
                {/* -------------------------------- */}
                {/* --  Product process tab strt   -- */}
                {/* -------------------------------- */}
                <div className="w-full bg-[#979797] bg-opacity-5 py-[70px] max-md:pt-[11px] max-md:pb-[27px]">
                    <div className="custom__container">
                        <InfoTab />
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* --  Product process tab End   -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- Wideset Range     start   -- */}
                {/* -------------------------------- */}
                <div className="custom__container">
                    <div className="py-[45px] md:py-[100px]">
                        <h2 className="text-[35px] leading-[52px] text-center font-medium max-w-[761px] mx-auto max-md:text-[15px] max-md:leading-[21px]">
                            We manufacture widest range of powder formulas in
                            the regions we serve
                        </h2>

                        <p className="mt-[24px] max-w-[982px] mx-auto text-center leading-[21px] text-[#50504e] font-normal text-[15px]">
                            The Kingenic manufacturing unit stands as a pinnacle
                            of cutting-edge technology, representing an advanced
                            and futuristic Contract Research and Manufacturing
                            (CRAMS) facility. Nestled in Surat, Gujarat, India,
                            our state-of-the-art manufacturing facility embodies
                            the essence of Gen-Z innovations, being fully
                            equipped with the latest technological advancements.
                            Meticulously designed in accordance with Current
                            Good Manufacturing Practice (CGMP) and stringent
                            quality standards, our facility boasts the highest
                            accreditation, underscoring our unwavering
                            commitment to excellence.
                        </p>
                        <p className="mt-[24px] max-w-[982px] mx-auto text-center leading-[21px] text-[#007AD9] font-normal text-[15px]">
                            Operating with a continuous improvement approach, we
                            not only meet but exceed industry benchmarks,
                            ensuring that our manufacturing processes align
                            seamlessly with the ever-evolving landscape of
                            scientific and technological progress.
                        </p>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* -- Wideset Range    tab End   -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* -- DivisionBussness sl Start   -- */}
                {/* -------------------------------- */}
                <BusinessDivisionSlider />
                {/* -------------------------------- */}
                {/* -- DivisionBussness sl End   -- */}
                {/* -------------------------------- */}

                {/* -------------------------------- */}
                {/* --Team section       start   -- */}
                {/* -------------------------------- */}
                <div className="bg-[#979797] bg-opacity-5 rounded-lg pt-[13px] pb-[26px]">
                    <div className="custom__container">
                        <div className="max-w-[785px] mx-auto">
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-[32px] pl-[20px]">
                                <div className="w-full relative max-md:max-w-[236px] max-md:mx-auto">
                                    <img
                                        src={teamImg.manan}
                                        alt=""
                                        className="w-full z-10 relative "
                                    />

                                    <div className="w-full h-[80%] bottom-0 left-0 absolute z-0 rounded-md bg-[#D9D9D9]"></div>
                                </div>

                                <div className="flex items-end md:col-span-2">
                                    <p className="text-[13px] leading-[18px] font-normal text-[#50504E] max-md:px-[25px] max-md:text-center">
                                        Our name embodies a genetic connection,
                                        and the term &apos;KIN&apos; encompass-
                                        es all individuals. This is the essence
                                        of Kingenic. Our aim extends beyond mere
                                        product sales; we strive to foster a
                                        lasting relationship. As you explore our
                                        catalog, you may discover products that
                                        resonate with you, be it for your
                                        personal life or to enrich your
                                        inventory. Our concept is distinctive,
                                        adaptable, and full of possibilities. We
                                        offer an extensive array of products,
                                        available either as a brand or as
                                        private label, tailored to meet your
                                        specific requirements. Each stage of our
                                        formulation process is meticulously
                                        executed, with an unwavering focus on
                                        quality, functionality, attention to
                                        detail, and adherence to regulatory
                                        standards. - Manan Kinariwala (CEO)
                                    </p>
                                </div>
                            </div>

                            <div className="my-[25px] h-[1px] w-full bg-[#007AD9]"></div>
                            <p className="text-center text-[15px] leading-[21px] font-normal max-w-[737px] mx-auto text-[#50504E] max-md:px-[25px]">
                                Kingenic is committed to pursue Kingenic&apos;s
                                goal of discovering and delivering
                                transformational nutritional products and
                                therapies to serve human worldwide. We bring in
                                deep science, technical and business expertise
                                to deliver commercial success for the partners
                                we serve.
                            </p>
                            <div className="my-[25px] h-[1px] w-full bg-[#007AD9]"></div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-[32px] pl-[20px]">
                                <div className="w-full relative max-md:max-w-[236px] max-md:mx-auto">
                                    <img
                                        src={teamImg.nikita}
                                        alt=""
                                        className="w-full z-10 relative"
                                    />

                                    <div className="w-full h-[80%] bottom-0 left-0 absolute z-0 rounded-md bg-[#D9D9D9]"></div>
                                </div>

                                <div className="flex items-end md:col-span-2">
                                    <p className="text-[13px] leading-[18px] font-normal text-[#50504E] max-md:px-[25px] max-md:text-center">
                                        With a steadfast commitment to efficient
                                        operations, meticulous supply
                                        management, and unwavering adherence to
                                        quality compliance, Kingenic is
                                        diligently constructing an advanced
                                        ecosystem for providing top-notch
                                        nutrition manufacturing solutions. We
                                        consistently strive to enhance our
                                        management systems and interaction
                                        processes, aiming to provide
                                        unparalleled service to our partners.
                                        Our dedication lies in assisting
                                        patients worldwide with optimal
                                        products. We deeply comprehend the
                                        rapidly evolving product landscape,
                                        consumer behaviors, and consump- tion
                                        patterns, enabling us to design products
                                        that precisely cater to their needs. At
                                        Kingenic, we stand prepared to meet the
                                        challenges of the future. - Nikita Shah
                                        (Ops Director)
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* -------------------------------- */}
                {/* --Team section       End   -- */}
                {/* -------------------------------- */}

                <div className="custom__container">
                    <div className="py-[20px] md:py-[100px]">
                        <div className="max-w-[1059px] mx-auto">
                            <h3 className="text-[20px] leading-[28px] font-normal max-md:text-center max-md:font-medium">
                                Latest news
                            </h3>

                            {news?.map((item) => (
                                <NewCard
                                    key={item.title}
                                    title={item.title}
                                    img={item.img}
                                    paragraph={item.paragraph}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="custom__container">
                    <div className="max-w-[1180px] mx-auto w-full flex items-center justify-center gap-[30px] pb-[30px] max-md:flex-col-reverse">
                        <div className="max-w-[437px] w-full">
                            <h2 className="text-[20px] md:text-[36px] font-medium leading-[28px] md:leading-[50px] mb-[27px] max-md:text-center max-md:max-w-[243px] max-md:mx-auto">
                                Questions, answered by doctors and scientists
                            </h2>

                            <div className="border border-[#0F172E] rounded-[100px] w-fit px-8 py-4 cursor-pointer max-md:mx-auto">
                                <p className="text-[15px] leading-[21px] font-normal underline ">
                                    Ask a scientific question now
                                </p>
                            </div>
                        </div>

                        <div className="w-full">
                            <img
                                src={newsImg[3]}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
};

export default Company;
