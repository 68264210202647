import React from 'react';
import Navbar from '../Contents/Navbar';
import '../Styles/Ingredient.css';
import '../Styles/fonts.css';
// import AnimatedCursor from "react-animated-cursor";
import 'swiper/css';
import 'swiper/css/pagination';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Subscribe from '../Components/shared/Subscribe';
import Footer from '../Contents/Footer';
import '../Styles/Cardslider.css';
import {
    ingrComImg,
    ingredientsImg,
    manufacturerComImg,
} from '../utils/assets';
const Ingredient = () => {
    const partners = [
        {
            title: 'Astaxanthin',
            img: ingrComImg[0],
        },
        {
            title: 'Collagen Matrix',
            img: ingrComImg[1],
        },
        {
            title: 'Activated Piperine',
            img: ingrComImg[2],
        },
        {
            title: 'Balanced PPQ',
            img: ingrComImg[3],
        },
        {
            title: 'Acetyl-L-Carnitine',
            img: ingrComImg[4],
        },
        {
            title: 'Cinnamon Complex',
            img: ingrComImg[5],
        },
        {
            title: 'Optimized Marigold',
            img: ingrComImg[6],
        },
        {
            title: 'Fast Acting Arginine',
            img: ingrComImg[7],
        },
        {
            title: 'Stable Probiotic',
            img: ingrComImg[8],
        },
        {
            title: 'Magnesium L-threonate',
            img: ingrComImg[9],
        },
        {
            title: 'Advanced Lutein ',
            img: ingrComImg[10],
        },
        {
            title: 'Digestive Enzyme ',
            img: ingrComImg[11],
        },
        {
            title: 'Pumpkin Complex',
            img: ingrComImg[12],
        },
        {
            title: 'Corn Glucosamine',
            img: ingrComImg[13],
        },
        {
            title: 'Coffee Redefined ',
            img: ingrComImg[14],
        },
    ];

    const fearuredData = [
        [
            {
                img: manufacturerComImg[0],
                paragraph: 'Quality Centric',
                url: '',
                urlText: 'Commitment at every stage',
            },
            {
                img: manufacturerComImg[1],
                paragraph: 'Branded Ingredients',
                url: '',
                urlText: 'Superior Sourcing',
            },
            {
                img: manufacturerComImg[2],
                paragraph: 'Sustainable Approach',
                url: '',
                urlText: 'As easy as it gets',
            },
        ],
    ];

    return (
        <div>
            <Navbar />

            {/* -------------------------------- */}
            {/* -- Header banner sectin start -- */}
            {/* -------------------------------- */}
            <div className="max-w-[1280px] mt-[54px] md:mt-[120px] w-full mx-auto">
                <div className="max-md:h-[659px] md:max-h-[725px] w-full rounded-lg max-md:rounded-none overflow-hidden relative">
                    <img
                        src={ingredientsImg.banner}
                        alt=""
                        className="w-full h-full object-cover max-md:hidden"
                    />
                    <img
                        src={ingredientsImg.bannerM}
                        alt=""
                        className="w-full h-full object-cover md:hidden"
                    />

                    <div className="absolute bottom-0 left-0 w-full py-[16px] pl-[100px] backdrop-blur-md bg-[#0F172E]/10 text-white max-md:pl-[24px]">
                        <p className="text-[12px] leading-[16px] md:text-[19px] md:leading-[26px] font-normal">
                            Understanding Ingredients
                        </p>

                        <h3 className="text-[24px] leading-[30px] md:text-[64px] md:leading-[80px] max-md:my-[8px] font-medium">
                            Scientific. Safe. Sustainable
                        </h3>

                        <h3 className="text-[15px] leading-[18px] md:text-[25px] md:leading-[34px] font-normal">
                            Molecules that makes your brand stand out
                        </h3>
                    </div>
                </div>
            </div>
            {/* -------------------------------- */}
            {/* -- Header banner section End -- */}
            {/* -------------------------------- */}

            {/* -------------------------------- */}
            {/* -- Header banner section Start -- */}
            {/* -------------------------------- */}

            <div className="custom__container">
                <div className="pt-[55px]  text-center">
                    <h2 className="text-[35px] leading-[48px] font-medium uppercase">
                        QUALITY INGREDIENTS . FOR BETTER OUTCOMES
                    </h2>

                    <p className="mt-[17px] text-[15px] leading-[22px] font-normal max-w-[762px] mx-auto">
                        Our branded ingredients are clinically-proven and we
                        pride ourselves on staying current on the latest
                        research. In fact, every product is backed by research.
                        We believe it’s the most powerful tool.
                    </p>
                </div>

                <div className="w-full h-[1px] bg-[#727272] my-[27px]"></div>

                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-[16px]">
                    {partners?.map((item) => (
                        <div className="" key={item.title}>
                            <div className="w-full aspect-square bg-[#c8cbd2]/10 flex items-center justify-center rounded-xl px-[5px]">
                                <div className="w-full">
                                    <img
                                        className="w-full"
                                        src={item.img}
                                        alt={item.title}
                                    />
                                </div>
                            </div>

                            <p className="my-[13px] text-[15px] leading-[22px] font-normal text-[#50504e]">
                                {item.title}
                            </p>
                        </div>
                    ))}
                </div>

                <p className="text-[12px] font-normal leading-[17px] mt-[17px] mb-[70px]">
                    Disclaimer: This information in our Herbal Reference Guide
                    is intended only as a general reference for further
                    exploration, and is not a replacement for professional
                    health advice. This content does not provide dosage
                    information, format recommendations, toxicity levels, or
                    possible interactions with prescription drugs. Accordingly,
                    this information should be used only under the direct
                    supervision of a qualified health practitioner such as a
                    naturopathic physician.
                </p>
            </div>

            {/* -------------------------------- */}
            {/* -- Header banner section End -- */}
            {/* -------------------------------- */}

            <div className="bg-[#F2F2F2]/50 pt-[31px] pb-[22px]">
                <div className="custom__container">
                    <div className="max-md:hidden flex rounded-[30px]  ">
                        <div className="publp1div ">
                            <p className="text-[15px] leading-[21px] font-normal ">
                                Manufacturing
                            </p>
                            <p className="text-[18px] leading-[25px] font-medium my-[27px] max-w-[278px]">
                                The standards is ours to set
                            </p>
                            <p className="text-[15px] leading-[21px] font-normal max-w-[278px] text-[#0F172E] natosans">
                                The complexity of production demands our full
                                attention from start to finish. The process of
                                blending various ingredients and ensuring they
                                deliver their intended benefit requires
                                continued testing at every stage of
                                manufacturing. Once products pass inspection,
                                labels are then verified so a have insight into
                                every ingredient.
                            </p>
                        </div>
                        <div className="publp2div  pl-[48px] ">
                            {fearuredData?.map((arr) => (
                                <div
                                    className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-[23px] pb-[57px]  pr-[24px] border-b border-b-[#007ad9] last:border-none"
                                    key={arr}
                                >
                                    {arr.map((item) => (
                                        <div
                                            key={item.paragraph}
                                            className="max-w-[287px] mx-auto w-full"
                                        >
                                            <div className="w-full">
                                                <img
                                                    src={item.img}
                                                    alt=""
                                                    className="w-full"
                                                />
                                            </div>
                                            <p className="text-[20px] leading-[28px] font-normal text-center text-[#0f172e] mt-[11px] mb-[13px]">
                                                {item.paragraph}
                                            </p>

                                            <p className="text-[14px] font-medium leading-[20px] text-[#007AD9] text-center">
                                                {item.urlText}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className="px-[20px] md:hidden">
                <div className="">
                    <p className="text-[15px] leading-[21px] font-normal text-center">
                        Manufacturing
                    </p>
                    <p className="text-[18px] leading-[25px] font-medium my-[10px] text-center max-w-[255px] mx-auto">
                        The standards is ours to set
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal text-[#0F172E] natosans text-center">
                        The complexity of production demands our full attention
                        from start to finish. The process of blending various
                        ingredients and ensuring they deliver their intended
                        benefit requires continued testing at every stage of
                        manufacturing. Once products pass inspection, labels are
                        then verified so a have insight into every ingredient.
                    </p>
                </div>

                <div className="tipupa" id="topupa">
                    <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        pagination={{ clickable: true }}
                        spaceBetween={10}
                        slidesPerView={1}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        {fearuredData?.map((arr) => (
                            <div className="" key={arr}>
                                {arr.map((item) => (
                                    <SwiperSlide
                                        key={item.paragraph}
                                        className="w-full mt-[26px] pb-[40px]"
                                    >
                                        <div className="max-w-[287px] mx-auto">
                                            <img
                                                src={item.img}
                                                alt=""
                                                className="w-full"
                                            />
                                            <p className="text-[20px] leading-[28px] font-normal text-center text-[#0f172e] mt-[11px] mb-[13px]">
                                                {item.paragraph}
                                            </p>

                                            <p className="text-[14px] font-medium leading-[20px] text-[#007AD9] text-center">
                                                {item.urlText}
                                            </p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        ))}
                    </Swiper>
                </div>
            </div>

            <Subscribe />
            <Footer />
        </div>
    );
};

export default Ingredient;

// const OldStuff = () => {
//     return (
//         <div className="Ingredients-container">
//             {/* {!isMobile && <AnimatedCursor />} */}
//             <Navbar />
//             <div className="Imain">
//                 <div className="ingbox">
//                     <p className="idnts1">Understanding Ingredients</p>
//                     <p className="idnts2">Scientific. Safe. Sustainable</p>
//                     <p className="idnts3">
//                         Molecules that makes your brand stand out
//                     </p>
//                 </div>
//                 <p className="idnavigate">Navigate/our Products</p>
//                 <p className="idselect">Selected Herbs & Ingredients</p>
//                 <p className="jump">
//                     Jump to a section by clicking a letter. Explore products by
//                     selecting an herbs or ingredient below
//                 </p>
//                 <div className="lettera">
//                     <p>A</p>
//                     <p>B</p>
//                     <p>C</p>
//                     <p>D</p>
//                     <p>E</p>
//                     <p>F</p>
//                     <p>G</p>
//                     <p>H</p>
//                     <p>I</p>
//                     <p>J</p>
//                     <p>K</p>
//                     <p>L</p>
//                     <p>M</p>
//                     <p>N</p>
//                     <p>O</p>
//                     <p>P</p>
//                     <p>Q</p>
//                     <p>R</p>
//                     <p>S</p>
//                     <p>T</p>
//                     <p>U</p>
//                     <p>V</p>
//                     <p>W</p>
//                     <p>X</p>
//                     <p>Y</p>
//                     <p>Z</p>
//                 </div>
//                 <div className="letteralove">
//                     <p>A</p>
//                     <p>B</p>
//                     <p>C</p>
//                     <p>D</p>
//                     <p>E</p>
//                     <p>F</p>
//                     <p>G</p>
//                     <p>H</p>
//                     <p>I</p>
//                     <p>J</p>
//                 </div>
//                 <div className="und2"></div>
//                 <div className="ingrcards">
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                 </div>
//                 <div className="ingrcardslove">
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                     <div>
//                         <div></div>
//                         <p>Acacia</p>
//                     </div>
//                 </div>
//                 <p className="diclm">
//                     Disclaimer: This information in our Herbal Reference Guide
//                     is intended only as a general reference for further
//                     exploration, and is not a replacement for professional
//                     health advice. This content does not provide dosage
//                     information, format recommendations, toxicity levels, or
//                     possible interactions with prescription drugs. Accordingly,
//                     this information should be used only under the direct
//                     supervision of a qualified health practitioner such as a
//                     naturopathic physician.
//                 </p>
//                 <div className="ipublishcard">
//                     <div className="ipublp1div">
//                         <p className="ipu1">Manufacturing</p>
//                         <p className="ipu2">The standards is ours to set</p>
//                         <p className="ipu3">
//                             The complexity of production demands our full
//                             attention from start to finish. The process of
//                             blending various ingredients and ensuring they
//                             deliver their intended benefit requires continued
//                             testing at every stage of manufacturing. Once
//                             products pass inspection, labels are then verified
//                             so a have insight into every ingredient.
//                         </p>
//                     </div>
//                     <div className="ipublp2div">
//                         <div>
//                             <div></div>
//                             <p className="ipubt1">Quality Centric</p>
//                             <p className="ipubt2">Commitment at every stage</p>
//                         </div>
//                         <div>
//                             <div></div>
//                             <p className="ipubt1">Quality Centric</p>
//                             <p className="ipubt2">Commitment at every stage</p>
//                         </div>
//                         <div>
//                             <div></div>
//                             <p className="ipubt1">Quality Centric</p>
//                             <p className="ipubt2">Commitment at every stage</p>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="ingredient-swiper-1">
//                     <Swiper
//                         modules={[Navigation, Pagination, Scrollbar, A11y]}
//                         spaceBetween={10}
//                         slidesPerView={1}
//                         pagination={{ clickable: true }}
//                         scrollbar={{ draggable: true }}
//                         onSwiper={(swiper) => console.log(swiper)}
//                         onSlideChange={() => console.log('slide change')}
//                     >
//                         <SwiperSlide>
//                             <div className="ING-CHILD">
//                                 <div></div>
//                                 <p className="ipubt1">Quality Centric</p>
//                                 <p className="ipubt2">
//                                     Commitment at every stage
//                                 </p>
//                             </div>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <div className="ING-CHILD">
//                                 <div></div>
//                                 <p className="ipubt1">Quality Centric</p>
//                                 <p className="ipubt2">
//                                     Commitment at every stage
//                                 </p>
//                             </div>
//                         </SwiperSlide>
//                         <SwiperSlide>
//                             <div className="ING-CHILD">
//                                 <div></div>
//                                 <p className="ipubt1">Quality Centric</p>
//                                 <p className="ipubt2">
//                                     Commitment at every stage
//                                 </p>
//                             </div>
//                         </SwiperSlide>
//                     </Swiper>
//                 </div>
//                 {/* <div className="product-form-ing">
//           <p className="form-p1-ing">
//             Expand your product portfolio by featuring science based products
//           </p>
//           <p className="form-p2-ing">Collaborate with us.<br/> Book a free consultation today !</p>
//           <input type="text" name="" id="" placeholder="Name" />
//           <input type="text" name="" id="" placeholder="Email Address" />
//           <input type="text" name="" id="" placeholder="Phone Number" />
//           <textarea placeholder="Tell us the area of your interest"></textarea>
//           <button>Submit</button>
//         </div> */}
//                 <DownloadCard />
//                 <Footer />
//             </div>
//         </div>
//     );
// };
