import React from 'react';
import Footer from '../Contents/Footer';
import Navbar from '../Contents/Navbar';
import '../Styles/Privatecategory.css';
// import AnimatedCursor from "react-animated-cursor";
import c1 from '../Assests/c1.svg';
import c10 from '../Assests/c10.svg';
import c2 from '../Assests/c2.svg';
import c3 from '../Assests/c3.svg';
import c4 from '../Assests/c4.svg';
import c5 from '../Assests/c5.svg';
import c6 from '../Assests/c6.svg';
import c7 from '../Assests/c7.svg';
import c8 from '../Assests/c8.svg';
import c9 from '../Assests/c9.svg';
import '../Styles/fonts.css';
import DownloadCard from './DownloadCard';
const brand = () => {
    const tableData = [
        {
            id: 'KSN01',
            name: 'Ultra Whey',
            age: 'Whey Protein Concentrate, Whey Isolate',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Pure Whey',
            age: 'Whey Protein Isolate',
            email: 'Muscle Building',
        },
        {
            id: 'KSN01',
            name: 'Intense Whey',
            age: 'Whey Protein Isolate',
            email: 'Muscle Building',
        },
        {
            id: 'KSN01',
            name: 'Branched Amino',
            age: 'Whey Protein Isolate',
            email: 'Muscle Building',
        },
        {
            id: 'KSN01',
            name: 'Creatine',
            age: 'Whey Protein Concentrate, Whey Isolate',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Ultra Whey',
            age: 'Ultra Whey',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Pure Whey',
            age: 'Pure Whey',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Ultra Whey',
            age: 'Whey Protein Concentrate, Whey Isolate',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Intense Whey',
            age: 'Intense Whey',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Branched Amino',
            age: 'Branched Amino',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Ultra Whey',
            age: 'Whey Protein Concentrate, Whey Isolate',
            email: 'Muscle Maintenance',
        },
        {
            id: 'KSN01',
            name: 'Creatine',
            age: 'Creatine',
            email: 'Muscle Maintenance',
        },
    ];
    const isMobile = window.innerWidth <= 768;
    return (
        <div className="Private-containerx">
            {/* {!isMobile && <AnimatedCursor />} */}
            <Navbar />
            <div className="Bmainx">
                <div className="tbdiv1">
                    <div className="tbd-pr1"></div>
                    <div className="tbd-pr2">
                        <p>
                            Build your{' '}
                            <span className="sports-brand">Sports brand</span>{' '}
                            with the best formulas{' '}
                        </p>
                    </div>
                </div>
                <p className="priv-sp-nutri">Sports Nutrition </p>
                <p className="kriyexp">
                    Select the sub-category for sports nutrition
                </p>
                <div className="circletext"></div>
                <div className="kriyediv">
                    <div>
                        <p className="kriyexp1">Name/Code</p>
                        <p className="kriyexp2">Ultra Whey</p>
                    </div>
                    <div>
                        <p className="kriyexp1">Ingredients</p>
                        <p className="kriyexp2">
                            Whey Protein Concentrate, Whey Isolate
                        </p>
                    </div>
                    <div>
                        <p className="kriyexp1">Indication</p>
                        <p className="kriyexp2">Muscle Maintenance</p>
                    </div>
                </div>
                <div className="kriyediv">
                    <div>
                        <p className="kriyexp1">Product/Name</p>
                        <p className="kriyexp2">Ultra Whey</p>
                    </div>
                    <div>
                        <p className="kriyexp1">Ingredients</p>
                        <p className="kriyexp2">
                            Whey Protein Concentrate, Whey Isolate
                        </p>
                    </div>
                    <div>
                        <p className="kriyexp1">Indication</p>
                        <p className="kriyexp2">Muscle Maintenance</p>
                    </div>
                </div>
                <div className="kriyediv">
                    <div>
                        <p className="kriyexp1">Product/Name</p>
                        <p className="kriyexp2">Ultra Whey</p>
                    </div>
                    <div>
                        <p className="kriyexp1">Ingredients</p>
                        <p className="kriyexp2">
                            Whey Protein Concentrate, Whey Isolate
                        </p>
                    </div>
                    <div>
                        <p className="kriyexp1">Indication</p>
                        <p className="kriyexp2">Muscle Maintenance</p>
                    </div>
                </div>
                {/* <div className="xrdiv"></div> */}

                <div className="tablx">
                    <table>
                        <thead>
                            <tr>
                                <th>Product Code</th>
                                <th>Product Name</th>
                                <th>Active Ingredients</th>
                                <th>Indication</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr
                                    key={row.id}
                                    style={{
                                        backgroundColor:
                                            index % 2 === 0
                                                ? 'rgba(217, 217, 217, 0.8)'
                                                : '#ffffff',
                                    }}
                                >
                                    <td>{row.id}</td>
                                    <td>{row.name}</td>
                                    <td>{row.age}</td>
                                    <td>{row.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <p className="xycategoryx">HEALTH CATEGORIES</p>
                <div className="xyline1x"></div>
                <div className="zcategoryBoxx">
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c1} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>BLOOD SUGAR</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c2} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>
                                BONE, JOINT & <br /> MUSCLE HEALTH
                            </p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c3} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>
                                COGNITIVE <br /> HEALTH & MENTAL <br />{' '}
                                PERFORMANCE
                            </p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c4} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>EYE HEALTH</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c5} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>
                                GASTROINTESTINAL <br /> HEALTH
                            </p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c6} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>HEART HEALTH</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c7} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBox">
                            <p>IMMUNE HEALTH</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c8} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>MOOD SUPPORT</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c9} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>NATURAL ENERGY</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c10} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>
                                SEXUAL <br /> PERFORMANCE & <br /> FERTILITY
                            </p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c8} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>MOOD SUPPORT</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c9} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>NATURAL ENERGY</p>
                        </div>
                    </div>
                    <div className="zcatboxx">
                        <div className="zicondivx">
                            <img src={c10} alt="" className="zcatLox" />
                        </div>
                        <div className="zpBoxx">
                            <p>
                                SEXUAL <br /> PERFORMANCE & <br /> FERTILITY
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className="pc-form-div">
          <div className="priv-div-l">
            <p className="priv-exp-p">
              Expand your product portfolio by featuring science based products
            </p>
            <p className="priv-collab">
              Collaborate with us. Book a free consultation today !
            </p>
            <input type="text" placeholder="Name" />
            <input type="text" placeholder="Email Address" />
            <input type="text" placeholder="Phone Number" />
            <textarea placeholder="Tell us the area of your interest"></textarea>
            <button>Submit</button>
          </div>
          <div className="priv-div-r"></div>
        </div> */}
                <DownloadCard />
                <Footer />
            </div>
        </div>
    );
};

export default brand;
