import { manufactureImg } from '../../../utils/assets';
import './Manufecturer.scss';

const ManufecturerSlider = () => {
    return (
        <div className="custom__container">
            <div className="slider__strem overflow-hidden">
                <div className="slide-track">
                    {manufactureImg.map((img) => (
                        <div key={img} className="slide">
                            <img
                                src={img}
                                alt=""
                                className="w-full h-full object-contain"
                            />
                        </div>
                    ))}
                    {manufactureImg.map((img) => (
                        <div key={img} className="slide">
                            <img
                                src={img}
                                alt=""
                                className="w-full h-full object-contain"
                            />
                        </div>
                    ))}
                    {manufactureImg.map((img) => (
                        <div key={img} className="slide">
                            <img
                                src={img}
                                alt=""
                                className="w-full h-full object-contain"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ManufecturerSlider;
