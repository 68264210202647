import { FaAngleRight } from 'react-icons/fa6';
import { logos } from '../../utils/assets';
import { Link } from 'react-router-dom';

const HubsCard = ({ title, paragraph, urltitle, url }) => {
    return (
        <div className="max-md:mx-auto max-w-[270px] mb-[56px] last:mb-0 flex flex-col items-center justify-center md:items-start md:justify-start ">
            <div className="flex items-center">
                <div className="log-1">
                    <img src={logos.logo2} alt="" />
                </div>
                <div className="line-x"></div>
                <p className="text-[20px] leading-[28px] font-medium">
                    {title}
                </p>
            </div>

            <p className="text-[15px] mt-[15px] mb-[7px] font-normal leading-[21px] text-[#50504e] max-md:text-center">
                {paragraph}
            </p>

            <Link to={url}>
                <div className="flex items-center gap-2 text-[#007ad9] cursor-pointer">
                    <p className="text-[15px] font-normal leading-[26px] ">
                        {urltitle}
                    </p>
                    <FaAngleRight size={13} className="mt-1 hidden md:block" />
                </div>
            </Link>
        </div>
    );
};

export default HubsCard;
