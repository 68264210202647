import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Slider.scss';

// import required modules
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { logos, sliderPhotos } from '../../utils/assets';
import { cn } from '../../utils/classMarge';

export default function Slider() {
    const sliderData = [
        {
            header: 'NUTRACEUTICALS',
            heading: 'TRANSFORMING NUTRITION',
            titleclass: 'max-md:max-w-[259px]',
            title: 'Manufacturing Next-Gen Nutrition',
            paragraph:
                'Kingenic is a multifunctional food & nutrition solution partner, specializing in end-to-end solutions for high-quality, affordable dry food products &  oral nutrition supplements (ONS). ',
            image: sliderPhotos[0],
        },

        {
            header: 'FOODSERVICE',
            heading: 'REDEFINING FOOD SOLUTIONS',
            title: "Let's Get Cooking",
            paragraph:
                'We process dry food and offer blending services that fits your need. We have the expertise and equipment to safely and effectively manufacture  food products for hospitality industries',
            image: sliderPhotos[1],
        },
        {
            header: 'MEDICAL NUTRITION',
            heading: 'EMPOWERING LIVES',
            title: 'Advancing health with precision nutrition',
            paragraph:
                'we nourish those who need extra care, fill nutrition gaps and provide innovative products for a healthier future, enabling customers and patients to live their healthiest lives',
            image: sliderPhotos[2],
        },
        {
            header: 'ANIMAL NUTRITION',
            heading: 'WE CARE FOR PETS',
            titleclass: 'max-md:max-w-[259px]',
            title: 'Crafting Animal Nutrition Together',
            paragraph:
                'Our diverse range of products is crafted to support pet &  animal health in achieving their health objectives, whether it involves managing conditions, promoting faster recovery, or enhancing overall well-being.',
            image: sliderPhotos[3],
        },
    ];

    return (
        <>
            <Swiper
                style={{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                    '--swiper-pagination-bullet-inactive-color': '#fff',
                    '--swiper-pagination-bottom': 'calc(35% - 95px)',
                }}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                }}
                navigation={{
                    clickable: true,
                }}
                speed={1200}
                modules={[Pagination, Navigation, Autoplay]}
                className="mySwiper hero__slider"
            >
                {sliderData?.map((itm) => (
                    <SwiperSlide className="w-full" key={itm.title}>
                        <SliderCard
                            header={itm.header}
                            heading={itm.heading}
                            title={itm.title}
                            paragraph={itm.paragraph}
                            image={itm.image}
                            titleclass={itm.titleclass}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
}

const SliderCard = ({
    header,
    heading,
    title,
    titleclass,
    paragraph,
    image,
}) => {
    return (
        <div className="flex flex-col gap-4 items-center justify-center text-white  h-full px-6">
            <div className="flex items-center  justify-center">
                <div className="w-32 h-20 overflow-hidden flex items-center justify-center">
                    <img
                        src={logos.logo}
                        alt="logo"
                        className="w-full h-full object-cover mt-2"
                    />
                </div>{' '}
                <span className="mr-5 text-[20px]">|</span>
                <p className="text-[15px] md:text-5xl font-semibold tracking-[5px]">
                    {header}
                </p>
            </div>

            <p className="uppercase text-[13px] md:text-[17px] tracking-[4px] text-center">
                {heading}
            </p>
            <h2
                className={cn(
                    'text-[25px] md:text-[50px] font-medium text-center max-md:mx-auto',
                    titleclass
                )}
            >
                {title}
            </h2>

            <p className="text-[16px] md:text-[20px] max-w-[736px] text-center px-3">
                {paragraph}
            </p>

            <div className="w-full h-full absolute left-0 top-0 -z-10">
                <img
                    className="w-full h-full object-cover"
                    src={image}
                    alt="Image"
                />
            </div>
        </div>
    );
};
