const ProductCard = ({ title, url, img }) => {
    return (
        <a target="_blank" href={url} rel="noreferrer">
            <div className="flex flex-col justify-center items-center">
                <img src={img} className="max-w-[140px] w-full" alt="" />

                <p className="text-[20px] mb-[6px] leading-[28px] font-normal text-center text-[#007ad9]">
                    {title}
                </p>

                <div>
                    <p className="max-w-[234px] text-[#0F172E] cursor-pointer text-center mx-auto max-md:underline text-[15px] font-normal leading-[21px]">
                        Click to view our manufacturing license
                    </p>
                </div>
            </div>
        </a>
    );
};

export default ProductCard;
