import React from 'react';

const Subscribe = () => {
    return (
        <div className="my-[88px] max-md:my-[65px] mx-auto">
            <p className="text-center text-[35px] font-medium leading-[52px] mb-[28px] max-md:text-[25px] max-md:leading-[52px] max-md:mb-[13px] max-md:px-[10px]">
                Subscribe our Newsletters
            </p>
            <p className="mb-[50px] text-center text-[#50504E] text-[15px]  font-normal max-w-[498px] leading-[26px] mx-auto max-md:px-[10px] max-md:mb-[15px]">
                We have created a new product that will help designers,
                developers and companies create websites for their startups
                quickly and easily.
            </p>
            <div className="flex flex-col md:flex-row gap-8 justify-center items-center">
                <input
                    className="border h-[60px] max-md:h-[48px] w-[343px] md:w-[470px] rounded-full ps-8 placeholder:text-[18px] placeholder:font-light max-md:placeholder:text-[15px]"
                    type="text"
                    name=""
                    id=""
                    placeholder="Your email"
                />
                <button className="bg-black h-[60px] max-md:h-[48px] w-fit px-12 py-4 text-white rounded-full max-md:text-[15px]">
                    Sign Up
                </button>
            </div>
            <p className="text-[16px] leading-[26px] font-normal text-center text-[#50504E] mt-[63px] max-md:text-[10px] max-md:mt-[20px]">
                By subscribing, you agree to the Terms of Service
            </p>
        </div>
    );
};

export default Subscribe;
