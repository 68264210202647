import Subscribe from '../Components/shared/Subscribe';
import Footer from '../Contents/Footer';
import Navbar from '../Contents/Navbar';
import { branded } from '../utils/assets';

const BrandedPortfolo = () => {
    const brandedData = [
        {
            logo: branded.partner[0],
            text: 'Introducing Wune, innovative brand developed by Kingenic that specializes in wound management. We assist healthcare professionals in achieving optimal healing by harnessing the power of nutrition. Whether it&apos;s pre-surgery, post-surgery, or disease-specific wound care, we provide a comprehensive range of products for accelerated healing.',
            img: branded.brandedImg[0],
        },
        {
            logo: branded.partner[1],
            text: 'At Collagen Republic, we believe in timeless beauty. Our skincare harnesses the power of collagen, replenishing and stimulating its production for radiant skin. With natural ingredients and a commitment to sustainability, join us in revolutionizing skincare for a confident, empowered you.',
            img: branded.brandedImg[1],
        },
        {
            logo: branded.partner[2],
            text: 'Definite Everyday Wellness: Nourishing individuals facing chronic diseases like diabetes, pain management, cancer, and critical care. Our holistic approach blends science and compassion to support well-being, fostering resilience and vitality in every journey.',
            img: branded.brandedImg[2],
        },
    ];

    return (
        <div>
            <Navbar />

            <div className="custom__container">
                <div className="flex max-md:flex-col  justify-between pt-[80px] md:pt-[160px] pb-[26px]">
                    <p className="text-[30px] max-w-[286px] font-medium leading-[42px] max-md:text-center max-md:mb-[13px] max-md:mx-auto max-md:max-w-[346px]">
                        Branded Portfolio
                    </p>

                    <p className="max-w-[641px] text-[15px] font-normal leading-[21px] max-md:text-center">
                        Our products are developed on scientific principles,
                        innovation, with a deep understanding of human needs.
                        They are trusted by businesses and consumers worldwide.
                        Kingenic possesses a robust collection of brands and
                        formulations strategically poised to serve a crucial
                        role for people across the globe. In a rapidly expanding
                        and increasingly relevant sector, we are
                        well-positioned. We offer a portfolio of one thousand
                        in-house formulas for contract manufacturing and have
                        nine Power Brands prepared for distribution and vertical
                        expansion. Additionally, we provide exclusive contract
                        offerings with special incentives.
                    </p>
                </div>
            </div>

            <div className="w-full max-h-[510px] overflow-hidden border-b-[6px] border-b-[#D00D38]">
                <img
                    src={branded.banner}
                    alt="bannner"
                    className="w-full h-full object-cover"
                />
            </div>

            <div className="relative overflow-hidden max-md:pb-[150px]">
                <div className="custom__container">
                    <div className="flex flex-col items-center justify-center py-[190px]">
                        <h3 className="uppercase text-[20px] leading-[26px] text-[#d00d38] text-center font-bold">
                            AFFORDABLE • CONVENIENT • DELICIOUS
                        </h3>

                        <p className="max-w-[753px] text-center text-[20px] max-md:text-[15px] max-md:leading-[21px] leading-[27px] font-normal text-[#50504e] mt-[15px]">
                            In a world where energy solution often come at a
                            high price, we bring you affordable innovation that
                            not only recharges your energy levels but also
                            treats your taste buds to an explosion of delightful
                            flavours.
                        </p>

                        <div className="max-w-[328px] max-md:max-w-[178px] w-full overflow-hidden mt-[39px]">
                            <img
                                src={branded.partner[3]}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>
                </div>

                {/* overchk */}
                <div className="absolute left-[27%]  max-lg:left-[70%]  max-lg:top-[3%] top-[10%] w-[111px] overflow-hidden -z-10">
                    <img src={branded.chk[0]} alt="" className="w-full" />
                </div>
                <div className="absolute left-[10%]  max-lg:hidden top-[20%] max-lg:w-[100px] w-[262px] overflow-hidden -z-10">
                    <img src={branded.chk[4]} alt="" className="w-full " />
                </div>
                <div className="absolute left-[28%] top-[45%] max-lg:left-[0%] max-lg:w-[100px] max-lg:top-[6%] w-[162px] overflow-hidden -z-10 ">
                    <img src={branded.chk[1]} alt="" className="w-full " />
                </div>

                <div className="absolute right-[10%] max-lg:right-[50%] max-lg:w-[168px] max-lg:top-[60%] top-[10%] w-[158px] overflow-hidden -z-10">
                    <img src={branded.chk[2]} alt="" className="w-full " />
                </div>
                <div className="absolute right-[20%] top-[45%] w-[243px] max-lg:right-[0%] max-lg:w-[138px] max-lg:top-[60%] overflow-hidden -z-10">
                    <img src={branded.chk[3]} alt="" className="w-full " />
                </div>
            </div>

            <div className="custom__container">
                {brandedData?.map((item) => (
                    <div
                        key={item.text}
                        className="flex max-md:flex-col items-center gap-[24px] pb-[45px] md:pb-[100px] last:pb-0"
                    >
                        <div className="flex flex-col gap-[20px] items-center justify-center max-w-[364px] w-full">
                            <div className="max-w-[231px] rounded-md max-md:max-w-[159px] w-full overflow-hidden">
                                <img
                                    src={item.logo}
                                    alt=""
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <p className="text-[16px] leading-[26px] font-normal dmsans max-md:text-center">
                                {item.text}
                            </p>

                            <div className="px-[30px] max-md:hidden py-[12px] rounded-[100px] border-2 text-center text-[18px] font-medium leading-[26px] dmsans">
                                Coming Soon
                            </div>
                        </div>

                        <div className="w-[calc(100% - 364px)] flex flex-col items-center justify-center">
                            <img
                                src={item.img}
                                alt=""
                                className="w-full h-full object-cover"
                            />
                            <div className="px-[30px] mt-[25px] hidden max-md:block py-[12px] rounded-[100px] border-2 text-center text-[18px] font-medium leading-[26px] dmsans">
                                Coming Soon
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Subscribe />
            <Footer />
        </div>
    );
};

export default BrandedPortfolo;
