/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { aboutImg } from '../../utils/assets';

const AboutCardsSection = () => {
    const [selectedAbout, setSelectedAbout] = useState('Who');

    const handleAboutClick = (about) => {
        setSelectedAbout(about);
    };

    useEffect(() => {
        const abouts = Object.keys(AboutCards);
        let currentIndex = abouts.indexOf(selectedAbout);

        const intervalId = setInterval(() => {
            currentIndex = (currentIndex + 1) % abouts.length;
            setSelectedAbout(abouts[currentIndex]);
        }, 5000); // Change the interval duration as needed (in milliseconds)

        return () => clearInterval(intervalId);
    }, [selectedAbout]);

    const AboutCards = {
        Who: (
            <div className="grid grid-cols-1 md:grid-cols-2  gap-[36px]">
                <div className="overflow-hidden rounded-3xl relative ">
                    <div className="w-full h-full top-0 left-0 absolute overflow-hidden">
                        <img
                            className="w-full h-full object-cover"
                            src={aboutImg[0]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full">
                    <p className="text-[15px]">About us</p>
                    <p className="text-[40px] text-[#007AD9] font-medium">
                        Who
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal">
                        Kingenic is a multifunctional nutrition product solution
                        partner with key focus on end-to-end solutions with
                        highest quality, affordable oral nutrition supplements
                        (ONS) for a healthier tomorrow.
                        <br />
                        <br />
                        Kingenic prides itself on innovation and research to
                        consistently enhance its offerings. With an unwavering
                        commitment to health, we go beyond conventional
                        approaches, leveraging cutting-edge technologies and
                        scientific advancements to formulate nutritional
                        supplements that cater to diverse needs. Kingenic
                        envisions a future where individuals not only have
                        access to essential nutrition but also an experience
                        that they never forget
                    </p>
                </div>
            </div>
        ),
        What: (
            <div className="grid grid-cols-1 md:grid-cols-2  gap-[36px]">
                <div className="overflow-hidden rounded-3xl relative ">
                    <div className="w-full h-full top-0 left-0 absolute overflow-hidden">
                        <img
                            className="w-full h-full object-cover"
                            src={aboutImg[1]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full">
                    <p className="text-[15px]">About us</p>
                    <p className="text-[40px] text-[#007AD9] font-medium">
                        What
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal">
                        We specialize in providing brand owners and
                        manufacturers with comprehensive solutions, offering
                        customized product formulas, packaging, and brand design
                        solutions. Developing purposeful partnerships is at the
                        core of our mission.
                        <br />
                        <br /> Through personalized formulations, unique
                        packaging, and innovative brand design, we aim to
                        elevate brands to their fullest potential. Our
                        commitment to customization ensures tailored experiences
                        that resonate with the market, driving sustained growth
                        and success for our valued partners in the industry.
                        nutrition but also an experience that they never forget
                    </p>
                </div>
            </div>
        ),
        Why: (
            <div className="grid grid-cols-1 md:grid-cols-2  gap-[36px]">
                <div className="overflow-hidden rounded-3xl relative ">
                    <div className="w-full h-full top-0 left-0 absolute overflow-hidden">
                        <img
                            className="w-full h-full object-cover"
                            src={aboutImg[2]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full">
                    <p className="text-[15px]">About us</p>
                    <p className="text-[40px] text-[#007AD9] font-medium">
                        Why
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal">
                        Discover a transformative partnership when you choose
                        us, where reliability is just the beginning. Our
                        commitment goes beyond dependability - we offer a
                        comprehensive, innovative, purpose-led collaboration.
                        <br />
                        <br /> With a vision for contributing to a brighter
                        world and better nutrition for all, our approach extends
                        far beyond traditional business relationships. Join us
                        in creating a meaningful impact as we work together
                        towards a shared goal of fostering positive change and
                        ensuring improved nutrition reaches everyone.
                    </p>
                </div>
            </div>
        ),
        When: (
            <div className="grid grid-cols-1 md:grid-cols-2  gap-[36px]">
                <div className="overflow-hidden rounded-3xl relative ">
                    <div className="w-full h-full top-0 left-0 absolute overflow-hidden">
                        <img
                            className="w-full h-full object-cover"
                            src={aboutImg[3]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full">
                    <p className="text-[15px]">About us</p>
                    <p className="text-[40px] text-[#007AD9] font-medium">
                        When
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal">
                        With a proven track record of triumphing over challenges
                        in product launches, we stand out as the ideal
                        collaborator for co-innovation throughout every
                        development stage, spanning from conceptualization to
                        the end consumer.
                        <br />
                        <br /> Our expertise extends to offering unwavering
                        support at any point in the ideation process. Whether
                        you are in the early stages of brainstorming or
                        navigating the complexities of the final consumer phase,
                        our commitment to excellence ensures that we are the
                        go-to partner for comprehensive support and successful
                        product development.
                    </p>
                </div>
            </div>
        ),
        How: (
            <div className="grid grid-cols-1 md:grid-cols-2  gap-[36px]">
                <div className="overflow-hidden rounded-3xl relative ">
                    <div className="w-full h-full top-0 left-0 absolute overflow-hidden">
                        <img
                            className="w-full h-full object-cover"
                            src={aboutImg[4]}
                            alt=""
                        />
                    </div>
                </div>
                <div className="w-full">
                    <p className="text-[15px]">About us</p>
                    <p className="text-[40px] text-[#007AD9] font-medium">
                        How
                    </p>
                    <p className="text-[15px] leading-[21px] font-normal">
                        Driven by passion, we actively engage with stakeholders,
                        delivering top-tier customer service that remains
                        unwavering, regardless of project size. Our commitment
                        extends beyond mere collaboration; we become partners in
                        realizing your vision.
                        <br />
                        <br /> From inception to execution, we guide your ideas
                        through every stage, ensuring they evolve into
                        revenue-generating products. This steadfast dedication
                        to your success underscores our approach, making us not
                        just service providers, but invaluable allies in
                        transforming concepts into tangible, market-ready
                        offerings.
                    </p>
                </div>
            </div>
        ),
    };

    return (
        <div className="py-[100px] max-md:hidden">
            <div className="custom__container ">
                <div className="">{AboutCards[selectedAbout]}</div>
                <div className="business-menu-about  !mt-[42px]">
                    {Object.keys(AboutCards).map((about) => (
                        <p
                            key={about}
                            onClick={() => handleAboutClick(about)}
                            className={
                                selectedAbout === about ? 'selected' : ''
                            }
                        >
                            {about}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AboutCardsSection;
